import React from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  notification,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import CInput from '@components/UI/input/CInput';
import CButton from '@components/UI/button/CButton';
import './CardMembers.scss';
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import api from '@modules/Quotation/services';
import axios from 'axios';
import { useImmerReducer } from 'use-immer';
import ListMembers from '@modules/Quotation/MembersQuotation/ListMembers';
import useQuotation from '@modules/Quotation/hooks/useQuotation';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';

type Action = {
  type: 'search';
  payload?: any;
};

interface State {
  contractorInfo: object | null;
  isLoading: boolean;
}

const initialState: State = {
  contractorInfo: null,
  isLoading: false,
};

const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'search': {
      draft.isLoading = action.payload;
      break;
    }
  }
};

const CardMembers = ({
  member,
  updateMembers,
  deleteMembers,
  index,
  roles,
}) => {
  const { foreign_id, full_name } = member;
  const { Text } = Typography;
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const { isLoading } = state;
  const { agrMembers, setAgrMembers, stateDateQuotation } = useQuotation();

  const searchContractor = async (iin, foreignId) => {
    dispatch({ type: 'search', payload: true });
    try {
      if (iin) {
        const { data } = await api.contractor.getContractorByIIN({
          iin,
        });

        if (data.data.length === 1) {
          if (!data.data[0].ISN) {
            const info = await api.contractor.saveContractorKias({
              xml_isn: data.data[0].XMLISN,
              ext_system_key: data.data[0].EXTSYSTEMKEY,
            });
          } else {
            // empty
          }
        } else {
          // empty
        }

        data.data[0].foreign_id = foreignId;
        updateMembers(data.data[0]);
      } else {
        notification.error({
          message: 'Ошибка',
          description: 'Введите ИИН / БИН',
        });
      }
      dispatch({ type: 'search', payload: false });
    } catch (error) {
      console.log(error);

      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      notification.error({
        message: 'Ошибка',
        description: message,
      });
      dispatch({ type: 'search', payload: false });
    }
  };

  const onChange = (event, foreignId) => {
    if (event.target.value.length === 12) {
      const item = [...agrMembers];
      item[index].subj_iin = event.target.value;
      setAgrMembers(item);
    }

    if (event.target.value.length === 12 && member?.foreign_id) {
      setTimeout(() => {
        searchContractor(event.target.value, foreignId);
      }, 500);
    }
  };

  /**
   * По ISN роли возвращает текстовое наименование
   * @param roleIsn - ISN роли
   */
  const roleIsnToText = (roleIsn) => {
    if (roles.length) {
      return roles.find((role) => role.id === Number(roleIsn))?.label;
    }
  };

  /**
   * Добавляем в массив нового пользователя
   * @param memberRole - isn роли
   */
  const memberRoleChange = (memberRole) => {
    if (!index && index !== 0) return;
    const item = [...agrMembers];
    item[index].foreign_id = memberRole;
    setAgrMembers(item);

    if (member?.subj_iin && member?.foreign_id) {
      searchContractor(member.subj_iin, memberRole);
    }
  };
  return (
    <CustomLoader spinning={isLoading}>
      <Card
        style={{ backgroundColor: '#f8f8f8' }}
        className="quotation-members mt-5"
        size="small"
      >
        {member.IIN || member.user_isn ? (
          <Row className="align-center justify-space-between" gutter={18}>
            <Col span={6} style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: '18px', fontWeight: '700' }}>
                {roleIsnToText(member.class_isn || member.foreign_id)}
              </Text>
            </Col>
            <Col span={6} className="text-center">
              {member.FULLNAME || member.subj_full_name}
            </Col>
            <Col span={6} className="text-center">
              {member.IIN || member.subj_iin}
            </Col>
            <Col span={6} className="d-flex justify-space-between align-center">
              <div>{member.BIRTHDAY || member.subj_birthday}</div>
              <div>
                <Space
                  size="middle"
                  style={{ width: '100%', justifyContent: 'end' }}
                >
                  <Tooltip
                    placement="top"
                    title="Удалить участника"
                    mouseEnterDelay={1}
                  >
                    <Button
                      type="primary"
                      className="quotation-members__button"
                      danger
                      onClick={() => deleteMembers(member, index)}
                      disabled={
                        roleIsnToText(member.class_isn || member.foreign_id) !==
                          'Подписант от страховщика' &&
                        checkDisabledField(stateDateQuotation.status_isn)
                      }
                    >
                      <DeleteOutlined />
                    </Button>
                  </Tooltip>
                </Space>
              </div>
            </Col>
          </Row>
        ) : (
          <Row gutter={13}>
            <Col span={6} style={{ alignItems: 'center' }}>
              <ListMembers
                className="w-100"
                member={member}
                placeholder="Тип участника"
                members={roles}
                onChange={memberRoleChange}
              />
            </Col>
            <Col span={12}>
              <Form.Item className="mb-0">
                <CInput
                  className="digitsMask"
                  placeholder="Поиск по ИИН/БИН"
                  disabled={false}
                  size="large"
                  theme="light"
                  onChange={(e) => {
                    onChange(e, foreign_id);
                  }}
                ></CInput>
              </Form.Item>
            </Col>
            <Col span={5}>
              <CButton type="primary" className="c-button_left" block>
                <SearchOutlined />
                Расширенный поиск
              </CButton>
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                className="quotation-members__button"
                danger
                onClick={() => deleteMembers(member, index)}
              >
                <DeleteOutlined />
              </Button>
            </Col>
          </Row>
        )}
      </Card>
    </CustomLoader>
  );
};

export default CardMembers;
