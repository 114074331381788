import React, { memo, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IMainContract } from '@modules/Quotation/Interfaces/MainContract/index.interface';
import api from '@modules/Quotation/services';
import useAuth from '@hooks/useAuth';
import { Col, notification, Row, Typography } from 'antd';
import MainContractFormular from '@modules/Quotation/MainContract/MainContractFormular';
import MainContractRole from '@modules/Quotation/MainContract/MainContractRole';
import MainContractAdditionalAttributes from '@modules/Quotation/MainContract/MainContractAdditionalAttributes';
import MainContractClauses from '@modules/Quotation/MainContract/MainContractClauses';
import MainContractObjects from '@modules/Quotation/MainContract/MainContractObjects';
import MainContractAccruals from '@modules/Quotation/MainContract/MainContractAccruals';
import './MainContractSection.scss';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import CButton from '@components/UI/button/CButton';
import PrintedForm from '../PrintedForm/PrintedForm';
import { LeftOutlined } from '@ant-design/icons';
import MainContractDocument from './MainContractDocument';
import AddendumModal from '@modules/Quotation/MainContract/AddendumModal';
import DenounceModal from '@modules/Quotation/MainContract/DenounceModal';
import MainContractRequest from './MainContractRequest';
const MainContract: React.FC<IMainContract> = memo(() => {
  const { contractIsn } = useParams();
  const [contractData, setContractData] = useState<any>();
  const [userForeignId, setUserForeignId] = useState(0);
  const [quotationData, setQuotationData] = useState();
  const [documentTitle, setDocumentTitle] = useState<string>('');
  const [isAddendumOpen, setIsAddendumOpen] = useState<boolean>(false);
  const [isDenounceOpen, setIsDenounceOpen] = useState<boolean>(false);
  const auth = useAuth();
  const { Title } = Typography;

  const savedAccruals = (params: any) => {};

  useEffect(() => {
    if (contractIsn) {
      const fetchData = async () => {
        const { data } = await api.contractor.getAgreementByISN({
          agr_isn: contractIsn,
        });
        if (data) {
          setContractData(data);
        }
      };
      fetchData();
    }
  }, [contractIsn]);

  useEffect(() => {
    if (contractData?.data) {
      setDocumentTitle(
        `Договор ${contractData?.data?.agreement[0]?.productisn_name} #${contractIsn} «${contractData?.data?.agreement[0]?.id}»`
      );
      const fetchData2 = async () => {
        const { data } = await api.quotation.getFullQuotation(
          contractData?.data?.agreement[0]?.productisn
        );
        if (data) {
          setQuotationData(data);
        }
      };
      fetchData2();
    }
  }, [contractData]);

  useEffect(() => {
    if (!documentTitle) return;
    document.title = documentTitle;
  }, [documentTitle]);

  useEffect(() => {
    setUserForeignId(auth.user?.data?.user_info.foreign_id);
  }, [auth]);

  const contractProlongation = async () => {
    try {
      const { data } = await api.quotation.contractProlongation({
        isn: contractData.data.agreement[0].isn,
        user_isn: userForeignId,
      });
      if (data.NEWLY) {
        notification.success({
          message: 'Продление договора',
          description: 'Договор успешно продлен',
        });
        setTimeout(() => {
          window.location.replace(`/quotation/${data.AGREEMENTCALC_ID}`);
        }, 1100);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const contractDataSections = useMemo(() => {
    if (contractData?.data) {
      return Object.entries(contractData.data);
    }
    return null;
  }, [contractData]);

  return (
    <div className="main-contract">
      {contractDataSections?.length && (
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={1}>{documentTitle}</Title>
          </Col>
        </Row>
      )}

      {contractDataSections?.length && (
        <Row gutter={24} className="mt-5">
          {contractData.data.prolongation &&
            parseInt(contractData.data.agreement[0].stageisn) === 221103 && (
              <Col>
                <CButton onClick={contractProlongation} type="primary">
                  Продлить договор
                </CButton>
              </Col>
            )}
          {contractData.data.addendum_reason.length >= 1 && (
            <>
              <Col>
                <CButton onClick={() => setIsAddendumOpen(true)} type="primary">
                  Аддендум
                </CButton>
              </Col>
              <AddendumModal
                addendumReason={
                  contractDataSections?.length &&
                  contractData.data.addendum_reason
                }
                addendumModal={isAddendumOpen}
                handleCancel={() => setIsAddendumOpen(false)}
                docIsn={contractIsn}
              />
            </>
          )}
          {contractData.data.agreement[0].status_name === 'Выпущен' && (
            <Col>
              <PrintedForm
                agrIsn={contractData.data.agreement[0].isn}
                printAgrIsn={contractData.data.agreement[0].isn}
                userForeignId={userForeignId}
              />
            </Col>
          )}
          {contractData.data.agreement[0].status_name === 'Выпущен' && (
            <>
              <Col>
                <CButton
                  onClick={() => setIsDenounceOpen(true)}
                  type="primary"
                  danger
                >
                  Прекращение
                </CButton>
              </Col>
              <DenounceModal
                contactInfo={contractDataSections.length && contractData}
                denounceModalOpen={isDenounceOpen}
                handleCancel={() => setIsDenounceOpen(false)}
                docIsn={Number(contractIsn)}
              />
            </>
          )}
          <Col>
            <CButton
              href={`/quotation/${contractData?.data.agreement[0].quotation_id}`}
              type="default"
              icon={<LeftOutlined />}
              className="d-flex align-center justify-center"
            >
              Вернуться в котировку
            </CButton>
          </Col>
        </Row>
      )}

      {contractDataSections?.length ? (
        contractDataSections?.map(([key, array]: any[], index) => {
          if (array.length) {
            console.log(key);

            switch (key) {
              case 'agreement':
                return <MainContractFormular fields={array} key={index} />;
              case 'agrrole':
                return (
                  <MainContractRole
                    roles={array}
                    key={index}
                    userForeignId={userForeignId}
                    agrIsn={Number(contractIsn)}
                    contractData={contractData}
                  />
                );
              case 'addattr_insurer':
                return (
                  <>
                    <MainContractAdditionalAttributes
                      contractData={contractData}
                      fields={array}
                      key={index}
                      userForeignId={userForeignId}
                      agrIsn={Number(contractIsn)}
                      quotationData={quotationData}
                      productIsn={contractData?.data?.agreement[0]?.productisn}
                    />
                    <MainContractDocument
                      agrIsn={contractData.data.agreement[0].isn}
                      userForeignId={userForeignId}
                      objData={contractData?.data?.agrobject}
                      condData={contractData?.data?.agrcond}
                      onClick={savedAccruals}
                      agreement={contractData?.data?.docs.map((item) => {
                        return {
                          [item?.doc_classisn]: {
                            doc_classisn_name: item.doc_classisn_name,
                            doc_list: item,
                          },
                        };
                      })}
                    />
                  </>
                );
              case 'agrclause':
                return (
                  <MainContractClauses
                    clauses={array}
                    quotationData={quotationData}
                    key={index}
                  />
                );
              case 'agrobject':
                return (
                  <MainContractObjects
                    objects={array}
                    quotationData={contractData}
                    key={index}
                  />
                );
              case 'agrsum':
                return (
                  <MainContractAccruals
                    agrsums={array}
                    quotationData={contractData}
                    key={index}
                  />
                );
              case 'servreq':
                return (
                  <MainContractRequest
                    userForeignId={userForeignId}
                    agrIsn={Number(contractIsn)}
                    quotationData={contractData?.data}
                    productIsn={contractData?.data?.agreement[0]?.productisn}
                  />
                );
            }
          } else if (key === 'servreq') {
            return (
              <MainContractRequest
                userForeignId={userForeignId}
                agrIsn={Number(contractIsn)}
                quotationData={undefined}
                productIsn={contractData?.data?.agreement[0]?.productisn}
              />
            );
          }
        })
      ) : contractData ? (
        <div>Нет данных</div>
      ) : (
        <CustomLoader spinning={true} />
      )}
    </div>
  );
});

export default MainContract;
