import React, { useEffect } from 'react';

import { Card, Form, Row, Col, Select, Typography, notification } from 'antd';
import { IClausesProps } from '../Interfaces/ClausesQuotation/index.interface';
import useQuotation from '../hooks/useQuotation';
import CSelect from '@components/UI/select/CSelect';
import api from '@modules/Quotation/services';
import axios from 'axios';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
const { Option } = Select;

const Clauses: React.FC<IClausesProps> = ({ agrIsn, userForeignId }) => {
  const { productSelectedQuotation, agrClauses, stateDateQuotation } =
    useQuotation();
  const { Title } = Typography;

  /**
   * При выборе Ограничения/Оговорки из списка Select
   * отправляем на бэк
   * @param clauseId - параметр order_no в запросе для идентификации типа Ограничения/Оговорки
   */
  const onClauseChange = async ({ clause_isn, class_isn }) => {
    if (!clause_isn || !class_isn) return;

    const requestBody = {
      agr_clause: [
        {
          agr_isn: agrIsn,
          clause_isn,
          class_isn,
          user_isn: userForeignId,
        },
      ],
    };

    let message;
    let messageType = 'success';

    try {
      const { data } = await api.saveQuotation.clauseSave(requestBody);
      await api.quotation.setGraph({
        agr_isn: agrIsn,
        user_isn: userForeignId,
      });
      message = data.message;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }
      messageType = 'info';
      notification[messageType]({
        message: messageType === 'info' ? 'Ошибка' : null,
        description: message,
      });
    }
  };
  return (
    <Card className="mt-5">
      <Col xs={24} xl={8}>
        <Title className="main-quotation__section-title" level={3}>
          Ограничения/Оговорки
        </Title>
      </Col>
      <Row gutter={18} className="mt-5" style={{ marginBottom: -20 }}>
        {productSelectedQuotation?.map((product) =>
          product.clauses?.map((item, index) => {
            const cols =
              24 / product.clauses.filter((item) => item.active_mycent)?.length;
            if (item.active_mycent) {
              return (
                <Col xs={24} md={cols} key={index}>
                  <Form.Item
                    initialValue={
                      agrClauses
                        ?.filter(
                          (clause) => clause.clause_isn === item.clause_isn
                        )
                        .map((clause) => clause.class_isn)[0]
                    }
                    name={[
                      index,
                      `clause_isn: ${item.clause_isn}, agr_isn:, class_isn`,
                    ]}
                    label={item.clause_name}
                    rules={[
                      {
                        required: true,
                        message: `${item.clause_name} не выбран!`,
                      },
                    ]}
                  >
                    <CSelect
                      showSearch
                      optionFilterProp="children"
                      size="large"
                      placeholder={item.clause_name}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onSelect={(e) => {
                        onClauseChange({
                          clause_isn: item.clause_isn,
                          class_isn: e,
                        });
                      }}
                      disabled={checkDisabledField(
                        stateDateQuotation.status_isn
                      )}
                    >
                      {item?.clause_value?.map((element) =>
                        typeof element?.clause_value === 'string' ? (
                          <Option
                            value={element?.clause_value_isn}
                            key={element?.clause_value_isn}
                          >
                            {element?.clause_value}
                          </Option>
                        ) : typeof element?.full_name === 'string' ? (
                          <Option
                            value={element?.foreign_id}
                            key={element?.foreign_id}
                          >
                            {element?.full_name}
                          </Option>
                        ) : (
                          item?.clause_value?.map((element) =>
                            element?.clause_value?.map((item) =>
                              item.children?.map((children) => {
                                return (
                                  <Option
                                    value={children.foreign_id}
                                    key={children.foreign_id}
                                  >
                                    {children.full_name}
                                  </Option>
                                );
                              })
                            )
                          )
                        )
                      )}
                    </CSelect>
                  </Form.Item>
                </Col>
              );
            }
          })
        )}
      </Row>
    </Card>
  );
};

export default Clauses;
