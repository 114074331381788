import api from '@modules/Quotation/services';

import { Row, Col, Button, Input, Form, notification } from 'antd';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { STATUS_ISN } from '../DateQuotation';
import useFormSubmit from '../hooks/CommonMethods/MainComponents/useFormSubmit';
import useQuotation from '../hooks/useQuotation';
import useQuotationJournal from '../hooks/useQuotationJournal';
import { TypeAction } from '../providers/JournalReducer';
import { TypeAction as TypeActionDate } from '@modules/Quotation/providers/DateReducer';
import { agrCalcMethod } from './utils/agrCalcMethod';
import { saveQuotation, sendAllSectionsQuotation } from './utils/saveSections';
import { sendCo } from './utils/sendCO';
import { updateEmptyQuotation } from './utils/updateEmptyQuotation';
import CButton from '@components/UI/button/CButton';
import CInput from '@components/UI/input/CInput';
import { validateForms } from '@modules/Quotation/MainButtons/utils/validateForms';

type MainButtonsProps = {
  userForeignId: number;
  agrIsn: number | undefined;
  validateFormsArray?: any[];
  productIsn: any;
  quotationId: string | undefined;
  getFullData: any;
};

function MainButtons(props: MainButtonsProps) {
  const {
    userForeignId,
    agrIsn,
    validateFormsArray,
    productIsn,
    quotationId,
    getFullData,
  } = props;

  const [premiumTotal, setPremiumTotal] = useState(0);
  const [isLoadingCalcButton, setIsLoadingCalcButton] = useState(false);
  const [isLoadingCOButton, setIsLoadingCOButton] = useState(false);
  const [isLoadingSaveSections, setIsLoadingSaveSections] = useState(false);
  const [
    isLoadingSendAllSectionsQuotation,
    setIsLoadingSendAllSectionsQuotation,
  ] = useState(false);
  const [isLoadingCreateContract, setIsLoadingCreateContract] =
    useState<boolean>(false);
  const [isLoadingContract, setIsLoadingContract] = useState<boolean>(false);

  const {
    agrClauses,
    agrObj,
    agrAttributes,
    agrObjCar,
    agrObjAttributes,
    agrCond,
    setAgrCond,
    agrObjCarGo,
    fileList,
    stateDateQuotation,
    dispatchDate,
    agrContractor,
    setAgrContractor,
  } = useQuotation();

  const {
    clauseValueFilterPickLast,
    objectValueFilterPickLast,
    agrAttributesCheckEmptyOrNot,
    objectCarFilterPickLast,
    objectCargoFilterPickLast,
    agrObjectAttributesCheckEmptyOrNot,
    agrCondFilterPickLast,
    fileListSend,
  } = useFormSubmit();

  const { state, dispatch } = useQuotationJournal();

  const form = Form.useFormInstance();

  const { agrCalc } = useFormSubmit();

  useEffect(() => {
    if (premiumTotal === 0) return;
    form.setFieldsValue({
      agrCalculateData: premiumTotal,
    });
  }, [premiumTotal]);

  /**
   * Если котировка была рассчитана - подставляем сумму расчета
   */
  useEffect(() => {
    if (agrContractor?.premium_sum_total) {
      form.setFieldsValue({
        agrCalculateData: agrContractor?.premium_sum_total,
      });
    }
  }, [agrContractor?.premium_sum_total]);

  /**
   * Переотправляет все блоки на бэк и делет рассчет
   */
  const callSaveQuotation = () => {
    validateForms(validateFormsArray)
      .then((success) => {
        /**
         * Отправляет все разделы на бэк
         */
        sendAllSectionsQuotation({
          agrObj,
          agrCond,
          setAgrCond,
          // agrClauses,
          agrAttributes,
          stateDateQuotation,
          productIsn,
          setIsLoadingSendAllSectionsQuotation,
        }).then(() => {
          /**
           * Возвращает рассчет премии
           */
          agrCalcMethod(
            agrCalc,
            setPremiumTotal,
            userForeignId,
            agrIsn,
            setIsLoadingCalcButton
          ).then(async () => {
            if (Number(productIsn) === 471541) {
              await getFullData();
            } else {
              await sendAllSectionsQuotation({
                agrObj,
                agrCond,
                setAgrCond,
                productIsn,
              });
            }
          });
        });
      })
      .catch((error) => {
        /**
         * Скроллим до самой верхней ошибки
         */
        setTimeout(() => {
          const errorInputs = document.querySelectorAll(
            '.ant-form-item-has-error'
          );

          if (errorInputs.length > 0) {
            const firstErrorInput = errorInputs[0];
            firstErrorInput.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        }, 100);

        notification.error({
          message: 'Ошибка',
          description: 'Не заполнены обязательные поля',
        });
      });

    // TODO: Подтягивать обновления полей Тариф и т.д.
    /* Старое, надо разбираться
    saveQuotation({
      agrClauses,
      agrObj,
      agrAttributes,
      clauseValueFilterPickLast,
      objectValueFilterPickLast,
      agrAttributesCheckEmptyOrNot,
      agrObjCar,
      agrObjAttributes,
      agrCond,
      agrObjCarGo,
      objectCarFilterPickLast,
      agrObjectAttributesCheckEmptyOrNot,
      agrCondFilterPickLast,
      objectCargoFilterPickLast,
      fileListSend,
      userForeignId,
      agrIsn,
      fileList,
      setIsLoadingSaveSections,
    });

    updateEmptyQuotation(
      agrIsn,
      stateDateQuotation,
      userForeignId,
      setIsLoadingSaveSections,
      dispatchDate
    );
    */
  };

  const callAgrCalcMethod = () => {
    agrCalcMethod(
      agrCalc,
      setPremiumTotal,
      userForeignId,
      agrIsn,
      setIsLoadingCalcButton
    );
  };

  const callSendCoMethod = () => {
    // fourth param === 1
    // means that send to DA (underwriting department)

    sendCo(form, setIsLoadingCOButton, agrIsn, userForeignId, 1);
  };

  /**
   * Создает договор?
   */
  const createIssueContract = async () => {
    const payloadData = {
      agr_isn: agrIsn,
      user_isn: userForeignId,
    };
    try {
      setIsLoadingContract(true);
      const { data } = await api.quotation.createIssueContract(payloadData);
      if (data?.success === true) {
        const agreementData = await api.quotationJournal.getAgreementData(
          agrIsn,
          userForeignId
        );
        notification.info({
          message: 'Уведомление',
          description: data?.data?.STATUS,
        });
        if (agreementData.data?.success === true) {
          dispatch({
            type: TypeAction.agreementData,
            payload: agreementData.data?.data,
          });
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        notification.info({
          message: 'Ошибка',
          description: String(err.response.data.error),
          duration: 0,
        });
      } else {
        notification.info({
          message: 'Ошибка',
          description: String(error),
          duration: 0,
        });
      }
    } finally {
      setIsLoadingContract(false);
    }
  };

  /**
   * Создать договор
   */
  const createContract = async () => {
    const payloadData = {
      agr_isn: agrIsn,
      user_isn: userForeignId,
    };
    try {
      setIsLoadingCreateContract(true);
      const { data } = await api.saveQuotation.createAgrFromCalc(payloadData);
      if (data?.success === true) {
        // Получает данные по созданному договору?
        const agreementData = await api.quotationJournal.getAgreementData(
          agrIsn,
          userForeignId
        );
        if (agreementData.data?.success === true) {
          dispatch({
            type: TypeAction.agreementData,
            payload: agreementData.data?.data,
          });

          setAgrContractor({
            ...agrContractor,
            agr_id: agreementData.data?.data?.ID,
            agr_status: agreementData.data?.data?.STATUS,
          });
          dispatchDate({
            type: TypeActionDate.AGR_STATUS,
            payload: agreementData.data?.data?.STATUS,
          });
        }
        await getFullData();
        notification.info({
          message: 'Уведомление',
          description: data?.data?.STATUS || agreementData.data?.data?.STATUS,
        });
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        notification.info({
          message: 'Ошибка',
          description: String(err.response.data.error),
          duration: 0,
        });
      } else {
        notification.info({
          message: 'Ошибка',
          description: String(error),
          duration: 0,
        });
      }
    } finally {
      setIsLoadingCreateContract(false);
    }
  };

  /**
   * Выпустить договор
   */
  const buildContract = async () => {
    const payloadData = {
      agr_isn: agrIsn,
      user_isn: userForeignId,
    };
    try {
      setIsLoadingContract(true);
      const { data } = await api.quotation.buildContract(payloadData);
      if (data?.success === true) {
        const agreementData = await api.quotationJournal.getAgreementData(
          agrIsn,
          userForeignId
        );
        notification.info({
          message: 'Уведомление',
          description: data?.data?.STATUS,
        });
        if (agreementData.data?.success === true) {
          dispatch({
            type: TypeAction.agreementData,
            payload: agreementData.data?.data,
          });
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        notification.info({
          message: 'Ошибка',
          description: String(err.response.data.error),
          duration: 0,
        });
      } else {
        notification.info({
          message: 'Ошибка',
          description: String(error),
          duration: 0,
        });
      }
    } finally {
      setIsLoadingContract(false);
    }
  };
  return (
    <>
      <Row gutter={[24, { xs: 8, sm: 8, md: 0 }]} style={{ marginTop: 20 }}>
        <Col xs={24} md={6}>
          <CButton
            type="primary"
            block
            onClick={callSaveQuotation}
            loading={isLoadingSendAllSectionsQuotation || isLoadingCalcButton}
            htmlType="submit"
            disabled={stateDateQuotation?.status_isn !== 223368}
          >
            Рассчитать стоимость
          </CButton>
        </Col>
        <Col xs={24} md={6}>
          <Form.Item
            name="agrCalculateData"
            label="Сумма общей премии"
            // initialValue={agrContractor?.premium_sum_total}
          >
            <CInput
              className="numericMask"
              readOnly
              theme="light"
              size="large"
            />
          </Form.Item>
        </Col>
        {stateDateQuotation?.status_isn !== 223368 &&
          stateDateQuotation?.status_isn !== 223369 && (
            <Col xs={24} md={6}>
              <Form.Item>
                <CButton
                  type="primary"
                  htmlType="submit"
                  block
                  onClick={createContract}
                  loading={isLoadingCreateContract}
                  disabled={
                    !(
                      stateDateQuotation.status_isn === STATUS_ISN.AGREED &&
                      parseInt(state.agreementData.ISSUED) !== 1
                    ) || stateDateQuotation?.agr_status === 'Оформление'
                  }
                >
                  Создать договор
                </CButton>
              </Form.Item>
            </Col>
          )}
        {/* {stateDateQuotation?.agr_status === 'Оформление' &&
          stateDateQuotation?.status_isn === 223370 && (
            <Col xs={24} md={6}>
              <CButton
                type="primary"
                block
                onClick={buildContract}
                loading={isLoadingContract}
                disabled={
                  !(
                    stateDateQuotation.status_isn === STATUS_ISN.AGREED &&
                    parseInt(state.agreementData.ISSUED) !== 1
                  )
                }
              >
                Выпустить договор
              </CButton>
            </Col>
          )} */}

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => prevValues !== curValues}
        >
          {({ getFieldsValue }) => {
            const { submitUnderwritingDepartment } = getFieldsValue();
            if (submitUnderwritingDepartment === true) {
              return (
                <Col xs={24} xl={4}>
                  <Form.Item name="change status" label=" ">
                    <Button
                      type="primary"
                      block
                      onClick={callSendCoMethod}
                      loading={isLoadingCOButton}
                    >
                      Отправить в ДА
                    </Button>
                  </Form.Item>
                </Col>
              );
            }
          }}
        </Form.Item>
        {/* stateDateQuotation.status_isn === STATUS_ISN.AGREED &&
          parseInt(state.agreementData.ISSUED) !== 1 && (
            <Col xs={24} xl={8}>
              <Form.Item label=" ">
                <Button
                  onClick={createIssueContract}
                  block
                  type="primary"
                  loading={isLoadingContract}
                >
                  Создать договор
                </Button>
              </Form.Item>
            </Col>
          ) */}
      </Row>
    </>
  );
}

export default MainButtons;
