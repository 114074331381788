import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const monthNames = [
  'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
  'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
];

interface IRaitingType {
  id: number;
  emplisn: number;
  period: string;
  rate: string;
  percentage: string;
  created_at: string;
  updated_at: string;
}

interface IRaitingSales {
  listRaiting: Array<IRaitingType>;
}

const RaitingSales: React.FC<IRaitingSales> = ({ listRaiting }: IRaitingSales) => {

  const getCurrentMonthLabels = () => {
    // const currentMonthIndex = new Date().getMonth();
    return monthNames.slice(0, listRaiting.length);
  };

  console.log('listRaiting', listRaiting);
  const [raiting, setRaiting] = useState<number>(0);
  const [raitingName, setRaitingName] = useState<string>('');
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            setRaiting(context.raw);
            return `Рейтинг: ${context.dataset.rating}`;
          },
        },
      },
    }, 
  };

  if (listRaiting.length) {
    useEffect(() => {
      const calcRaiting: any = listRaiting?.find(item => {
        if (parseFloat(item.percentage) === raiting) {
          return item;
        }
      });
      setRaitingName(calcRaiting?.rate);
    }, [raiting]);
  }

  const getDataForMonth = (month: string) => {
    const matchingItem = listRaiting.find(item => {
      const date = new Date(item.period);
      const options: Intl.DateTimeFormatOptions = { month: 'long' };
      const monthName = date.toLocaleString('ru-RU', options);
      const capitalizedMonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
      return capitalizedMonthName === month;
    });
    return matchingItem ? parseFloat(matchingItem.percentage) : 0;
  };

  const labels = getCurrentMonthLabels();

  const data = {
    labels,
    datasets: [
      {
        label: 'Рейтинг продавца',
        data: labels.map(month => getDataForMonth(month)),
        borderColor: '#1677ff',
        backgroundColor: '#1677ff',
        fill: false, // Убедитесь, что fill отключен, чтобы линия была видна,
        rating: raitingName ? `${raitingName}` : 0, // сделай с помощью этого рейтинга
      },
    ],
  };

  return <Line options={options} data={data} />;
};

export default RaitingSales;
