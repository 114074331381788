import CInput from '@components/UI/input/CInput';
import CSelect from '@components/UI/select/CSelect';
import useCityQuotation from '@modules/Quotation/hooks/useCityQuotation';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Input,
  notification,
  Checkbox,
} from 'antd';
import api from '@modules/Quotation/services';
import TreeSelectCities from '@modules/TreeSelect/TreeSelectCities';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import useQuotation from '../../hooks/useQuotation';
import CButton from '@components/UI/button/CButton';
import { DeleteFilled, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { useImmerReducer } from 'use-immer';
import SearchClientModal from '@modules/common/Client/SearchClientModal';
import contractorFunctions from '@modules/common/Client/Functions';
import ResultClientModal from '@modules/common/Client/ResultClientModal';
import axios from 'axios';
import AddClientModal from '@modules/common/Client/AddClientModal';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
import SubjectCard from './SubjectCard';
const { Search } = Input;

const { Option } = Select;
type Action = {
  type:
    | 'nextStep'
    | 'backStep'
    | 'showSearchContractorModal'
    | 'hideContractorModal'
    | 'getContractorInfo'
    | 'searchContractor'
    | 'saveContractorList'
    | 'selectContractor'
    | 'search'
    | 'loading'
    | 'removeContractInfo';
  payload?: any;
};

interface contractorFullInfo {
  isn: number;
  fullname: string;
}
interface State {
  step: number;
  isVisibleConstactorModal: boolean;
  contractorInfo: any[] | null;
  contractorList: [] | null;
  contractorFullInfo: contractorFullInfo | null;
  isLoading: boolean;
  isJurUser: boolean;
  from: any;
}
const initialState: State = {
  step: 0,
  isVisibleConstactorModal: false,
  contractorInfo: null,
  contractorList: null,
  contractorFullInfo: null,
  isLoading: false,
  isJurUser: true,
  from: null,
};
const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'showSearchContractorModal': {
      draft.step = 1;
      draft.isVisibleConstactorModal = true;
      break;
    }
    case 'loading': {
      draft.isLoading = action.payload;
      break;
    }
    case 'searchContractor': {
      draft.step = 2;
      break;
    }
    case 'saveContractorList': {
      draft.isJurUser = action.payload.isJurUser == 'Y';
      draft.contractorList = action.payload.data;
      break;
    }
    case 'hideContractorModal': {
      draft.step = 0;
      break;
    }
    case 'selectContractor': {
      draft.isJurUser = action.payload.juridical == 'Y';
      draft.contractorFullInfo = action.payload.data;
      if (action.payload.data) {
        draft.contractorInfo = [
          {
            FULLNAME: action.payload.data.fullname,
            IIN: action.payload.data.iin,
            BIRTHDAY: action.payload.data.birthday,
            ISN: action.payload.data.isn,
          },
        ];
      }
      break;
    }
    case 'getContractorInfo': {
      draft.contractorInfo = action.payload;
      break;
    }
    case 'removeContractInfo': {
      draft.contractorInfo = null;
      draft.contractorFullInfo = action.payload;
      if (action.payload?.jur) {
        draft.isJurUser = action.payload.jur == 'Y';
      }
      break;
    }
    case 'nextStep': {
      if (action.payload.step) {
        draft.step = action.payload.step;
      } else {
        draft.step = action.payload;
      }
      if (action.payload.from) {
        draft.from = action.payload.from;
      }
      break;
    }
    case 'backStep': {
      draft.step = draft.step - 1;
      break;
    }
    case 'search': {
      draft.isLoading = action.payload;
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const ObjectCardMainDynamic = ({
  dicti,
  agrIsn,
  saveObject,
  AdvancedContractorSearch,
  modalSetting,
  mainForm,
  selectedObj,
}) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const {
    isVisibleConstactorModal,
    contractorInfo,
    step,
    contractorList,
    isLoading,
    contractorFullInfo,
    isJurUser,
    from,
  } = state;
  const { productSelectedQuotation, stateDateQuotation, agrCond } =
    useQuotation();
  const { cityData } = useCityQuotation();
  useEffect(() => {
    if (selectedObj) {
      mainForm.setFieldsValue({
        fid_obj: selectedObj.fid_obj,
        obj_isn: selectedObj.isn,
        obj_name: selectedObj.obj_name,
        class_isn: Number(selectedObj.class_isn),
        sub_class_isn: +selectedObj.sub_class_isn,
        cityisn: selectedObj.cityisn,
        address: selectedObj.address,
        count: selectedObj.obj_count,
        human_isn: selectedObj.subj_isn,
      });
      if (Number(selectedObj.class_isn) === 2125 && selectedObj.agrobjrail) {
        let setData = {};
        Object.keys(selectedObj.agrobjrail[0]).map((key) => {
          modalSetting.item.card.map(async (elem) => {
            if (elem.column_name === key.toUpperCase()) {
              if (elem.code === 'DICTI') {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: Number(selectedObj.agrobjrail[0][key]),
                };
              } else if (elem.code === 'DATE') {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: selectedObj.agrobjrail[0][key]
                    ? dayjs(selectedObj.agrobjrail[0][key], 'DD-MM-YYYY')
                    : null,
                };
              } else if (elem.code === 'SUBJECT') {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: selectedObj.agrobjrail[0][key],
                };

                dispatch({ type: 'loading', payload: false });
              } else {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: selectedObj.agrobjrail[0][key],
                };
              }
            }
          });
        });
        mainForm.setFieldsValue({
          ...setData,
        });
      } else if (
        Number(selectedObj.class_isn) === 2119 &&
        selectedObj.agrobjmarine
      ) {
        let setData = {};

        Object.keys(selectedObj.agrobjmarine[0]).map((key) => {
          modalSetting.item.card.map(async (elem) => {
            if (elem.column_name === key.toUpperCase()) {
              if (elem.code === 'DICTI') {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: Number(selectedObj.agrobjmarine[0][key]),
                };
              } else if (elem.code === 'DATE') {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: selectedObj.agrobjmarine[0][key]
                    ? dayjs(selectedObj.agrobjmarine[0][key], 'DD-MM-YYYY')
                    : null,
                };
              } else if (elem.code === 'SUBJECT') {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: selectedObj.agrobjmarine[0][key],
                };

                dispatch({ type: 'loading', payload: false });
              } else {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: selectedObj.agrobjmarine[0][key],
                };
              }
            }
          });
        });
        mainForm.setFieldsValue({
          ...setData,
        });
      } else if (
        Number(selectedObj.class_isn) === 2120 &&
        selectedObj.agrobjcargo
      ) {
        let setData = {};
        Object.keys(selectedObj.agrobjcargo[0]).map((key) => {
          modalSetting.item.card.map(async (elem) => {
            if (elem.column_name === key.toUpperCase()) {
              if (elem.code === 'DICTI') {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: selectedObj.agrobjcargo[0][key]
                    ? Number(selectedObj.agrobjcargo[0][key])
                    : null,
                };
              } else if (elem.code === 'DATE') {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: selectedObj.agrobjcargo[0][key]
                    ? dayjs(selectedObj.agrobjcargo[0][key], 'DD-MM-YYYY')
                    : null,
                };
              } else if (elem.code === 'SUBJECT') {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: selectedObj.agrobjcargo[0][key],
                };

                dispatch({ type: 'loading', payload: false });
              } else {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: selectedObj.agrobjcargo[0][key],
                };
              }
            }
          });
        });
        mainForm.setFieldsValue({
          ...setData,
        });
      } else if (
        Number(selectedObj.class_isn) === 2117 &&
        selectedObj.agrobjavia
      ) {
        let setData = {};
        Object.keys(selectedObj.agrobjavia[0]).map((key) => {
          modalSetting.item.card.map(async (elem) => {
            if (elem.column_name === key.toUpperCase()) {
              if (elem.code === 'DICTI') {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: selectedObj.agrobjavia[0][key]
                    ? Number(selectedObj.agrobjavia[0][key])
                    : null,
                };
              } else if (elem.code === 'DATE') {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: selectedObj.agrobjavia[0][key]
                    ? dayjs(selectedObj.agrobjavia[0][key], 'DD-MM-YYYY')
                    : null,
                };
              } else if (elem.code === 'SUBJECT') {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: selectedObj.agrobjavia[0][key],
                };

                dispatch({ type: 'loading', payload: false });
              } else {
                setData = {
                  ...setData,
                  [key.toUpperCase()]: selectedObj.agrobjavia[0][key],
                };
              }
            }
          });
        });
        mainForm.setFieldsValue({
          ...setData,
        });
      }
    } else {
      mainForm.setFieldValue('class_isn', Number(modalSetting.item.isn));
      if (modalSetting.item.card.length) {
        modalSetting.item.card.map((item) => {
          if (item.code === 'CHECKBOX') {
            console.log(item);

            mainForm.setFieldValue(item.column_name, 'N');
          }
        });
      }
    }
  }, [selectedObj]);

  const selectContractor = async (value, fromInfo) => {
    try {
      dispatch({ type: 'search', payload: true });
      if (!value.ISN && !value.isn) {
        const info = await api.contractor.saveContractorKias({
          xml_isn: value.XMLISN,
          ext_system_key: value.EXTSYSTEMKEY,
        });
        const { data } = await api.contractor.getContractorByISN(info.data.isn);
        dispatch({
          type: 'selectContractor',
          payload: { data: data.data, juridical: data.data.juridical },
        });
      } else {
        const isn = value.ISN ? value.ISN : value.isn;
        const { data } = await api.contractor.getContractorByISN(isn);
        dispatch({
          type: 'selectContractor',
          payload: { data: data.data, juridical: data.data.juridical },
        });
      }
      dispatch({ type: 'nextStep', payload: 3 });
      dispatch({ type: 'search', payload: false });
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      dispatch({ type: 'search', payload: false });
      notification.error({
        message: 'Ошибка',
        description: message,
      });
    }
  };
  const handleCancelAddClientModal = () => {
    dispatch({ type: 'hideContractorModal', payload: 0 });
    dispatch({ type: 'nextStep', payload: { from: null } });
  };
  const getContractorList = (values) => {
    contractorFunctions.getContractorList({ dispatch, values });
  };
  const handleDeleteUser = () => {
    dispatch({ type: 'removeContractInfo', payload: null });
    mainForm.setFieldsValue({
      ...mainForm.getFieldsValue(),
      class_human: null,
    });
  };
  const changeState = (step, data: any | null, fromInfo) => {
    dispatch({ type: 'nextStep', payload: { step, from: fromInfo } });
    if (data !== null) {
      dispatch({ type: 'saveContractorList', payload: { data } });
    }
    if (fromInfo == 'related') {
      dispatch({
        type: 'removeContractInfo',
        payload: { ...contractorFullInfo, from: 'related', jur: 'Y' },
      });
    }
  };

  return (
    <Form
      className="c-form"
      layout="vertical"
      name="transportCard"
      form={mainForm}
    >
      <Row gutter={6}>
        <Form.Item name="fid_obj" hidden noStyle>
          <CInput />
        </Form.Item>
        <Form.Item name="obj_isn" hidden noStyle>
          <CInput />
        </Form.Item>
        {modalSetting?.item?.card[0] && (
          <Col span={6}>
            <Form.Item name="class_isn" label="Объект">
              <CSelect placeholder="Объект" disabled={true}>
                <Option
                  value={modalSetting.item.isn}
                  key={modalSetting.item.isn}
                >
                  {modalSetting.item.full_name}
                </Option>
              </CSelect>
            </Form.Item>
          </Col>
        )}
        {modalSetting?.item?.card[0] && (
          <Col span={6}>
            <Form.Item name="sub_class_isn" label="Тип объекта">
              <CSelect
                placeholder="Тип объекта"
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                {modalSetting?.item?.child.length > 0 &&
                  modalSetting?.item?.child?.map((item) => {
                    return (
                      <Option
                        value={
                          item.sub_class_obj_isn
                            ? item.sub_class_obj_isn
                            : item.class_obj_isn
                        }
                        key={
                          item.sub_class_obj_isn
                            ? item.sub_class_obj_isn
                            : item.class_obj_isn
                        }
                      >
                        {item.sub_class_obj_name
                          ? item.sub_class_obj_name
                          : item.class_obj_name}
                      </Option>
                    );
                  })}
              </CSelect>
            </Form.Item>
          </Col>
        )}
        {modalSetting?.item?.card[0] && (
          <Col span={6}>
            <Form.Item name="obj_name" label="Наименование объекта">
              <CInput
                placeholder="Наименование объекта"
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {modalSetting?.item?.card[0]
          ? modalSetting?.item?.card?.map((elem, index) => {
              switch (elem.code) {
                case 'NUMBER': {
                  if (elem.column_name === 'ISN') {
                    return (
                      <Form.Item
                        name={elem.column_name}
                        label={elem.name}
                        hidden
                        noStyle
                      >
                        <CInput
                          type="number"
                          placeholder={elem.name}
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                    );
                  } else {
                    return (
                      <Col span={6}>
                        <Form.Item name={elem.column_name} label={elem.name}>
                          <CInput
                            type="number"
                            placeholder={elem.name}
                            disabled={checkDisabledField(
                              stateDateQuotation.status_isn
                            )}
                          />
                        </Form.Item>
                      </Col>
                    );
                  }
                }
                case 'TEXT':
                case 'CITY': {
                  return (
                    <Col span={6}>
                      <Form.Item name={elem.column_name} label={elem.name}>
                        <CInput
                          placeholder={elem.name}
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                    </Col>
                  );
                }
                case 'DATE': {
                  return (
                    <Col span={6}>
                      <Form.Item name={elem.column_name} label={elem.name}>
                        <DatePicker
                          placeholder={elem.name}
                          format="DD.MM.YYYY"
                          style={{ width: '100%' }}
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                    </Col>
                  );
                }
                case 'DICTI': {
                  return (
                    <Col span={6}>
                      <Form.Item name={elem.column_name} label={elem.name}>
                        <CSelect
                          placeholder={elem.name}
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        >
                          {dicti
                            ? dicti[elem.dicti_isn]?.children?.map((item) => (
                                <Option
                                  value={item.foreign_id}
                                  key={item.foreign_id}
                                >
                                  {item.full_name}
                                </Option>
                              ))
                            : ''}
                        </CSelect>
                      </Form.Item>
                    </Col>
                  );
                }
                case 'SUBJECT': {
                  return (
                    <SubjectCard
                      key={`fullname-${elem.column_name}`}
                      elem={{
                        column_name: elem.column_name,
                        full_name: `fullname-${elem.column_name}`,
                        label: elem.name,
                      }}
                      stateDateQuotation={stateDateQuotation}
                      mainForm={mainForm}
                      col={6}
                    />
                  );
                }
                case 'CHECKBOX': {
                  return (
                    <Col span={6}>
                      <Form.Item
                        // label={elem.name}
                        name={elem.column_name}
                        // valuePropName="checked"
                      >
                        <Checkbox
                          // defaultChecked={false}
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        >
                          {elem.name}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  );
                }
              }
            })
          : Object.keys(modalSetting?.item?.card).map((key) => {
              switch (key) {
                case 'class_name':
                  return (
                    <Col span={8}>
                      <Form.Item name="obj_name" label="Наименование объекта">
                        <CInput
                          placeholder="Наименование объекта"
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        />
                      </Form.Item>
                    </Col>
                  );
                case 'class_obj':
                  return (
                    <Col span={8}>
                      <Form.Item name="class_isn" label="Класс объекта">
                        <CSelect placeholder="Класс объекта" disabled={true}>
                          <Option
                            value={modalSetting.item.card[key].isn}
                            key={modalSetting.item.card[key].isn}
                          >
                            {modalSetting.item.card[key].name}
                          </Option>
                        </CSelect>
                      </Form.Item>
                    </Col>
                  );
                case 'address': {
                  if (
                    modalSetting.item.card[key] !== null &&
                    modalSetting.item.card[key].address === 1
                  ) {
                    return (
                      <>
                        <Col span={12}>
                          <Form.Item label="Регион/город" name="cityisn">
                            <TreeSelectCities
                              placeholder="Регион/город"
                              citiesList={cityData}
                              disabled={checkDisabledField(
                                stateDateQuotation.status_isn
                              )}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="address" label="Адрес">
                            <CInput
                              placeholder="Адрес"
                              disabled={checkDisabledField(
                                stateDateQuotation.status_isn
                              )}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    );
                  }
                  return null;
                }
                case 'class_human': {
                  if (modalSetting.item.card[key] !== null) {
                    return (
                      <SubjectCard
                        elem={{
                          column_name: 'human_isn',
                          full_name: 'class_human',
                          label: 'Контрагент',
                        }}
                        stateDateQuotation={stateDateQuotation}
                        mainForm={mainForm}
                        col={8}
                      />
                    );
                  } else {
                    break;
                  }
                }
                case 'count': {
                  if (modalSetting.item.card[key] !== null) {
                    return (
                      <Col span={8}>
                        <Form.Item name="count" label="Количество">
                          <CInput
                            type="number"
                            placeholder="Количество"
                            disabled={checkDisabledField(
                              stateDateQuotation.status_isn
                            )}
                          />
                        </Form.Item>
                      </Col>
                    );
                  } else {
                    break;
                  }
                }
                case 'class_type': {
                  return (
                    <Col span={8}>
                      <Form.Item
                        name={modalSetting?.item?.card[key].column}
                        label="Тип объекта"
                      >
                        <CSelect
                          placeholder="Тип объекта"
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                        >
                          {modalSetting?.item?.card?.dictis?.children.length > 0
                            ? modalSetting?.item?.card?.dictis?.children?.map(
                                (item) => (
                                  <Option
                                    value={item.foreign_id}
                                    key={item.foreign_id}
                                  >
                                    {item.full_name}
                                  </Option>
                                )
                              )
                            : modalSetting?.item.child.map((item) => {
                                return (
                                  <Option
                                    value={
                                      item.sub_class_obj_isn
                                        ? item.sub_class_obj_isn
                                        : item.class_obj_isn
                                    }
                                    key={
                                      item.sub_class_obj_isn
                                        ? item.sub_class_obj_isn
                                        : item.class_obj_isn
                                    }
                                  >
                                    {item.sub_class_obj_name
                                      ? item.sub_class_obj_name
                                      : item.class_obj_name}
                                  </Option>
                                );
                              })}
                        </CSelect>
                      </Form.Item>
                    </Col>
                  );
                }
              }
            })}
      </Row>
      <Col span={24}>
        <Button type="primary" style={{ width: '100%' }} onClick={saveObject}>
          Сохранить
        </Button>
      </Col>
      {step == 1 && (
        <SearchClientModal
          isLoading={isLoading}
          from={from}
          contractorFullInfo={contractorFullInfo}
          isVisible={isVisibleConstactorModal}
          handleCancel={() =>
            contractorFunctions.handelCancelSearchContractorModal(
              dispatch,
              from
            )
          }
          getContractorList={getContractorList}
        />
      )}
      {step == 2 && (
        <ResultClientModal
          isLoading={isLoading}
          contractorList={contractorList}
          handleCancel={() =>
            contractorFunctions.handelCancelSearchContractorModal(
              dispatch,
              from
            )
          }
          selectContractor={selectContractor}
          createContractor={() => {}}
          from={from}
        />
      )}
      {step == 3 && (
        <AddClientModal
          isLoading={isLoading}
          isJurUser={isJurUser}
          contractorFullInfo={contractorFullInfo}
          handleCancel={handleCancelAddClientModal}
          changeState={changeState}
        />
      )}
    </Form>
  );
};
export default ObjectCardMainDynamic;
