import { DeleteFilled, EditOutlined } from '@ant-design/icons';
import CInput from '@components/UI/input/CInput';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
import AddClientModal from '@modules/common/Client/AddClientModal';
import ResultClientModal from '@modules/common/Client/ResultClientModal';
import SearchClientModal from '@modules/common/Client/SearchClientModal';
import { Button, Col, Form, Input, notification } from 'antd';
import contractorFunctions from '@modules/common/Client/Functions';
import api from '@modules/Quotation/services';
import { useImmerReducer } from 'use-immer';
import axios from 'axios';
import { useEffect } from 'react';
import { SizeType } from 'antd/es/config-provider/SizeContext';
const { Search } = Input;
type Action = {
  type:
    | 'nextStep'
    | 'backStep'
    | 'showSearchSubjectModal'
    | 'hideSubjectModal'
    | 'getSubjectInfo'
    | 'searchSubject'
    | 'saveSubjectList'
    | 'selectSubject'
    | 'search'
    | 'loading'
    | 'hideContractorModal'
    | 'removeContractInfo'
    | 'selectContractor'
    | 'removeSubjectInfo';
  payload?: any;
};
interface State {
  step: number;
  isVisibleSubjectModal: boolean;
  subjectInfo: any[] | null;
  subjectList: [] | null;
  subjectFullInfo: any | null;
  isLoading: boolean;
  isJurUser: boolean;
  from: any;
}
const initialState: State = {
  step: 0,
  isVisibleSubjectModal: false,
  subjectInfo: null,
  subjectList: null,
  subjectFullInfo: null,
  isLoading: false,
  isJurUser: true,
  from: null,
};

const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'showSearchSubjectModal': {
      draft.step = 1;
      draft.isVisibleSubjectModal = true;
      break;
    }
    case 'loading': {
      draft.isLoading = action.payload;
      break;
    }
    case 'searchSubject': {
      draft.step = 2;
      break;
    }
    case 'saveSubjectList': {
      draft.isJurUser = action.payload.isJurUser == 'Y';
      draft.subjectList = action.payload.data;
      break;
    }
    case 'hideSubjectModal': {
      draft.step = 0;
      break;
    }
    case 'hideContractorModal': {
      draft.step = 0;
      break;
    }
    case 'selectSubject':
    case 'selectContractor': {
      draft.isJurUser = action.payload.juridical == 'Y';
      draft.subjectFullInfo = action.payload.data;
      if (action.payload.data) {
        draft.subjectInfo = [
          {
            FULLNAME: action.payload.data.fullname,
            IIN: action.payload.data.iin,
            BIRTHDAY: action.payload.data.birthday,
            ISN: action.payload.data.isn,
          },
        ];
      }
      break;
    }
    case 'getSubjectInfo': {
      draft.subjectInfo = action.payload;
      break;
    }
    case 'removeSubjectInfo':
    case 'removeContractInfo': {
      draft.subjectInfo = null;
      draft.subjectFullInfo = action.payload;
      if (action.payload?.jur) {
        draft.isJurUser = action.payload.jur == 'Y';
      }
      break;
    }
    case 'nextStep': {
      if (action.payload.step) {
        draft.step = action.payload.step;
      } else {
        draft.step = action.payload;
      }
      if (action.payload.from) {
        draft.from = action.payload.from;
      }
      break;
    }
    case 'backStep': {
      draft.step = draft.step - 1;
      break;
    }
    case 'search': {
      draft.isLoading = action.payload;
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

interface Props {
  elem: any;
  stateDateQuotation?: any;
  mainForm: any;
  col?: number;
  lg?: number;
  xs?: number;
  size?: SizeType;
  onChange?: any;
  className?: string;
  disabled?: boolean;
}
const SubjectCard = ({
  elem,
  stateDateQuotation,
  mainForm,
  col,
  size,
  onChange,
  lg,
  xs,
  className,
  disabled,
}: Props) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const {
    isVisibleSubjectModal,
    subjectInfo,
    step,
    subjectList,
    isLoading,
    subjectFullInfo,
    isJurUser,
    from,
  } = state;
  const selectContractor = async (value, fromInfo) => {
    try {
      dispatch({ type: 'search', payload: true });
      if (!value.ISN && !value.isn) {
        const info = await api.contractor.saveContractorKias({
          xml_isn: value.XMLISN,
          ext_system_key: value.EXTSYSTEMKEY,
        });
        const { data } = await api.contractor.getContractorByISN(info.data.isn);
        dispatch({
          type: 'selectSubject',
          payload: { data: data.data, juridical: data.data.juridical },
        });
      } else {
        const isn = value.ISN ? value.ISN : value.isn;
        const { data } = await api.contractor.getContractorByISN(isn);
        dispatch({
          type: 'selectSubject',
          payload: { data: data.data, juridical: data.data.juridical },
        });
      }
      dispatch({ type: 'nextStep', payload: 3 });
      dispatch({ type: 'search', payload: false });
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      dispatch({ type: 'search', payload: false });
      notification.error({
        message: 'Ошибка',
        description: message,
      });
    }
  };
  const handleDeleteUser = () => {
    dispatch({ type: 'removeSubjectInfo', payload: null });
    mainForm.setFieldsValue({
      ...mainForm.getFieldsValue(),
      [`fullname-${elem.column_name}`]: null,
      [elem.column_name]: null,
    });
  };
  const onChangeOwner = (e, column) => {
    if (e.target.value.length === 12) {
      setTimeout(async () => {
        dispatch({ type: 'loading', payload: true });
        const { data } = await api.contractor.getContractorByIIN({
          iin: e.target.value,
        });
        if (data.data[0].ISN) {
          const info = await api.contractor.getContractorByISN(
            data.data[0].ISN
          );
          if (onChange) {
            onChange(elem.column_name, data.data[0].ISN, 'SUBJECT');
          }
          console.log(data);
          console.log(column);
          console.log(mainForm.getFieldsValue());

          mainForm.setFieldValue(column, data.data[0].ISN);
          dispatch({
            type: 'selectSubject',
            payload: { data: info.data.data, juridical: data.data.juridical },
          });
          mainForm.setFieldValue(`fullname-${column}`, info.data.data.fullname);
        } else {
          const saveKias = await api.contractor.saveContractorKias({
            xml_isn: data.data[0].XMLISN,
            ext_system_key: data.data[0].EXTSYSTEMKEY,
          });
          const info = await api.contractor.getContractorByISN(
            saveKias.data.data[0].isn
          );
          mainForm.setFieldValue(column, data.data[0].ISN);
          dispatch({
            type: 'selectSubject',
            payload: { data: info.data.data, juridical: data.data.juridical },
          });
          mainForm.setFieldValue('fullname', info.data.data.fullname);
        }
        dispatch({ type: 'loading', payload: false });
      }, 300);
    }
  };
  const AdvancedOwnerSearch = () => {
    dispatch({ type: 'nextStep', payload: 1 });
  };
  const handleCancelAddClientModal = () => {
    dispatch({ type: 'hideSubjectModal', payload: 0 });
    dispatch({ type: 'nextStep', payload: { from: null } });
  };
  const changeState = (step, data: any | null, fromInfo) => {
    dispatch({ type: 'nextStep', payload: { step, from: fromInfo } });
    if (data !== null) {
      dispatch({ type: 'saveSubjectList', payload: { data } });
    }
    if (fromInfo == 'related') {
      dispatch({
        type: 'removeSubjectInfo',
        payload: { ...subjectFullInfo, from: 'related', jur: 'Y' },
      });
    }
  };
  const getContractorList = (values) => {
    contractorFunctions.getContractorList({ dispatch, values });
  };
  useEffect(() => {
    if (
      mainForm.getFieldsValue()[elem.column_name] &&
      !mainForm.getFieldsValue()[`fullname-${elem.column_name}`]
    ) {
      (async () => {
        const { data } = await api.contractor.getContractorByISN(
          mainForm.getFieldsValue()[elem.column_name]
        );
        dispatch({
          type: 'selectSubject',
          payload: { data: data.data, juridical: data.data.juridical },
        });
        mainForm.setFieldValue(
          `fullname-${elem.column_name}`,
          data.data.fullname
        );
        // mainForm.setFieldValue(`fullname-${elem.column_name}`, data.data.iin);
      })();
      setTimeout(() => {}, 500);
    }
  }, [mainForm.getFieldsValue()[elem.column_name]]);

  return (
    <Col span={col} lg={lg} xs={xs} className={className}>
      <Form.Item name={elem.column_name} noStyle hidden>
        <CInput />
      </Form.Item>
      <Form.Item
        name={`fullname-${elem.column_name}`}
        style={{
          width: `100%`,
          marginBottom: 0,
        }}
        label={elem.name ? elem.name : elem.label}
      >
        {subjectFullInfo ? (
          <Input.Group compact>
            <Input
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                width: 'calc(100% - 92px)',
              }}
              value={subjectFullInfo.fullname}
              disabled={
                checkDisabledField(stateDateQuotation?.status_isn) || disabled
              }
              // onChange={handleSearchUser}
              // readOnly
            />
            <>
              <Button
                type="primary"
                icon={<EditOutlined />}
                style={{ width: '46px' }}
                onClick={() => selectContractor(subjectFullInfo, null)}
                htmlType="button"
                disabled={
                  checkDisabledField(stateDateQuotation?.status_isn) || disabled
                }
              />
              <Button
                type="primary"
                icon={<DeleteFilled />}
                style={{ width: '46px' }}
                onClick={handleDeleteUser}
                danger
                htmlType="button"
                disabled={
                  checkDisabledField(stateDateQuotation?.status_isn) || disabled
                }
              />
            </>
          </Input.Group>
        ) : (
          <Search
            loading={isLoading}
            onChange={() => onChangeOwner(event, elem.column_name)}
            placeholder="ИИН"
            onSearch={AdvancedOwnerSearch}
            style={{ width: '100%' }}
            enterButton
            size={size ? size : 'middle'}
            disabled={
              checkDisabledField(stateDateQuotation?.status_isn) || disabled
            }
          />
        )}
      </Form.Item>
      {step == 1 && (
        <SearchClientModal
          isLoading={isLoading}
          from={from}
          contractorFullInfo={subjectFullInfo}
          isVisible={isVisibleSubjectModal}
          handleCancel={() =>
            contractorFunctions.handelCancelSearchContractorModal(
              dispatch,
              from
            )
          }
          getContractorList={getContractorList}
        />
      )}
      {step == 2 && (
        <ResultClientModal
          isLoading={isLoading}
          contractorList={subjectList}
          handleCancel={() =>
            contractorFunctions.handelCancelSearchContractorModal(
              dispatch,
              from
            )
          }
          selectContractor={selectContractor}
          createContractor={() => {}}
          from={from}
        />
      )}
      {step == 3 && (
        <AddClientModal
          isLoading={isLoading}
          isJurUser={isJurUser}
          contractorFullInfo={subjectFullInfo}
          handleCancel={handleCancelAddClientModal}
          changeState={changeState}
          selectContractor={selectContractor}
        />
      )}
    </Col>
  );
};
export default SubjectCard;
