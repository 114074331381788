import { Button, Col, DatePicker, Form, Input, notification, Row, Select } from 'antd';
import TreeSelectHandbook from '@modules/TreeSelect/TreeSelectHandook';
import useHandbook, { HandbookListType } from '@hooks/useHandbook';
import { ColleaguesListType } from '@hooks/useColleagues';
import TreeSelectCollegues from '@modules/TreeSelect/TreeSelectCollegues';
import useAuth from '@hooks/useAuth';
import { SearchOutlined } from '@ant-design/icons';
import api from '@modules/Edo/services/api';
import axios from 'axios';
import { useState } from 'react';
import ShowInvestDocuments from '@modules/Edo/ModalInvestDecisions/ShowInvetsDocuments/ShowInvestDocuments';

interface IPropsSearchInvestDocuments {
	handbookList: HandbookListType;
	colleaguesList: ColleaguesListType;
	departmentList: ColleaguesListType;
}

const { RangePicker } = DatePicker;

const PAGE_NUMBER_DEFAULT = 1;

const SearchInvestDocuments = ({ handbookList, departmentList, colleaguesList }: IPropsSearchInvestDocuments) => {
	const [form] = Form.useForm();

	const [isLoading, setIsLoading] = useState(false);
	const [documents, setDocuments] = useState([]);

	const { handbookStageList: handbookStageList } = useHandbook('cDocStatus');
	const auth = useAuth();

	const handleFinishForm = async (values) => {
		try {
			setIsLoading(true);

			const sendData = {
				class_id: values.class_id,
				document_id: values.document_id,
				status_id: values.status_id,
				stage_id: values.stage_id,
				employee_id: parseInt(values.employee_id),
				department_id: values.department_id,
				client_id: values.client_id,
				document_date_start: values.date_range?.[0],
				document_date_end: values.date_range?.[1],
				agreement_null: true,
			};

			const { data } = await api.searchDocuments(PAGE_NUMBER_DEFAULT, sendData);

			if (!values.once) {
				sessionStorage.setItem('searchData', JSON.stringify(values));
			}

			setDocuments(data.data);
		} catch (error) {
			let message;

			if (axios.isAxiosError(error) && error.response) {
				message = error.message;
			} else {
				message = String(error);
			}

			notification.info({
				message: 'Ошибка',
				description: message,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Form layout="vertical" form={form} onFinish={handleFinishForm}>
				<Row gutter={24}>
					<Col span={8} xs={24} xl={8}>
						<Form.Item label="Тип документа" name="class_id" initialValue={8390}>
							<TreeSelectHandbook
								handbookList={handbookList}
								disabled={true}
								value={8390}
								large={true}
							/>
						</Form.Item>
					</Col>
					<Col span={8} xs={24} xl={8}>
						<Form.Item label="Статусы" name="stage_id" initialValue={14}>
							<Select
								value={14}
								disabled={true}
								options={handbookStageList}
								showSearch
								allowClear
								fieldNames={{
									label: 'name',
									value: 'id',
									options: 'children',
								}}
								size="large"
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col span={8} xs={24} xl={8}>
						<Form.Item label="Подразделения" name="department_id">
							<TreeSelectCollegues
								disabled={isLoading}
								colleaguesList={departmentList}
								selectDepartment={true}
								large={true}
							/>
						</Form.Item>
					</Col>
					<Col span={8} xs={24} xl={8}>
						<Form.Item label="За период" name="date_range">
							<RangePicker
								style={{ width: '100%' }}
								format={'DD.MM.YYYY'}
								size="large"
								disabled={isLoading}
							/>
						</Form.Item>
					</Col>
					<Col span={8} xs={24} xl={8}>
						<Form.Item label="Номер документа" name="document_id">
							<Input size="large" disabled={isLoading}/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col span={16} xs={24} xl={16}>
						<Form.Item
							label="Куратор"
							name="employee_id"
							initialValue={auth?.user?.data?.id}
						>
							<TreeSelectCollegues
								disabled={isLoading}
								colleaguesList={colleaguesList}
								large={true}
							/>
						</Form.Item>
					</Col>
					<Col span={8} xs={24} xl={8}>
						<Form.Item label=" ">
							<Button
								htmlType="submit"
								type="primary"
								icon={<SearchOutlined/>}
								loading={isLoading}
								size="large"
								style={{ width: '100%' }}
							>
								{isLoading ? 'Поиск' : 'Найти'}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
			<ShowInvestDocuments data={documents}/>
		</>
	)
};

export default SearchInvestDocuments;