import React, { useState } from 'react';
import useMembersQuotation from '@modules/Quotation/hooks/useMembersQuotation';
import { Button, Card, Col, Form, Row, Typography, notification } from 'antd';
import CButton from '@components/UI/button/CButton';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import CInput from '@components/UI/input/CInput';
import ListMembers from '../MembersQuotation/ListMembers';
import api from '@modules/Quotation/services';
import { useImmerReducer } from 'use-immer';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import axios from 'axios';
type Action = {
  type: 'search';
  payload?: any;
};

interface State {
  contractorInfo: object | null;
  isLoading: boolean;
}

const initialState: State = {
  contractorInfo: null,
  isLoading: false,
};

const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'search': {
      draft.isLoading = action.payload;
      break;
    }
  }
};

const MainContractRole = ({ roles, userForeignId, agrIsn, contractData }) => {
  const { members, setMembers } = useMembersQuotation();
  const [memberType, setMemberType] = useState(null);
  const [add, setAdd] = useState(false);
  const { Text, Title } = Typography;
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const [memberList, setMemberList] = useState(roles);
  const { isLoading } = state;
  /**
   * По ISN роли возвращает текстовое наименование
   * @param roleIsn - ISN роли
   */
  const roleIsnToText = (roleIsn) => {
    if (members.length) {
      return members.find((role) => role.id === Number(roleIsn))?.label;
    }
    return roleIsn;
  };
  const handleMemberAdd = () => {
    setAdd(true);
  };
  const deleteMembers = () => {
    setAdd(false);
  };
  const sendMembersQuotation = async ({ role, isn, newMember }) => {
    const sendData = {
      agr_isn: agrIsn,
      connection: 'centras',
      role_isn: role,
      subj_isn: isn,
      user_isn: userForeignId,
    };
    const { data } = await api.contractor.saveContractor(sendData);

    if (data.success === true) {
      console.log(data);

      const temp: Array<any> = [...memberList];
      console.log(memberList);

      // const existingElementIndex = temp.findIndex(
      //   (item) => item.foreign_id === newMember.foreign_id
      // );
      temp.push({
        agrisn: data.data.data[0].AGRISN,
        classisn: data.data.data[0].CLASSISN,
        isn: data.data.data[0].ISN,
        subj_full_name: newMember.FULLNAME,
        subj_isn: data.data.data[0].SUBJISN,
        subj_short_name: newMember.FULLNAME,
        user_isn: '0',
      });
      if (temp.length) {
        setMemberList(temp);
        // setMembers(temp);
      }
      notification.success({
        message: 'Сообщение',
        description: data.message,
      });
      return true;
    } else {
      notification.info({
        message: 'Ошибка',
        description: data.error,
      });
      return false;
    }
  };
  const updateMembers = async (newMember) => {
    const data = await sendMembersQuotation({
      role: newMember.foreign_id,
      isn: newMember.ISN,
      newMember,
    });

    if (!data) return;
  };

  const searchContractor = async (iin, memberType) => {
    dispatch({ type: 'search', payload: true });
    try {
      if (iin) {
        const { data } = await api.contractor.getContractorByIIN({
          iin,
        });

        if (data.data.length === 1) {
          if (!data.data[0].ISN) {
            const info = await api.contractor.saveContractorKias({
              xml_isn: data.data[0].XMLISN,
              ext_system_key: data.data[0].EXTSYSTEMKEY,
            });
          } else {
            // empty
          }
        } else {
          // empty
        }

        data.data[0].foreign_id = memberType;
        updateMembers(data.data[0]);
      } else {
        notification.error({
          message: 'Ошибка',
          description: 'Введите ИИН / БИН',
        });
      }
      dispatch({ type: 'search', payload: false });
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      notification.error({
        message: 'Ошибка',
        description: message,
      });
      dispatch({ type: 'search', payload: false });
    }
  };

  const onChange = (event) => {
    // if (event.target.value.length === 12) {
    //   const item = [...members];
    //   item[index].subj_iin = event.target.value;
    //   setAgrMembers(item);
    // }

    if (event.target.value.length === 12 && memberType) {
      setTimeout(() => {
        searchContractor(event.target.value, memberType);
      }, 500);
    }
  };
  const memberRoleChange = (e) => {
    setMemberType(e);
  };
  console.log(contractData.data.agreement[0]);

  return (
    <Card className="mt-5">
      <Title className="main-contract__section-title" level={3}>
        Участники
      </Title>
      {memberList.map((role, index) => (
        <Card
          style={{ backgroundColor: '#f8f8f8' }}
          className="quotation-members mt-5"
          size="small"
          key={index}
        >
          <Row className="align-center justify-space-between" gutter={18}>
            <Col span={6} style={{ alignItems: 'center' }}>
              <Text style={{ fontSize: '18px', fontWeight: '700' }}>
                {roleIsnToText(role.classisn)}
              </Text>
            </Col>
            <Col span={6} style={{ textAlign: 'right', fontSize: '18px' }}>
              {role.subj_full_name}
            </Col>
          </Row>
        </Card>
      ))}
      {add && (
        <CustomLoader spinning={isLoading}>
          <Row gutter={13} className="mt-3 mb-3">
            <Col span={6} style={{ alignItems: 'center' }}>
              <ListMembers
                className="w-100"
                // member={members}
                placeholder="Тип участника"
                members={members}
                onChange={memberRoleChange}
              />
            </Col>
            <Col span={12}>
              <Form.Item className="mb-0">
                <CInput
                  className="digitsMask"
                  placeholder="Поиск по ИИН/БИН"
                  disabled={false}
                  size="large"
                  theme="light"
                  onChange={onChange}
                ></CInput>
              </Form.Item>
            </Col>
            <Col span={5}>
              <CButton type="primary" className="c-button_left" block>
                <SearchOutlined />
                Расширенный поиск
              </CButton>
            </Col>
            <Col span={1}>
              <Button
                type="primary"
                className="quotation-members__button"
                danger
                onClick={() => deleteMembers()}
              >
                <DeleteOutlined />
              </Button>
            </Col>
          </Row>
        </CustomLoader>
      )}
      {!add && (
        <div
          className="mt-6"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <CButton
            type="text"
            size="large"
            style={{
              color: '#7449ef',
              fontWeight: 600,
            }}
            onClick={handleMemberAdd}
            disabled={contractData.data.agreement[0].status !== 'С'}
          >
            + Добавить участника
          </CButton>
        </div>
      )}
    </Card>
  );
};

export default MainContractRole;
