import CInput from '@components/UI/input/CInput';
import CSelect from '@components/UI/select/CSelect';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Input,
  notification,
  Checkbox,
  InputNumber,
} from 'antd';
import api from '@modules/Quotation/services';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import useQuotation from '../../hooks/useQuotation';
import { DeleteFilled, EditOutlined } from '@ant-design/icons';
import { useImmerReducer } from 'use-immer';
import SearchClientModal from '@modules/common/Client/SearchClientModal';
import contractorFunctions from '@modules/common/Client/Functions';
import ResultClientModal from '@modules/common/Client/ResultClientModal';
import axios from 'axios';
import AddClientModal from '@modules/common/Client/AddClientModal';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
const { Search } = Input;

const { Option } = Select;
type Action = {
  type:
    | 'nextStep'
    | 'backStep'
    | 'showSearchContractorModal'
    | 'hideContractorModal'
    | 'getContractorInfo'
    | 'searchContractor'
    | 'saveContractorList'
    | 'selectContractor'
    | 'search'
    | 'loading'
    | 'removeContractInfo';
  payload?: any;
};

interface contractorFullInfo {
  isn: number;
  fullname: string;
}
interface State {
  step: number;
  isVisibleConstactorModal: boolean;
  contractorInfo: any[] | null;
  contractorList: [] | null;
  contractorFullInfo: contractorFullInfo | null;
  isLoading: boolean;
  isJurUser: boolean;
  from: any;
}
const initialState: State = {
  step: 0,
  isVisibleConstactorModal: false,
  contractorInfo: null,
  contractorList: null,
  contractorFullInfo: null,
  isLoading: false,
  isJurUser: true,
  from: null,
};
const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'showSearchContractorModal': {
      draft.step = 1;
      draft.isVisibleConstactorModal = true;
      break;
    }
    case 'loading': {
      draft.isLoading = action.payload;
      break;
    }
    case 'searchContractor': {
      draft.step = 2;
      break;
    }
    case 'saveContractorList': {
      draft.isJurUser = action.payload.isJurUser == 'Y';
      draft.contractorList = action.payload.data;
      break;
    }
    case 'hideContractorModal': {
      draft.step = 0;
      break;
    }
    case 'selectContractor': {
      draft.isJurUser = action.payload.juridical == 'Y';
      draft.contractorFullInfo = action.payload.data;
      if (action.payload.data) {
        draft.contractorInfo = [
          {
            FULLNAME: action.payload.data.fullname,
            IIN: action.payload.data.iin,
            BIRTHDAY: action.payload.data.birthday,
            ISN: action.payload.data.isn,
          },
        ];
      }
      break;
    }
    case 'getContractorInfo': {
      draft.contractorInfo = action.payload;
      break;
    }
    case 'removeContractInfo': {
      draft.contractorInfo = null;
      draft.contractorFullInfo = action.payload;
      if (action.payload?.jur) {
        draft.isJurUser = action.payload.jur == 'Y';
      }
      break;
    }
    case 'nextStep': {
      if (action.payload.step) {
        draft.step = action.payload.step;
      } else {
        draft.step = action.payload;
      }
      if (action.payload.from) {
        draft.from = action.payload.from;
      }
      break;
    }
    case 'backStep': {
      draft.step = draft.step - 1;
      break;
    }
    case 'search': {
      draft.isLoading = action.payload;
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const CardCarMainDynamic = ({
  selectedCar,
  dicti,
  agrIsn,
  saveObject,
  carForm,
  AdvancedContractorSearch,
  modalSetting,
}) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const {
    isVisibleConstactorModal,
    step,
    contractorList,
    isLoading,
    contractorFullInfo,
    isJurUser,
    from,
  } = state;
  const { productSelectedQuotation, stateDateQuotation, agrCond } =
    useQuotation();
  const [typeTs, setTypeTs] = useState<any>();
  const [markaDicti, setMarkaDict] = useState<any>();
  const [modelDicti, setModelDicti] = useState<any>();
  useEffect(() => {
    (async () => {
      if (selectedCar) {
        if (selectedCar.sub_class_isn) {
          const { data } = await api.auto.getFirstDictiByIsn(
            selectedCar.sub_class_isn
          );
          setMarkaDict(data.data);
        }
        if (selectedCar.vehicle_mark_isn) {
          const { data } = await api.auto.getFirstDictiByIsn(
            selectedCar.vehicle_mark_isn
          );
          setModelDicti(data.data);
        }
        carForm.setFieldsValue({
          ISN: selectedCar.isn,
          obj_isn: selectedCar.isn,
          fid_obj: selectedCar.fid,
          sub_class_isn: +selectedCar.vehicle_type_isn
            ? +selectedCar.vehicle_type_isn
            : null,
          auto_markisn: selectedCar.vehicle_mark_isn,
          auto_mark: selectedCar.vehicle_mark,
          auto_modelisn: selectedCar.vehicle_model_isn,
          BRAND: Number(selectedCar.vehicle_mark_isn),
          MODELISN: selectedCar.vehicle_model_isn
            ? Number(selectedCar.vehicle_model_isn)
            : selectedCar.vehicle_model,
          CATEGORYISN: +selectedCar.vehicle_category_isn
            ? +selectedCar.vehicle_category_isn
            : null,
          REGNO: selectedCar.reg_no,
          VIN: selectedCar.vin,
          ENGINEID: selectedCar.engine_id,
          auto_year: selectedCar.vehicle_year?.split('.')[2],
          VOLUME: selectedCar.engine_volume,
          POWER: selectedCar.engine_power,
          PROBEG: selectedCar.vehicle_mileage,
          COLORISN: +selectedCar.vehicle_color_isn
            ? +selectedCar.vehicle_color_isn
            : null,
          CHASSISID: selectedCar.chassis_number,
          BODYID: selectedCar.body_number,
          DOCNO: selectedCar.doc_number,
          DOCSER: selectedCar.doc_series,
          DOCDATE: selectedCar.doc_date
            ? dayjs(selectedCar.doc_date, 'DD.MM.YYYY')
            : null,
          NUMSEATS: selectedCar.auto_numseats,
          TONNAGE: selectedCar.auto_tonnage,
          MAXMASS: selectedCar.auto_maxmass,
          NUMKEY: selectedCar.auto_numkey,
          RELEASEDATE: selectedCar.vehicle_year
            ? dayjs(selectedCar.vehicle_year, 'DD.MM.YYYY')
            : null,
          MODIFICATION: selectedCar.auto_modification,
          CARUSEISN: +selectedCar.auto_caruseisn
            ? +selectedCar.auto_caruseisn
            : null,
          OWNERSHIPISN: +selectedCar.auto_ownershipisn
            ? +selectedCar.auto_ownershipisn
            : null,
          REALPRICE: selectedCar.auto_realprice,
          MULTIDRIVE: selectedCar.auto_multidrive === '1',
          auto_ptsclassisn: +selectedCar.auto_ptsclassisn
            ? +selectedCar.auto_ptsclassisn
            : null,
          RIGHTWHEEL: selectedCar.auto_rightwheel,
          COUNTRYISN: selectedCar.country_isn ? +selectedCar.country_isn : null,
          FOREIGN: selectedCar.auto_foreign === '1',
          TERRITORYISN: selectedCar.auto_territoryguid,
          DATEBEG: selectedCar.auto_datebeg
            ? dayjs(selectedCar.auto_datebeg, 'DD.MM.YYYY')
            : null,
          PASSENGER: selectedCar.auto_passenger,
          USETRAILER: selectedCar.auto_usetrailer === '1',
          USESPECIALSIGNAL: selectedCar.auto_usespecialsignal === '1',
          // TONNAGE: selectedCar.,
        });
      }
      if (selectedCar.owner_isn?.length > 0) {
        const { data } = await api.contractor.getContractorByISN(
          selectedCar.owner_isn
        );
        carForm.setFieldValue('OWNERISN', selectedCar.owner_isn);
        dispatch({
          type: 'selectContractor',
          payload: { data: data.data, juridical: data.data.juridical },
        });
        dispatch({ type: 'loading', payload: false });
      }
    })();
  }, [selectedCar]);
  const onChangeOwner = (e) => {
    if (e.target.value.length === 12) {
      setTimeout(async () => {
        dispatch({ type: 'loading', payload: true });
        const { data } = await api.contractor.getContractorByIIN({
          iin: e.target.value,
        });
        if (data.data[0].ISN) {
          const info = await api.contractor.getContractorByISN(
            data.data[0].ISN
          );
          carForm.setFieldValue('OWNERISN', data.data[0].ISN);
          dispatch({
            type: 'selectContractor',
            payload: { data: info.data.data, juridical: data.data.juridical },
          });
          carForm.setFieldValue('OWNER', info.data.data.fullname);
        } else {
          const saveKias = await api.contractor.saveContractorKias({
            xml_isn: data.data[0].XMLISN,
            ext_system_key: data.data[0].EXTSYSTEMKEY,
          });
          const info = await api.contractor.getContractorByISN(
            saveKias.data.data[0].isn
          );
          carForm.setFieldValue('OWNERISN', data.data[0].ISN);
          dispatch({
            type: 'selectContractor',
            payload: { data: info.data.data, juridical: data.data.juridical },
          });
          carForm.setFieldValue('OWNER', info.data.data.fullname);
        }
        dispatch({ type: 'loading', payload: false });
      }, 300);
    }
  };
  const AdvancedOwnerSearch = () => {
    dispatch({ type: 'nextStep', payload: 1 });
  };
  useEffect(() => {
    if (stateDateQuotation) {
      carForm.setFieldsValue({
        date_sign: dayjs(stateDateQuotation.date_sign),
        date_beg: dayjs(stateDateQuotation.date_beg),
        date_end: dayjs(stateDateQuotation.date_end),
      });
    }
  }, [stateDateQuotation]);
  const sendData = () => {
    saveObject(carForm.getFieldsValue());
  };
  const selectContractor = async (value, fromInfo) => {
    try {
      dispatch({ type: 'search', payload: true });
      if (!value.ISN && !value.isn) {
        const info = await api.contractor.saveContractorKias({
          xml_isn: value.XMLISN,
          ext_system_key: value.EXTSYSTEMKEY,
        });
        const { data } = await api.contractor.getContractorByISN(info.data.isn);
        dispatch({
          type: 'selectContractor',
          payload: { data: data.data, juridical: data.data.juridical },
        });
      } else {
        const isn = value.ISN ? value.ISN : value.isn;
        const { data } = await api.contractor.getContractorByISN(isn);
        dispatch({
          type: 'selectContractor',
          payload: { data: data.data, juridical: data.data.juridical },
        });
      }
      dispatch({ type: 'nextStep', payload: 3 });
      dispatch({ type: 'search', payload: false });
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      dispatch({ type: 'search', payload: false });
      notification.error({
        message: 'Ошибка',
        description: message,
      });
    }
  };
  const handleCancelAddClientModal = () => {
    dispatch({ type: 'hideContractorModal', payload: 0 });
    dispatch({ type: 'nextStep', payload: { from: null } });
  };
  const handleDeleteUser = () => {
    dispatch({ type: 'removeContractInfo', payload: null });
    carForm.setFieldsValue({
      ...carForm.getFieldsValue(),
      owner: null,
    });
  };
  useEffect(() => {
    if (contractorFullInfo) {
      carForm.setFieldsValue({
        ...carForm.getFieldsValue(),
        owner_isn: contractorFullInfo.isn,
      });
    }
  }, [contractorFullInfo]);

  const getContractorList = (values) => {
    contractorFunctions.getContractorList({ dispatch, values });
  };
  const changeState = (step, data: any | null, fromInfo) => {
    dispatch({ type: 'nextStep', payload: { step, from: fromInfo } });
    if (data !== null) {
      dispatch({ type: 'saveContractorList', payload: { data } });
    }
    if (fromInfo == 'related') {
      dispatch({
        type: 'removeContractInfo',
        payload: { ...contractorFullInfo, from: 'related', jur: 'Y' },
      });
    }
  };
  useEffect(() => {
    (async () => {
      const { data } = await api.auto.getFirstDictiByIsn(2118);
      setTypeTs(data.data);
    })();
  }, []);
  const selectTypeTS = async (typeIsn) => {
    const { data } = await api.auto.getFirstDictiByIsn(typeIsn);
    setMarkaDict(data.data);
  };
  const onSelectMarka = async (markaIsn) => {
    const { data } = await api.auto.getFirstDictiByIsn(markaIsn);
    setModelDicti(data.data);
  };

  return (
    <Form
      className="c-form"
      layout="vertical"
      name="transportCard"
      form={carForm}
    >
      <Row gutter={6}>
        <Form.Item name="fid_obj" hidden noStyle>
          <CInput />
        </Form.Item>
        <Form.Item name="obj_isn" hidden noStyle>
          <CInput />
        </Form.Item>
        <Form.Item name="auto_ptsclassisn" hidden noStyle>
          <CInput />
        </Form.Item>
        <Form.Item name="class_isn" hidden noStyle>
          <CInput />
        </Form.Item>
        <Col span={6}>
          <Form.Item name="sub_class_isn" label="Тип транспортного средства">
            <CSelect
              showSearch
              placeholder="Тип транспортного средства"
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
              onChange={selectTypeTS}
              // optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={
                typeTs
                  ? typeTs.children?.map((item) => {
                      return {
                        value: item.foreign_id,
                        key: item.foreign_id,
                        label: item.full_name,
                      };
                    })
                  : ''
              }
            ></CSelect>
          </Form.Item>
        </Col>
        {modalSetting?.item?.card?.map((elem, index) => {
          switch (elem.code) {
            case 'NUMBER': {
              if (elem.column_name !== 'ISN') {
                return (
                  <Col span={6}>
                    <Form.Item name={elem.column_name} label={elem.name}>
                      <CInput
                        autocomplete="cc-csc"
                        type={'number'}
                        placeholder={elem.name}
                        disabled={checkDisabledField(
                          stateDateQuotation.status_isn
                        )}
                      />
                    </Form.Item>
                  </Col>
                );
              }
              break;
            }
            case 'TEXT':
            case 'CITY': {
              return (
                <Col span={6}>
                  <Form.Item name={elem.column_name} label={elem.name}>
                    <CInput
                      placeholder={elem.name}
                      disabled={checkDisabledField(
                        stateDateQuotation.status_isn
                      )}
                    />
                  </Form.Item>
                </Col>
              );
            }
            case 'DATE': {
              return (
                <Col span={6}>
                  <Form.Item name={elem.column_name} label={elem.name}>
                    <DatePicker
                      placeholder={elem.name}
                      format="DD.MM.YYYY"
                      style={{ width: '100%' }}
                      disabled={checkDisabledField(
                        stateDateQuotation.status_isn
                      )}
                    />
                  </Form.Item>
                </Col>
              );
            }
            case 'DICTI': {
              if (elem.column_name === 'BRAND') {
                return (
                  <Col span={6}>
                    <Form.Item name={elem.column_name} label={elem.name}>
                      <CSelect
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').includes(input)
                        }
                        placeholder={elem.name}
                        disabled={
                          checkDisabledField(stateDateQuotation.status_isn) ||
                          !markaDicti
                        }
                        onSelect={onSelectMarka}
                        options={
                          markaDicti
                            ? markaDicti.children.map((item, index) => {
                                return {
                                  value: item.foreign_id,
                                  key: item.foreign_id,
                                  label: item.full_name,
                                };
                              })
                            : ''
                        }
                      />
                    </Form.Item>
                  </Col>
                );
              } else if (elem.column_name === 'MODELISN') {
                return (
                  <Col span={6}>
                    <Form.Item name={elem.column_name} label={elem.name}>
                      <CSelect
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? '').includes(input)
                        }
                        placeholder={elem.name}
                        disabled={
                          checkDisabledField(stateDateQuotation.status_isn) ||
                          !modelDicti
                        }
                        options={
                          modelDicti
                            ? modelDicti.children.map((item, index) => {
                                return {
                                  value: item.foreign_id,
                                  key: item.foreign_id,
                                  label: item.full_name,
                                };
                              })
                            : ''
                        }
                      />
                    </Form.Item>
                  </Col>
                );
              } else {
                return (
                  <Col span={6}>
                    <Form.Item name={elem.column_name} label={elem.name}>
                      <CSelect
                        placeholder={elem.name}
                        disabled={checkDisabledField(
                          stateDateQuotation.status_isn
                        )}
                      >
                        {dicti
                          ? dicti[elem.dicti_isn]?.children?.map((item) => (
                              <Option
                                value={item.foreign_id}
                                key={item.foreign_id}
                              >
                                {item.full_name}
                              </Option>
                            ))
                          : ''}
                      </CSelect>
                    </Form.Item>
                  </Col>
                );
              }
            }
            case 'SUBJECT': {
              return (
                <Col span={6}>
                  <Form.Item name="OWNERISN" hidden noStyle>
                    <CInput value={contractorFullInfo?.isn} />
                  </Form.Item>
                  <Form.Item
                    name="OWNER"
                    style={{
                      width: `100%`,
                      marginBottom: 0,
                    }}
                    label={elem.name}
                  >
                    {contractorFullInfo ? (
                      <Input.Group compact>
                        <Input
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            width: 'calc(100% - 92px)',
                          }}
                          value={contractorFullInfo.fullname}
                          disabled={checkDisabledField(
                            stateDateQuotation.status_isn
                          )}
                          // onChange={handleSearchUser}
                          // readOnly
                        />
                        <>
                          <Button
                            type="primary"
                            icon={<EditOutlined />}
                            style={{ width: '46px' }}
                            onClick={() =>
                              selectContractor(contractorFullInfo, null)
                            }
                            htmlType="button"
                            disabled={checkDisabledField(
                              stateDateQuotation.status_isn
                            )}
                          />
                          <Button
                            type="primary"
                            icon={<DeleteFilled />}
                            style={{ width: '46px' }}
                            onClick={handleDeleteUser}
                            danger
                            htmlType="button"
                            disabled={checkDisabledField(
                              stateDateQuotation.status_isn
                            )}
                          />
                        </>
                      </Input.Group>
                    ) : (
                      <Search
                        loading={isLoading}
                        onChange={onChangeOwner}
                        placeholder="ИИН"
                        onSearch={AdvancedOwnerSearch}
                        style={{ width: '100%' }}
                        enterButton
                      />
                    )}
                  </Form.Item>
                </Col>
              );
            }
            case 'CHECKBOX': {
              return (
                <Col span={6}>
                  <Form.Item
                    // label={elem.name}
                    name={elem.column_name}
                    valuePropName="checked"
                  >
                    <Checkbox
                      disabled={checkDisabledField(
                        stateDateQuotation.status_isn
                      )}
                    >
                      {elem.name}
                    </Checkbox>
                  </Form.Item>
                </Col>
              );
            }
          }
        })}
      </Row>
      <Col span={24}>
        <Button type="primary" style={{ width: '100%' }} onClick={sendData}>
          Сохранить
        </Button>
      </Col>
      {step == 1 && (
        <SearchClientModal
          isLoading={isLoading}
          from={from}
          contractorFullInfo={contractorFullInfo}
          isVisible={isVisibleConstactorModal}
          handleCancel={() =>
            contractorFunctions.handelCancelSearchContractorModal(
              dispatch,
              from
            )
          }
          getContractorList={getContractorList}
        />
      )}
      {step == 2 && (
        <ResultClientModal
          isLoading={isLoading}
          contractorList={contractorList}
          handleCancel={() =>
            contractorFunctions.handelCancelSearchContractorModal(
              dispatch,
              from
            )
          }
          selectContractor={selectContractor}
          createContractor={() => {}}
          from={from}
        />
      )}
      {step == 3 && (
        <AddClientModal
          isLoading={isLoading}
          isJurUser={isJurUser}
          contractorFullInfo={contractorFullInfo}
          handleCancel={handleCancelAddClientModal}
          changeState={changeState}
        />
      )}
    </Form>
  );
};
export default CardCarMainDynamic;
