import React from 'react';
import './ObjectQuotation.scss';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Card, Col, Row, Typography, notification } from 'antd';
import ObjectQuotationProps from './type';
import { useImmerReducer } from 'use-immer';
import CInput from '@components/UI/input/CInput';
import CButton from '@components/UI/button/CButton';
import ObjectQuotationItem from '@modules/Quotation/ObjectQuotation/ObjectQuotationItem';
import SearchCarModal from './ObjectCar/SearchCarModal';
import ResultCarModal from './ObjectCar/ResultCarModal';
import CardCarModal from './ObjectCar/CardCarModal';
import api from '@modules/Quotation/services';
import axios from 'axios';
import dayjs from 'dayjs';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
import useQuotation from '@modules/Quotation/hooks/useQuotation';

const { Title } = Typography;

type Action = {
  type:
    | 'nextStep'
    | 'backStep'
    | 'saveAutoList'
    | 'loading'
    | 'selectCar'
    | 'closeModal';
  payload?: any;
};

interface State {
  isOpenTransport: boolean;
  selectedCar: any;
  autoList: any[];
  step: number;
  isLoading: boolean;
}
const initialState: State = {
  isOpenTransport: false,
  selectedCar: null,
  autoList: [],
  step: 0,
  isLoading: false,
};
const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'saveAutoList': {
      draft.autoList = action.payload;
      break;
    }
    case 'closeModal': {
      if (action.payload?.type === 'car') {
        draft.isOpenTransport = false;
        draft.step = 0;
      }
      break;
    }
    case 'selectCar': {
      draft.selectedCar = action.payload;
      break;
    }
    case 'loading': {
      draft.isLoading = action.payload;
      break;
    }
    case 'nextStep': {
      draft.step += 1;
      if (action.payload?.type === 'car') {
        draft.isOpenTransport = true;
      }
      if (action.payload?.step) {
        draft.step = action.payload.step;
      }
      break;
    }
    case 'backStep': {
      if (action.payload?.type === 'car') {
        draft.isOpenTransport = false;
        draft.step -= 1;
      }
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const ObjectQuotation = ({
  agrIsn,
  userForeignId,
  productIsn,
  objData,
  objAttributesList,
  productSelectedQuotation,
  getFullData,
  agrCond,
  objAttrData,
  AdvancedContractorSearch,
  isUpdate,
}: ObjectQuotationProps) => {
  const [transportForm] = Form.useForm();
  const [attrObjForm] = Form.useForm();
  const [carForm] = Form.useForm();
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const { isOpenTransport, step, autoList, isLoading, selectedCar } = state;
  const { stateDateQuotation } = useQuotation();

  const saveObject = async (data) => {
    dispatch({ type: 'loading', payload: true });
    try {
      const carInfo = await api.saveQuotation.carSave({
        agr_isn: `${agrIsn}`,
        fid_obj: data.fid_obj,
        obj_isn: data.isn,
        auto_typeisn: +data.auto_typeisn,
        auto_markisn: data.auto_markisn,
        auto_mark: data.auto_mark,
        auto_modelisn: data.auto_modelisn,
        auto_model: data.auto_model,
        auto_categoryisn: +data.auto_categoryisn,
        auto_regno: data.auto_regno,
        auto_vin: data.auto_vin,
        auto_engineid: data.auto_engineid,
        auto_countryisn: data.auto_countryisn,
        auto_year: data.auto_year,
        auto_volume: data.auto_volume,
        auto_power: data.auto_power,
        auto_probeg: data.auto_probeg,
        auto_colorisn: +data.auto_colorisn,
        auto_chassisid: data.auto_chassisid,
        auto_bodyid: data.auto_bodyid,
        auto_ptsno: data.auto_ptsno,
        auto_ptsser: data.auto_ptsser,
        auto_ownerisn: data.owner_isn,
        num_seats: data.num_seats,
        auto_ptsdate: dayjs(data.auto_ptsdate).format('YYYY-MM-DD'),
        auto_modification: data.auto_modification,
        auto_caruseisn: data.auto_caruseisn,
        auto_ownershipisn: data.auto_ownershipisn,
        auto_realprice: data.auto_realprice,
        auto_ptsclassisn: data.auto_ptsclassisn,
        right_wheel: data.right_hand_drive_bool,
        updated_by: userForeignId,
      });
      const info = await api.saveQuotation.saveObjCond({
        agr_cond: [
          {
            agr_isn: `${agrIsn}`,
            obj_isn: data.obj_isn,
            isn: data.cond_isn,
            fid: data.fid_cond ? (data.fid_cond == 0 ? 1 : data.fid_cond) : 1,
            cond_name: '',
            risk_isn: data.risk_isn,
            risk_name: productSelectedQuotation[0].risks?.filter(
              (item) => item.risk_isn === data.risk_isn
            )[0]?.risk_name,
            ins_class_isn: 438941,
            addendum_sum: 0,
            date_sign: dayjs(data.date_sign).format('YYYY-MM-DD') + ' 00:00:00',
            date_beg: dayjs(data.date_beg).format('YYYY-MM-DD') + ' 00:00:00',
            date_end: dayjs(data.date_end).format('YYYY-MM-DD') + ' 00:00:00',
            // date_denounce: '',
            duration: null,
            curr_isn: 9813,
            curr_sum_isn: 9813,
            limit_sum: data.limit_sum,
            // limit_sum_ext: '',
            // limit_sum_type: 'А',
            // discount: '0',
            tariff: data.tariff,
            tariff_str: `${data.tariff}`,
            premium_sum: data.premium_sum,
            // premium_sum_tariff: null,
            franch_type: data.franch_type,
            franch_tariff: data.franch_tariff,
            franch_sum: data.franch_sum,
            // comission_proc: '',
            // comission_sum: '',
            fid_obj: data.fid_obj,
            id_obj: 0,
            // parent_isn: 0,
            // payment_koeff: '1',
            // reason_cancel_isn: '',
            // prev_isn: '',
            user_isn: userForeignId,
            remark: data.remark,
          },
        ],
      });

      info.data.data?.filter((item) => {
        if (Number(item.OBJISN) === data.obj_isn) {
          carForm.setFieldsValue({
            ...carForm.getFieldsValue(),
            premium_sum: item.PREMIUMSUM,
            tariff: item.TARIFF,
            franch_tariff: item.FRANCHTARIFF,
            franch_sum: item.FRANCHSUM,
            franch_type: item.FRANCHTYPE,
            modification: item.MODIFICATION,
          });
        }
      });
      const all = await api.saveQuotation.getCarObjByIsn(data.obj_isn);
      dispatch({
        type: 'selectCar',
        payload: all.data.data[0],
      });
      dispatch({ type: 'loading', payload: false });
      notification.success({
        message: 'Успешно',
        description: 'Данные успешно сохранены',
      });
    } catch (error) {
      console.log(error);

      dispatch({ type: 'loading', payload: false });
      let message;

      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    }
  };
  /*
    установка поле defaultKiasValues
    нужно чтобы не отправлять через пропсы данные, которые приходят при заходе в котировку
    а их просто можно вытащить на любом уровне вложенности
    */

  // useEffect(() => {
  //   if (agrIsn === undefined && userForeignId === undefined) return;
  //   objectForm.setFieldsValue({
  //     defaultKiasValues: `agrIsn/${agrIsn}/userForeignId/${userForeignId}`,
  //   });
  // }, [agrIsn, userForeignId]);

  const objectQuotationItems = {
    realty: [
      {
        type: 'Имущество',
        title: 'Жилой дом',
        description: 'Алматы, Абая 151, кв. 236',
      },
    ],
  };
  const openObjectModal = (props) => {
    dispatch({
      type: 'selectCar',
      payload: props.car,
    });
    dispatch({ type: 'nextStep', payload: { step: 3, type: 'car' } });
  };
  const openTransportModal = () => {
    dispatch({ type: 'nextStep', payload: { type: 'car' } });
  };
  const closeTransportModal = () => {
    transportForm.resetFields();
    attrObjForm.resetFields();
    carForm.resetFields();
    getFullData();
    dispatch({ type: 'closeModal', payload: { type: 'car' } });
  };
  const searchTransportList = async (info) => {
    try {
      dispatch({ type: 'loading', payload: true });
      const { data } = await api.auto.searchCar({
        ...transportForm.getFieldsValue(),
        user_isn: userForeignId,
      });
      dispatch({ type: 'saveAutoList', payload: data.data });
      dispatch({ type: 'loading', payload: false });
      dispatch({ type: 'nextStep' });
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }

      dispatch({ type: 'loading', payload: false });
      notification.error({
        message: 'Ошибка',
        description: message,
      });
    }
  };
  const selectCar = async (data) => {
    dispatch({ type: 'loading', payload: true });
    if (!data.MARK) {
      const carInfo = await api.saveQuotation.getCarObjByIsn(data.isn);
      dispatch({
        type: 'selectCar',
        payload: carInfo.data.data[0],
      });
    } else {
      const carInfo = await api.auto.searchCar({
        reg_no: data.REG_NUM,
        user_isn: userForeignId,
      });
      let max = 0;
      objData.map((item) => {
        if (item.fid > max) {
          max = item.fid;
        }
      });

      const info = await api.saveQuotation.objSave({
        agr_obj: [
          {
            agr_isn: agrIsn,
            class_isn: 2118,
            sub_class_isn: carInfo.data.data.TYPE_ID_KIAS,
            obj_name: `${data.MARK} ${data.MODEL} <${data.REG_NUM}>`,
            obj_count: 1,
            ext_system_key: data.ID,
            user_isn: userForeignId,
            fid: max + 1,
          },
        ],
      });

      await api.saveQuotation.carSave({
        agr_isn: `${agrIsn}`,
        fid_obj: +info.data.data.FID,
        auto_modelisn: data.MODEL_ID_KIAS,
        auto_mark_isn: data.MARK_ID_KIAS,
        auto_year: data.NYEAR,
        auto_ptsser: data.REG_CERT_NUM[0] + data.REG_CERT_NUM[1],
        auto_ptsno: data.REG_CERT_NUM.split(data.REG_CERT_NUM[1])[1],
        auto_ptsdate: data.DT_REG_CERT,
        doc_ser: data.REG_CERT_NUM[0] + data.REG_CERT_NUM[1],
        doc_no: data.REG_CERT_NUM.split(data.REG_CERT_NUM[1])[1],
        doc_date: data.DT_REG_CERT,
        auto_vin: data.VIN,
        auto_regno: data.REG_NUM,
        auto_bodyid: data.BODYNUM,
        auto_volume: data.ENGINE_VOLUME,
        num_seats: data.NPLACES,
        auto_colorisn: data.COLORISN,
        auto_power: data.ENGINE_POWER,
        auto_categoryisn: 10830,
        right_wheel: data.RIGHT_HAND_DRIVE_BOOL,
        ext_system_key: data.ID,
        updated_by: userForeignId,
        auto_ptsclassisn: 225346,
      });
      const all = await api.saveQuotation.getCarObjByIsn(info.data.data.ISN);
      dispatch({
        type: 'selectCar',
        payload: all.data.data[0],
      });
    }
    dispatch({ type: 'nextStep', payload: { step: 3, type: 'car' } });
    dispatch({ type: 'loading', payload: false });
  };
  const deleteObj = async (data) => {
    dispatch({ type: 'loading', payload: true });
    await api.saveQuotation.deleteObj({
      agr_isn: `${data.agr_isn}`,
      fid: data.fid,
      user_isn: userForeignId,
    });
    getFullData();
    setTimeout(() => {
      dispatch({ type: 'loading', payload: false });
    }, 500);
  };
  return (
    <Card className="quotation-objects mt-5">
      <Row>
        <Col sm={24} md={8}>
          <Title className="main-quotation__section-title" level={3}>
            Объекты
          </Title>
        </Col>

        <Col sm={24} md={16}>
          <Row gutter={12}>
            <Col sm={24} md={12}>
              <CInput
                width={'100%'}
                placeholder="Поиск по ключевым словам"
                prefix={<SearchOutlined />}
                size="large"
              />
            </Col>
            <Col sm={24} md={12}>
              <CButton className="c-button_left w-100" type="primary">
                <SearchOutlined />
                Поиск по атрибутам
              </CButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <span
        style={{
          fontSize: 24,
          display: 'block',
          margin: '15px 0',
        }}
      >
        Транспортные средства
      </span>
      <CustomLoader spinning={isLoading || isUpdate}>
        <Row gutter={18} wrap={true}>
          {objData &&
            objData[2118]?.map((car, index) => {
              return (
                <Col span={8} key={index} className="mb-3">
                  <ObjectQuotationItem
                    props={{
                      car,
                      selectCar,
                      deleteObj,
                      agrCond,
                      openObjectModal,
                    }}
                  />
                </Col>
              );
            })}
          <Col span={8}>
            <Card
              className="quotation-object quotation-object_add-new"
              onClick={
                checkDisabledField(stateDateQuotation.status_isn)
                  ? () => {}
                  : openTransportModal
              }
            >
              + Добавить транспортное средство
            </Card>
          </Col>
        </Row>
        {productIsn !== 471541 && (
          <>
            <span
              style={{
                fontSize: 24,
                display: 'block',
                margin: '15px 0',
              }}
            >
              Недвижимость
            </span>
            <Row gutter={18} wrap={true}>
              {objectQuotationItems.realty.map((realty, index) => {
                return (
                  <Col span={8} key={index} className="mb-3">
                    <ObjectQuotationItem props={realty} />
                  </Col>
                );
              })}
              <Col span={8}>
                <Card className="quotation-object quotation-object_add-new">
                  + Добавить объект недвижимости
                </Card>
              </Col>
            </Row>
          </>
        )}
      </CustomLoader>

      <SearchCarModal
        form={transportForm}
        closeTransportModal={closeTransportModal}
        onSearch={searchTransportList}
        isLoading={isLoading}
        open={step === 1 && isOpenTransport}
      />
      {step === 2 && isOpenTransport && (
        <ResultCarModal
          isLoading={isLoading}
          autoList={autoList ? autoList : []}
          selectCar={selectCar}
          closeTransportModal={closeTransportModal}
        />
      )}
      {step === 3 && isOpenTransport && (
        <CardCarModal
          objAttributesList={objAttributesList}
          agrIsn={agrIsn}
          closeTransportModal={closeTransportModal}
          selectedCar={selectedCar}
          isLoading={isLoading}
          userForeignId={userForeignId}
          saveObject={saveObject}
          objAttrData={objAttrData}
          productIsn={productIsn}
          attrObjForm={attrObjForm}
          carForm={carForm}
          AdvancedContractorSearch={AdvancedContractorSearch}
        />
      )}
    </Card>
  );
};

export default ObjectQuotation;
