import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Select, Typography } from 'antd';
import CSelect from '@components/UI/select/CSelect';
const MainContractRole = ({ clauses, quotationData }) => {
  const [clausesData, setClausesData] = useState<any[]>([]);
  const { Option } = Select;
  const { Title } = Typography;
  useEffect(() => {
    if (quotationData) {
      setClausesData(quotationData?.data?.[0]?.clauses);
    }
  }, [quotationData]);
  console.log(clauses);
  console.log(
    clausesData
      ?.find((clause1) => Number(clause1.clause_isn) === Number('11110'))
      ?.clause_value.find((value) => {
        console.log(value);
        console.log(Number(value.foreign_id) === Number('198490'));

        return (
          Number(value.clause_value_isn) === Number('198490') ||
          Number(value.foreign_id) === Number('198490')
        );
      })?.clause_value
  );

  return (
    <Form className="c-form" layout="vertical">
      <Card className="mt-5">
        <Title className="main-contract__section-title" level={3}>
          Ограничения/Оговорки
        </Title>
        <Row gutter={18} className="mt-5" style={{ marginBottom: -20 }}>
          {clauses.map((clause, index) => {
            const cols = 24 / clauses.length;
            return (
              <Col xs={24} md={cols} key={index}>
                <Form.Item
                  name={clause.clause_isn}
                  initialValue={clause.class_isn}
                  label={
                    clausesData?.find(
                      (clause1) =>
                        clause1.clause_isn === Number(clause.clause_isn)
                    )?.clause_name
                  }
                >
                  <CSelect size="large" disabled={true}>
                    <Option value={clause.class_isn}>
                      {clausesData
                        ?.find(
                          (clause1) =>
                            Number(clause1.clause_isn) ===
                            Number(clause.clause_isn)
                        )
                        ?.clause_value.find(
                          (value) =>
                            Number(value.clause_value_isn) ===
                              Number(clause.class_isn) ||
                            Number(value.foreign_id) ===
                              Number(clause.class_isn)
                        )?.clause_value
                        ? clausesData
                            ?.find(
                              (clause1) =>
                                Number(clause1.clause_isn) ===
                                Number(clause.clause_isn)
                            )
                            ?.clause_value.find(
                              (value) =>
                                Number(value.clause_value_isn) ===
                                  Number(clause.class_isn) ||
                                Number(value.foreign_id) ===
                                  Number(clause.class_isn)
                            )?.clause_value
                        : clausesData
                            ?.find(
                              (clause1) =>
                                Number(clause1.clause_isn) ===
                                Number(clause.clause_isn)
                            )
                            ?.clause_value.find(
                              (value) =>
                                Number(value.clause_value_isn) ===
                                  Number(clause.class_isn) ||
                                Number(value.foreign_id) ===
                                  Number(clause.class_isn)
                            )?.full_name}
                    </Option>
                  </CSelect>
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      </Card>
    </Form>
  );
};

export default MainContractRole;
