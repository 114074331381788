import React, { useEffect } from 'react';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { IUserGroup, IUser, CoordinationType } from '@modules/Agreement/type';
import { Button, Form, Input, Space, Col, Row } from 'antd';
import TreeSelectCollegues from '@modules/TreeSelect/TreeSelectCollegues';
import { ColleaguesListType } from '../../hooks/useColleagues';
import { log } from 'console';

interface ApprovementFormProps {
  item: IUser[];
  colleaguesList?: ColleaguesListType;
  documentCoordinationForm?: any;
  changeApprovers: () => void;
  type: string;
  keyVal: any;
  name: string;
  readonly?: boolean;
  classConstant: string | undefined;
}

function ApprovementForm({
  item,
  colleaguesList,
  documentCoordinationForm,
  changeApprovers,
  type,
  keyVal,
  name,
  readonly,
  classConstant
}: ApprovementFormProps) {

  console.log(item);
  
  const sendData = (item) => {
    const sendData = item.map((val, index) => {
      return {
        value: `${val.employee.client_id}`,
        name: `${val.full_name}`,
        type: type,
        queue: val.queue,
      };
    });
    documentCoordinationForm.setFieldValue(keyVal, sendData);
  };
  
  useEffect(() => {
    if (item) {
      sendData(item);
    }
  }, [item]);

  const onDelete = (remove, key) => {
    changeApprovers();
    setTimeout(() => {
      remove(key);
    },50);
  };

  const addItem = (add) => {
    changeApprovers();  
    setTimeout(() => {
      add();
      let allData = documentCoordinationForm.getFieldsValue()[keyVal];
      allData.splice(allData.length - 1, 1);
      documentCoordinationForm.setFieldValue(
        [keyVal],
        [
          ...allData,
          {
            name: name,
            type: type,
          },
        ]
      );
    },50);
  };

  /**
   * для проверки
   */
  const categroy = {
    signatory: 'signatory',
    destination: 'destination',
  }

  /**
   * Костыль
   */
  const Minus = (index, remove, name) => {
    if(!categroy[keyVal] && index !== 0){
      return (
        <MinusCircleOutlined
          className="dynamic-delete-button"
          onClick={() => onDelete(remove, name)}
        />
      )
    } else if(categroy[keyVal] === 'signatory' && index !== 0) {
      return (
        <MinusCircleOutlined
          className="dynamic-delete-button"
          onClick={() => onDelete(remove, name)}
        />
      )
    } else if(!categroy[keyVal] && index === 0) {
      return (
        <MinusCircleOutlined
          className="dynamic-delete-button"
          onClick={() => onDelete(remove, name)}
        />
      )
    }
  }

  return (
    <Form.List
      name={keyVal}
      rules={[
        {
          validator: async (_, names) => {
            if (!names || names.length < 2) {
              return Promise.reject(new Error('Добавьте согласующего'));
            }
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => {
        return (
          <>
            {fields.map(({ key, name }, index) => {
              return (
                <Row
                  justify="space-between"
                  align={'middle'}
                  key={`appForm-${name}`}
                >
                  <Form.Item
                    name={[name, 'value']}
                    style={{ width: '90%', marginBottom: '10px' }}
                  >
                    {colleaguesList ? (
                      <TreeSelectCollegues colleaguesList={colleaguesList}  disabled={classConstant === 'ProtReport'}/>
                    ) : (
                      <Input disabled />
                    )}
                  </Form.Item>

                  <Form.Item
                    name={[name, 'name']}
                    style={{ width: '90%', marginBottom: '10px' }}
                    hidden
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    name={[name, 'type']}
                    style={{ width: '90%', marginBottom: '10px' }}
                    hidden
                  >
                    <Input disabled />
                  </Form.Item>


                  {classConstant !== 'ProtReport' &&  Minus(index, remove, name) }

                  {/* {fields.length > 1 && (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => onDelete(remove, name)}
                    />
                  )} */}

                  {fields.length < 1 && <Form.ErrorList errors={errors} />}
                </Row>
              );
            })}
            {
              classConstant === 'ProtReport' || keyVal !== 'destination' && (
                <PlusCircleOutlined
                  style={{ marginTop: '10px' }}
                  onClick={() => addItem(add)}
                />
              ) 
            }
          </>
        );
      }}
    </Form.List>
  );
}

export default ApprovementForm;
