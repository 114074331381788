import { useEffect, useState } from 'react';
import { Row, Col, Collapse, Steps, Layout, notification } from 'antd';
import axios from 'axios';

import api from '../../services/api';
import useAuth from '../../hooks/useAuth';
import Centcoins from '../Centcoins/Centcoins';
import UserInfo from '@modules/UserInfo/UserInfo';
import UserDetails from '@modules/common/UserDetails';
import UserDetailsCareer from '@modules/common/UserDetails/UserDetailsCareer';
import UserDetailsDefault from '@modules/common/UserDetails/UserDetailsDefault';
import UserDetailsVacation from '@modules/common/UserDetails/UserDetailsVacation';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import SalesmanDetails from '@modules/common/SalesmanDetails/SalesmanDetails';
import { IDataSeller } from '@modules/common/SalesmanDetails/IDataSeller';

const { Content } = Layout;

interface AboutMeProps {
  title: string;
}

function AboutMe({ title = 'Mycent.kz' }: AboutMeProps) {
  const auth = useAuth();
  const [showCentCoins, setShowCentCoins] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSeller, setDataSeller] = useState<IDataSeller | null>(null);
  const [raitingSeller, setRaitingSeller] = useState<any>(null);
  
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    (async function getProfile() {
      try {
        setIsLoading(true);
        const response = await api.auth.getProfile();
        auth.setUser(response.data);
      } catch (error) {
        let message;

        if (axios.isAxiosError(error)) {
          message = error.message;
        } else {
          message = String(error);
        }

        notification.info({
          message: 'Ошибка',
          description: message,
        });
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // id user ЛК
  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const seller_id: number = auth.user.data.id;

  const getDataSeller = async () => {
    try {
      setIsLoading(true);
      const response = await api.auth.getSellerInfo(seller_id);
      console.log(response);
      setDataSeller(response.data);
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getDataRaitingSeller = async () => {
    try {
      setIsLoading(true);
      const response = await api.auth.getSellerRaitingInfo({ user_id: seller_id });
      setRaitingSeller(response.data);
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (auth?.user?.data?.user_info?.is_seller) {
      getDataSeller();
      getDataRaitingSeller();
    }
  }, [auth]);

  return (
    <>
      <CustomLoader spinning={isLoading}>
        <Content>
          <div className="aboutme">
            <UserInfo
              listRaiting={raitingSeller}
              userInfo={auth.user?.data?.user_info}
              userDetails={auth.user?.data?.user_detail}
              permission={auth.user?.data}
            />

            <section className="user-profile-data">
              <div className="options-about">
                <div className="user-options">
                  <a
                    href="/#"
                    className="user-option-about user-dossier"
                    onClick={(event) => {
                      event.preventDefault();
                      setShowCentCoins(false);
                    }}
                  >
                    Досье
                  </a>
                </div>
                {/*<div className="user-options">*/}
                {/*  <text className="user-option-about"> Мои результаты </text>*/}
                {/*</div>*/}
                <div className="user-options">
                  <a
                    href="/#"
                    className="user-option-about"
                    onClick={(event) => {
                      event.preventDefault();
                      setShowCentCoins(true);
                    }}
                  >
                    Сенткоины
                  </a>
                </div>
              </div>
              <hr className="options-divider"></hr>
            </section>
            <Row>
              {showCentCoins ? (
                <Col span={24} className="mt-5">
                  <Centcoins
                    id={auth.user?.data?.id}
                    centcoin={auth?.user?.data?.centcoin}
                  />
                </Col>
              ) : (
                <>
                  {auth.user?.data?.user_info?.is_seller && (
                    <Col span={24} className="mt-5">
                      <UserDetails title="Личный кабинет продавца">
                        <SalesmanDetails dataSeller={dataSeller} listRaiting={raitingSeller}/>
                      </UserDetails>
                    </Col>
                  )}
                  <Col span={24} className="mt-5">
                    <UserDetails title="Кадровое перемещение">
                      <UserDetailsCareer
                        detailList={auth.user?.data?.user_detail?.carier}
                      />
                    </UserDetails>
                  </Col>
                  <Col span={24} className="mt-5">
                    <UserDetails title="Административные дни">
                      <UserDetailsDefault
                        detailList={auth.user?.data?.user_detail?.admin}
                        titleRest={`Не использованные адм. дни: ${
                          auth.user?.data?.user_detail?.admin_balance ?? ''
                        }`}
                      />
                    </UserDetails>
                  </Col>
                  <Col span={24} className="mt-5">
                    <UserDetails title="Отпуск">
                      <UserDetailsVacation
                        detailList={auth.user?.data?.user_detail?.vacation}
                      />
                    </UserDetails>
                  </Col>
                  <Col span={24} className="mt-5">
                    <UserDetails title="Больничные дни">
                      <UserDetailsDefault
                        detailList={auth.user?.data?.user_detail?.sick}
                      />
                    </UserDetails>
                  </Col>
                  <Col span={24} className="mt-5">
                    <UserDetails title="Командировка">
                      <UserDetailsDefault
                        detailList={auth.user?.data?.user_detail?.mission}
                      />
                    </UserDetails>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Content>
      </CustomLoader>
    </>
  );
}

export default AboutMe;
