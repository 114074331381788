import { InboxOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  TimePicker,
  TreeSelect,
  Typography,
  Upload,
  Layout,
  Avatar,
} from 'antd';
import dayjs from 'dayjs';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { Outlet } from 'react-router-dom';
import useEvent from '../../hooks/useEvent';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;
const { TreeNode } = TreeSelect;
const { Dragger } = Upload;
const { TextArea } = Input;

interface IDefaultState {
  company_id: string;
  room_id: string;
  title: string;
  date: null;
  timeBegin: any;
  timeEnd: any;
  description: string;
}

type EventFieldsProps = {
  state: IDefaultState;
  setState: Dispatch<SetStateAction<IDefaultState>>;
  handleEventChange: (e) => void;
  userSelectedDate: (e, date) => void;
  userSelectedTimeBegin: (e) => void;
  disabledTime: () => {};
  userSelectedTimeEnd: (e) => void;
  visibleModal: boolean;
  handleOkModal: () => void;
  beforeUploadFile: (file, newFilesList) => boolean;
  fileList: any[];
  handleRemoveFile: (file) => void;
  handleShowModal: () => void;
  handleCancelModal: () => void;
  setSelected: React.Dispatch<React.SetStateAction<[] | undefined>>;
  selected: undefined | [];
  handleCancelForm: () => void;
};

function EventFields(props: EventFieldsProps) {
  const {
    state,
    setState,
    handleEventChange,
    userSelectedDate,
    userSelectedTimeBegin,
    disabledTime,
    userSelectedTimeEnd,
    visibleModal,
    handleOkModal,
    beforeUploadFile,
    fileList,
    handleRemoveFile,
    handleShowModal,
    handleCancelModal,
    setSelected,
    selected,
    handleCancelForm,
  } = props;

  const [docExtensions] = useState<any>([
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.rar',
    'application/zip',
  ]);

  const {
    companiesList,
    isLoadingCompanyList,
    roomsList,
    isLoading,
    fetchedColleagues,
  } = useEvent();

  console.log(companiesList);
  console.log(roomsList);

  const getTreeSelect = useCallback(
    (company: any) => {
      return (
        <>
          {company.users.length
            ? company.users.map((user: any) => (
                <TreeNode
                  value={
                    user?.id +
                    '-' +
                    user?.full_name +
                    '-' +
                    user?.avatar?.link +
                    '-' +
                    user?.client_id
                  }
                  key={
                    user?.id +
                    '-' +
                    user?.full_name +
                    '-' +
                    user?.avatar?.link +
                    '-' +
                    user?.client_id
                  }
                  title={user?.full_name}
                  img={user?.avatar?.link}
                ></TreeNode>
              ))
            : null}
          {company?.child.length
            ? company?.child?.map((child: any) => (
                <TreeNode
                  selectable={false}
                  value={child?.id + '-' + child?.full_name}
                  key={child?.id + '-' + child?.full_name}
                  title={child?.full_name}
                >
                  {child?.child ? getTreeSelect(child) : null}
                </TreeNode>
              ))
            : null}
        </>
      );
    },
    [fetchedColleagues]
  );

  return (
    <>
      <div className="events__row">
        <Title className="mb-5" level={3} type="secondary">
          Создать событие
        </Title>
        <Row gutter={24} justify={{ lg: 'start' }}>
          <Col span={12} xs={24} xl={12}>
            <Form.Item
              name="company_id"
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message:
                    'Поле "Выберите компанию" обязательное для заполнения',
                },
              ]}
            >
              <Select
                placeholder="Выберите компанию"
                dropdownMatchSelectWidth={false}
                style={{ width: '100%' }}
                size="large"
                value={state.company_id}
                onChange={(e) => {
                  setState({
                    ...state,
                    company_id: e,
                  });
                }}
                disabled={isLoadingCompanyList}
              >
                {companiesList.map((company) => {
                  return (
                    <Option value={company.id} key={company.id}>
                      {company.name}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} xs={24} xl={12}>
            <Form.Item
              name="ChoosePlace"
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message:
                    'Поле "Выберите конференц зал" обязательное для заполнения',
                },
              ]}
            >
              <Select
                placeholder="Выберите конференц зал"
                dropdownMatchSelectWidth={false}
                style={{ width: '100%' }}
                size="large"
                value={state.room_id}
                onChange={(e) => {
                  setState({
                    ...state,
                    room_id: e,
                  });
                }}
                notFoundContent={
                  <>
                    {state.company_id === '' ? (
                      <span>Выберите компанию</span>
                    ) : (
                      <span>Отсутствует конференц зал</span>
                    )}
                  </>
                }
              >
                {roomsList
                  .filter(
                    (item) =>
                      Number(item.booking_building_id) === Number(state.company_id)
                  )
                  .map((room) => (
                    <Option value={room.id} key={String(room.id)}>
                      {room.room}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="title"
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message:
                    'Поле "Название встречи" обязательное для заполнения',
                },
              ]}
            >
              <Input
                placeholder="Название встречи"
                size="large"
                name="title"
                value={state.title}
                onChange={handleEventChange}
              />
            </Form.Item>
          </Col>
          <Col span={12} xs={24} xl={12}>
            <Form.Item
              name="ChooseDate"
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: 'Поле "Выберите дату" обязательное для заполнения',
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                size="large"
                disabledDate={(current) =>
                  current.isBefore(dayjs().subtract(1, 'day'))
                }
                onChange={userSelectedDate}
                name="date"
                value={state.date}
              />
            </Form.Item>
          </Col>
          <Col span={6} xs={12} xl={6}>
            <Form.Item
              name="ChooseTimeFrom"
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: 'Поле "С и До" обязательное для заполнения',
                },
              ]}
              initialValue={dayjs(new Date())}
              trigger="onSelect"
            >
              <TimePicker
                placeholder="Время с"
                format="HH:mm"
                minuteStep={5}
                size="large"
                style={{ width: '100%' }}
                name="time"
                value={state.timeBegin}
                onSelect={userSelectedTimeBegin}
                disabledTime={disabledTime}
                hideDisabledOptions
              />
            </Form.Item>
          </Col>
          <Col span={6} xs={12} xl={6}>
            <Form.Item
              name="ChooseTimeTo"
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: 'Поле "С и До" обязательное для заполнения',
                },
              ]}
              initialValue={dayjs(new Date()).add(1, 'hour')}
              trigger="onSelect"
            >
              <TimePicker
                placeholder="Время до"
                format="HH:mm"
                minuteStep={5}
                size="large"
                style={{ width: '100%' }}
                name="time"
                value={state.timeEnd}
                onSelect={userSelectedTimeEnd}
                disabledTime={disabledTime}
                hideDisabledOptions
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="description"
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message:
                    'Поле "Название встречи" обязательное для заполнения',
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="Описание события"
                size="large"
                name="description"
                value={state.description}
                onChange={handleEventChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Dragger
          className="mt-6"
          listType="text"
          name="fils"
          accept={docExtensions}
          multiple
          beforeUpload={beforeUploadFile}
          fileList={fileList}
          onRemove={handleRemoveFile}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Нажмите или перетащите файл в эту область, чтобы загрузить.
          </p>
          <p className="ant-upload-hint">
            Поддержка одиночной или массовой загрузки. Категорически запрещается
            загрузка данных компании или других групповых файлов.
          </p>
        </Dragger>
        <Row
          gutter={24}
          align="bottom"
          justify="space-between"
          className="mt-6"
        >
          <Col>Участники</Col>
          <Col>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="large"
              onClick={handleShowModal}
            >
              Пригласить участников
            </Button>
            <Modal
              title="Добавить участников"
              open={visibleModal}
              onOk={handleOkModal}
              onCancel={handleCancelModal}
              cancelText="Отмена"
              okText="Сохранить"
              width={1000}
            >
              <Content>
                {isLoading && (
                  <div style={{ margin: '20px 0', textAlign: 'center' }}>
                    <CustomLoader spinning={isLoading} />
                  </div>
                )}
                {!isLoading && (
                  <TreeSelect
                    showSearch
                    style={{
                      display: 'block',
                      marginTop: '20px',
                      marginRight: '25px',
                    }}
                    placeholder="Выбрать коллегу"
                    dropdownStyle={{ overflow: 'auto' }}
                    multiple
                    treeDefaultExpandAll
                    allowClear
                    onChange={setSelected}
                  >
                    {fetchedColleagues?.map((company: any) => (
                      <TreeNode
                        title={company?.full_name}
                        value={company.id}
                        key={company.id}
                        treeNodeFilterProp="title"
                      >
                        {getTreeSelect(company)}
                      </TreeNode>
                    ))}
                  </TreeSelect>
                )}
                <Outlet />
              </Content>
            </Modal>
          </Col>
        </Row>
        <div
          style={{ backgroundColor: '#E6EFFF', padding: 20 }}
          className="mt-5"
        >
          {selected?.length && (
            <Row gutter={24}>
              {selected?.map((user: any) => {
                if (user == 1) {
                  return (user = null);
                } else {
                  const [id, name, img] = user.split('-');
                  return (
                    <Col span={12} key={id} xs={24} sm={24} md={12} lg={12}>
                      <Avatar
                        src={`https:mycent.kz/${img}`}
                        alt="фото"
                        size={{
                          xs: 24,
                          sm: 32,
                          md: 40,
                          lg: 64,
                          xl: 64,
                          xxl: 64,
                        }}
                      ></Avatar>
                      <span className="ml-2">{name}</span>
                    </Col>
                  );
                }
              })}
            </Row>
          )}
        </div>
        <Row gutter={16} className="mt-7">
          <Col span={12} xs={24} xl={12}>
            <Button type="primary" size="large" block htmlType="submit">
              Сохранить и создать встречу
            </Button>
          </Col>
          <Col span={12} xs={24} xl={12}>
            <Button size="large" onClick={handleCancelForm} block>
              Отмена
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EventFields;
