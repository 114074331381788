import { Button, Card, Form } from 'antd';
import React from 'react';
import Title from 'antd/es/typography/Title';
import { useOutletContext } from 'react-router-dom';
import { ContextType } from '@pages/Edo';
import DynamicForm from '@modules/Edo/EdoMathcing/DynamicForm/DynamicForm';

const EdoMatching = ({ form }) => {
	const { docEdo, colleaguesList } = useOutletContext<ContextType>();

	const formatedUserList = docEdo?.data?.business_process_sample_document.reduce((acc, user) => {
		const { type, name } = user;
		if (!acc[type]) {
			acc[type] = {
				name: '',
				users: [],
			};
		}
		acc[type].name = name;
		acc[type].users.push(user);
		return acc;
	}, {});

	const formatedUserListClassCoordination = docEdo?.data?.docClassCoordination.reduce((acc, user) => {
		const { group, group_name } = user;
		if (group !== 'chairman' && group !== 'committee') {
			if (!acc[group]) {
				acc[group] = {
					name: '',
					users: [],
				};
			}
			acc[group].name = group_name;
			acc[group].users.push(user);
		}
		return acc;
	}, {});


	const newFormatedList = Object.entries(formatedUserList).filter((user: any) => user['1'].users['0'].show_the_other_doc);
	const newClassCoordinationList = Object.entries(formatedUserListClassCoordination);

	const renderForm = newFormatedList.map((user: any, index) => {
		return (
			<Form form={form} key={index} name="approvalSheet">
				<Title level={5}>{user[1].name}</Title>
				<DynamicForm
					docEdoId={docEdo?.data?.id}
					formName={user[0]}
					item={user[1].users}
					colleaguesList={colleaguesList}
					approverCoordinationForm={form}
					name={user[1].name}
					type={user[0]}
					classId={user[1].users['0'].class_id}
					docClassCoordination={docEdo?.data?.docClassCoordination}
				/>
			</Form>
		)
	});

	const renderFormClassCoordination = newClassCoordinationList.map((user: any, index) => {
		return (
			<Form form={form} key={index} name="approvalSheet">
				<Title level={5}>{user[1].name}</Title>
				<DynamicForm
					docEdoId={docEdo?.data?.id}
					formName={user[0]}
					item={user[1].users}
					colleaguesList={colleaguesList}
					approverCoordinationForm={form}
					name={user[1].name}
					type={user[0]}
					classId={user[1].users['0'].class_id}
					docClassCoordination={docEdo?.data?.docClassCoordination}
				/>
			</Form>
		)
	});

	return (
		<Card className="mt-5">
			<Title level={5} type="secondary">
				Формирование листа согласования для последующих документов
			</Title>
			{docEdo.data.docClassCoordination.length ? renderFormClassCoordination : renderForm}
		</Card>
	)
};

export default EdoMatching;
