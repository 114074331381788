import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
  Table,
} from 'antd';
import { getDocData } from '@modules/Agreement/getDocData';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Title from 'antd/es/typography/Title';
import api from '@modules/Quotation/services/index';
import axios from 'axios';
import SubjectCard from '@modules/Quotation/ObjectQuotation/ObjectCardDynamic/SubjectCard';
import {
  EditOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
} from '@ant-design/icons';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import RenderInputsForEdit from '@modules/Quotation/ContractDocuments/DynamicDocuments/ModalDynamicDocument/RenderInputsForEdit';
import useAuth from '@hooks/useAuth';

interface IPropsModalDynamicDocument {
  isOpenModal: boolean;
  currentDocIsn: string;
  setIsOpenModal: () => void;
  productIsn: number | undefined;
  userForeignId?: number;
}

interface IColumn {
  title: string;
  dataIndex: string;
  key: string;
  render?: (solution: string) => void;
}

interface DataType {
  DOCISN?: string;
  ISN?: string;
  PRIKAZ_KADRY?: string;
  SHEETNO?: string;
  UPDATED?: string | null;
  UPDATEDBYNAME?: string | null;
  VALC2?: string | null;
  VALC2_VALUE?: string | null;
  VALC3?: string | null;
  VALC3_VALUE?: string | null;
  VALC4?: string | null;
  VALC4_VALUE?: string | null;
  VALC5?: string | null;
  VALC5_VALUE?: string | null;
  VALC6?: string | null;
  VALC6_VALUE?: string | null;
  VALC7?: string | null;
  VALC7_VALUE?: string | null;
  VALC14?: string | null;
  VALC14_VALUE?: string | null;
  VALC15?: string | null;
  VALC15_VALUE?: string | null;
  VALC18?: string | null;
  VALC18_VALUE?: string | null;
  VALC22?: string | null;
  VALC22_VALUE?: string | null;
  VALD19?: string | null;
  VALD19_VALUE?: string | null;
  VALD20?: string | null;
  VALD20_VALUE?: string | null;
  VALN1?: string | null;
  VALN1_VALUE?: string | null;
  VALN8?: string | null;
  VALN8_VALUE?: string | null;
  VALN9?: string | null;
  VALN9_VALUE?: string | null;
  VALN10?: string | null;
  VALN10_VALUE?: string | null;
  VALN11?: string | null;
  VALN11_VALUE?: string | null;
  VALN12?: string | null;
  VALN12_VALUE?: string | null;
  VALN13?: string | null;
  VALN13_VALUE?: string | null;
  VALN16?: string | null;
  VALN16_VALUE?: string | null;
  VALN17?: string | null;
  VALN17_VALUE?: string | null;
  VALN21?: string | null;
  VALN21_VALUE?: string | null;
  VALN4?: string | null;
  VALN4_VALUE?: string | null;
}

const { Option } = Select;

const ModalDynamicDocument = ({
  isOpenModal,
  setIsOpenModal,
  currentDocIsn,
  productIsn,
  userForeignId,
}: IPropsModalDynamicDocument) => {
  const auth = useAuth();
  const [docForm] = Form.useForm();
  const [editForm] = Form.useForm();

  const [currentDocData, setCurrentDocData] = useState<any>({});
  const [currentDoc, setCurrentDoc] = useState<any>({});
  const [edit, setEdit] = useState<any>({});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNormalDoc, setIsNormalDoc] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [currentDeleteRowIsn, setCurrentDeleteRowIsn] = useState<
    number | string
  >(0);

  const [tableColumns, setTableColumns] = useState<any>([]);
  const [tableData, setTableData] = useState<DataType[]>([]);

  const getDocInfo = () => {
    setIsLoading(true);
    getDocData(currentDocIsn)
      .then((data) => {
        if (data?.DocSpecs == 'Документ не общей формы') {
          message.error('Документ не общей формы');
          setIsNormalDoc(false);
          setIsOpenModal();
        } else {
          setIsNormalDoc(true);
          setCurrentDocData(data);
          setCurrentDoc(data?.Docs?.docs[0]);
        }
      })
      .catch((e) => {
        message.error(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClick = async (btnIsn) => {
    try {
      await api.quotation.setDocButton({
        docisn: currentDocIsn,
        btn_isn: btnIsn,
      });

      getDocInfo();
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      notification.info({
        message: 'Ошибка',
        description: message,
      });
    }
  };

  const getDocsForRequest = (data) => {
    const keysToExclude = ['updated'];

    return Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => !keysToExclude.includes(key)
      )
    );
  };

  const changeDocs = async () => {
    try {
      const formData = docForm.getFieldsValue();
      console.log(formData);

      const docs = {
        ...currentDoc,
        remark: formData.remark,
        docdate: dayjs(formData.docdate).format('DD.MM.YYYY'),
        id: formData.id,
        subjisn: formData.subjisn,
        subjisn_name: formData['fullname-subjisn'],
        puserisn: userForeignId,
      };
      const newData = getDocsForRequest(docs);

      const response = await api.quotation.setDocInfo({
        docs: [newData],
      });

      if (response.data.data.docs.length) {
        notification.info({
          message: 'Ошибка',
          description: response.data.data.docs,
        });
      }
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      getDocInfo();
    }
  };

  const changeTablePart = async (values) => {
    try {
      const newData = {};
      for (const key in values) {
        if (Object.prototype.hasOwnProperty.call(values, key)) {
          if (key.endsWith('_VALUE') && key !== 'VALN1_VALUE') {
            const newKey = key.replace('_VALUE', '');
            newData[newKey] = values[key];
          } else {
            const newKey = key + '_VALUE';
            if (
              newKey.includes('VALD19_VALUE') ||
              newKey.includes('VALD20_VALUE')
            ) {
              values[key] = dayjs(values[key]).format('DD.MM.YYYY HH:mm:ss');
            } else {
              newData[newKey] = values[key];
            }
          }
        }
      }

      const newDoc = getDocsForRequest(currentDoc);
      await api.quotation.setDocInfo({
        docs: newDoc,
        docrow: [
          {
            DOCISN: currentDocIsn,
            ...edit,
            ...values,
            ...newData,
          },
        ],
      });
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      setIsAdd(false);
      getDocInfo();
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setCurrentDeleteRowIsn(selectedRowKeys[0]);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled:
        currentDocData && currentDocData?.Docs?.docs[0]?.statusisn !== '2516',
    }),
  };

  const getEditTablePart = (editElem) => {
    setIsAdd(true);
    editForm.resetFields();
    setEdit(editElem);

    for (const key in editElem) {
      const value = editElem[key];

      if (['VALD19', 'VALD19_VALUE', 'VALD20', 'VALD20_VALUE'].includes(key)) {
        if (value) editForm.setFieldValue(key, dayjs(value));
      } else {
        editForm.setFieldValue(key, value);
      }
    }
  };

  useEffect(() => {
    getDocInfo();
  }, [currentDocIsn]);

  useEffect(() => {
    if (isNormalDoc) {
      for (const key in currentDocData?.Docs?.docs[0]) {
        const value = currentDocData?.Docs?.docs[0][key];

        if (
          ['docdate', 'updated', 'datebeg', 'datedenounce', 'dateend'].includes(
            key
          )
        ) {
          let parts = value.split('.');
          let newDateString = `${parts[1]}.${parts[0]}.${parts[2]}`;
          docForm.setFieldValue(key, dayjs(newDateString));
        } else {
          console.log(key);
          console.log(value);

          docForm.setFieldValue(key, value);
        }
      }
    }
  }, [currentDocData, docForm]);

  const deleteRow = async () => {
    try {
      await api.quotation.deleteRow({
        user_isn: auth?.user?.data?.user_info?.foreign_id,
        row_isn: Number(currentDeleteRowIsn),
      });

      getDocInfo();
    } catch (error) {
      let message;
      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }
      notification.info({
        message: 'Ошибка',
        description: message,
      });
    }
  };

  useEffect(() => {
    if (isNormalDoc) {
      const columns: IColumn[] = [];
      const dataTable: DataType[] = [];

      currentDocData?.DocSpecs?.docrow.map((column, index) => {
        if (column.code === 'CHECKBOX') {
          columns.push({
            title: column.fieldname,
            dataIndex: column.rowcolumn.toUpperCase() + '_VALUE',
            key: column.rowcolumn.toUpperCase() + '_VALUE',
            render: (solution: string) => {
              return <Checkbox checked={!!Number(solution)} disabled={true} />;
            },
          });
        } else {
          columns.push({
            title: column.fieldname,
            dataIndex: column.rowcolumn.toUpperCase() + '_VALUE',
            key: column.rowcolumn.toUpperCase() + '_VALUE',
          });
        }
      });

      currentDocData?.DocRow !== undefined &&
        currentDocData?.DocRow.map((docRow) => {
          dataTable.push({ key: docRow.ISN, ...docRow });
        });

      setTableColumns(columns);
      setTableData(dataTable);
    }
  }, [currentDocData]);

  const RenderButtons = ({ fieldsList }) => {
    return fieldsList.map((btn, index) => {
      switch (btn.warning) {
        case '':
          return (
            <Button
              key={index}
              className="ml-5"
              type="primary"
              disabled={btn.active !== 'Y'}
              onClick={() => onClick(btn.button_isn)}
            >
              {btn.caption}
            </Button>
          );
        default:
          return (
            <Popconfirm
              key={index}
              title={btn.warning}
              onConfirm={() => onClick(btn.button_isn)}
              okText="Да"
              cancelText="Нет"
            >
              <Button
                key={index}
                className="ml-5"
                type="primary"
                danger
                disabled={btn.active !== 'Y'}
              >
                {btn.caption}
              </Button>
            </Popconfirm>
          );
      }
    });
  };

  const RenderDoc = ({ fieldsList }) => {
    console.log(fieldsList);

    return fieldsList?.map((field, index) => {
      if (field.showfield === 'N') return null;
      switch (field?.code) {
        case 'NUMBER':
        case 'AGREEMENT':
        case 'DOCS':
          return (
            <Col key={index} lg={8} xs={24}>
              <Form.Item
                label={field.name}
                name={`${field.column_name.toLowerCase()}_name`}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
          );
        case 'TEXT':
          return (
            <Col key={index} lg={8} xs={24}>
              <Form.Item
                label={field.name}
                name={`${field.column_name.toLowerCase()}`}
              >
                <Input
                  disabled={
                    field.column_name !== 'REMARK' &&
                    field.column_name !== 'ID' &&
                    (Number(productIsn) !== 5819621 ||
                      Number(productIsn) !== 449051 ||
                      Number(productIsn) !== 449061 ||
                      Number(productIsn) !== 816791)
                  }
                />
              </Form.Item>
            </Col>
          );
        case 'DATE':
          return (
            <Col key={index} lg={8} xs={24}>
              <Form.Item
                label={field.name}
                name={field.column_name.toLowerCase()}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format="DD.MM.YYYY"
                  disabled={
                    field.column_name !== 'DOCDATE' &&
                    (Number(productIsn) !== 5819621 ||
                      Number(productIsn) !== 449051 ||
                      Number(productIsn) !== 449061 ||
                      Number(productIsn) !== 816791)
                  }
                  className="dateMask"
                />
              </Form.Item>
            </Col>
          );
        case 'DICTI':
          if (field.column_name === 'STATUSISN') {
            return (
              <Col key={index} lg={8} xs={24}>
                <Form.Item
                  label={field.name}
                  name={`${field.column_name.toLowerCase()}_name`}
                >
                  <Select
                    showSearch
                    allowClear
                    onChange={(value, option) => {
                      if (!Array.isArray(option)) {
                        setCurrentDoc((prev) => ({
                          ...prev,
                          statusisn: option.key,
                          statusisn_name: option.value,
                        }));
                      }
                    }}
                    disabled={
                      currentDocData &&
                      currentDocData?.Docs?.docs[0]?.classisn_name ===
                        'Печатная форма по Котировке'
                    }
                  >
                    {field?.status?.map((val) => (
                      <Option value={val.status_name} key={val.status_isn}>
                        {val.full_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            );
          } else {
            return (
              <Col key={index} lg={8} xs={24}>
                <Form.Item
                  label={field.name}
                  name={`${field.column_name.toLowerCase()}_name`}
                >
                  <Select disabled={true} />
                </Form.Item>
              </Col>
            );
          }
        case 'SUBDEPT':
          return (
            <Col key={index} lg={8} xs={24}>
              <Form.Item
                label={field.name}
                name={`${field.column_name.toLowerCase()}_name`}
              >
                <Select />
              </Form.Item>
            </Col>
          );
        case 'SUBJECT':
          return (
            <SubjectCard
              elem={{
                column_name: `${field.column_name.toLowerCase()}`,
                full_name: `${field.column_name.toLowerCase()}_name`,
                label: field.name,
              }}
              disabled={field.name.toLowerCase() !== 'контрагент'}
              // stateDateQuotation={stateDateQuotation}
              mainForm={docForm}
              className="mb-5"
              lg={8}
              xs={24}
            />
          );
        default:
          return null;
      }
    });
  };

  return (
    <Modal
      title={
        isNormalDoc && (
          <Title level={4}>
            {currentDocData &&
              currentDocData?.DocSpecs?.docclass[0]?.docisn_name}
          </Title>
        )
      }
      open={isOpenModal}
      onCancel={setIsOpenModal}
      footer={null}
      width="60%"
      className="modal_dynamic_document"
    >
      <CustomLoader spinning={isLoading}>
        <>
          <Divider orientation="left">
            <Title className="mb-0" type="secondary" level={5}>
              Основная информация по документу
            </Title>
          </Divider>
          <Form layout="vertical" form={docForm}>
            <Row className="d-flex" gutter={16}>
              {isNormalDoc && (
                <RenderDoc
                  fieldsList={currentDocData && currentDocData?.DocSpecs?.docs}
                />
              )}
            </Row>
          </Form>
          {isNormalDoc &&
            currentDocData &&
            currentDocData?.DocSpecs?.docclass[0]?.docisn_name !==
              'Печатная форма по Котировке' && (
              <Row>
                <Col>
                  <Button onClick={changeDocs} type="primary">
                    Изменить документ
                  </Button>
                </Col>
              </Row>
            )}
          {tableColumns.length > 1 && (
            <>
              <Row className="d-flex justify-space-between mt-10 mb-5">
                <Col>
                  <Title className="mb-0" type="secondary" level={5}>
                    Табличная часть
                  </Title>
                </Col>
                <Col>
                  <Button
                    disabled={
                      currentDocData &&
                      currentDocData?.Docs?.docs[0]?.statusisn !== '2516'
                    }
                    type="text"
                    onClick={() => {
                      editForm.resetFields();
                      setIsAdd(true);
                      setEdit({});
                    }}
                  >
                    <PlusCircleTwoTone twoToneColor="#52c41a" />
                  </Button>
                  <Button
                    disabled={
                      currentDocData &&
                      currentDocData?.Docs?.docs[0]?.statusisn !== '2516'
                    }
                    type="text"
                    onClick={deleteRow}
                  >
                    <MinusCircleTwoTone twoToneColor="#eb2f96" />
                  </Button>
                </Col>
              </Row>
              <Table
                rowSelection={{
                  type: 'radio',
                  ...rowSelection,
                }}
                dataSource={tableData}
                columns={[
                  {
                    title: '',
                    key: 'action',
                    render: (_, record) => (
                      <Row>
                        <Col span={11}>
                          <Button
                            type="primary"
                            onClick={() => getEditTablePart(record)}
                            disabled={
                              currentDocData &&
                              currentDocData?.Docs?.docs[0]?.statusisn !==
                                '2516'
                            }
                          >
                            <EditOutlined />
                          </Button>
                        </Col>
                      </Row>
                    ),
                  },
                  ...tableColumns,
                ]}
                scroll={{
                  y: 400,
                  x:
                    currentDocData?.DocSpecs?.docclass[0]?.docisn_name ===
                    'Сведения по объекту'
                      ? 4000
                      : 1200,
                }}
              />
              {isAdd ? (
                <>
                  <Divider orientation="left">
                    <Title className="mb-0" type="secondary" level={5}>
                      Изменение табличной части
                    </Title>
                  </Divider>
                  <Form
                    layout="vertical"
                    form={editForm}
                    onFinish={changeTablePart}
                  >
                    <Row className="d-flex" gutter={16}>
                      {
                        <RenderInputsForEdit
                          fieldsList={currentDocData?.DocSpecs?.docrow}
                          editForm={editForm}
                        />
                      }
                    </Row>
                    <Row gutter={[24, 24]} className="d-flex justify-end">
                      <Col>
                        <Button type="primary" htmlType="submit">
                          Сохранить
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          danger
                          onClick={() => {
                            setIsAdd(false);
                            editForm.resetFields();
                          }}
                        >
                          Отменить
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              ) : null}
            </>
          )}
          <Divider />
          <Row gutter={[24, 24]} className="mt-5">
            {isNormalDoc && (
              <RenderButtons fieldsList={currentDocData?.DocSpecs?.buttons} />
            )}
          </Row>
        </>
      </CustomLoader>
    </Modal>
  );
};

export default ModalDynamicDocument;
