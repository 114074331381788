import { FileTwoTone } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Space,
  Typography,
} from 'antd';
import { useImmerReducer } from 'use-immer';
import { useCallback, useEffect, useState } from 'react';

import { AgreementFull } from '@modules/Agreement/type';
import dayjs from 'dayjs';
import Approvers from '@modules/common/Approvers';
import api from '@modules/Agreement/services/api';
import CancelLS from '@pages/Edo/CancelLS';
import EditLS from '@pages/Edo/EditLS';
import GenerateLS from '@pages/Edo/GenerateLS';
import axios from 'axios';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import VotingList from '@modules/Agreement/VotingList/VotingList';
import useAuth from '@hooks/useAuth';

const { Title } = Typography;
const { TextArea } = Input;

interface Action {
  type: 'data' | 'loading';
  payload?: any;
}

interface State {
  data: AgreementFull | null;
  isLoading: boolean;
}

const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'data': {
      draft.data = action.payload;
      break;
    }
    case 'loading': {
      draft.isLoading = action.payload;
      break;
    }
    default: {
      throw new Error(`Unknown action type: ${action.type}`);
    }
  }
};

const initialState: State = {
  data: null,
  isLoading: false,
};

interface Props {
  documentID: number | undefined;
  status: string | undefined;
  remarkTemplate: boolean;
  isDisabledForm: boolean;
  disabledButton: boolean;
  isDisabledStatus: boolean;
  formApprovers?: any;
  docCompanyId: number;
}

function AgreementItem({
  documentID,
  status,
  remarkTemplate,
  isDisabledForm,
  disabledButton,
  isDisabledStatus,
  formApprovers,
  docCompanyId,
}: Props) {
  const [form] = Form.useForm();
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const { data, isLoading } = state;
  const auth = useAuth();

  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);

  const fetchData = useCallback(
    async (documentID: number) => {
      try {
        dispatch({ type: 'loading', payload: true });
        const { data } = await api.getAgreement(documentID);
        if (data) {
          dispatch({ type: 'data', payload: data.data });
        }
      } catch (error) {
      } finally {
        dispatch({ type: 'loading', payload: false });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!data) return;
    if (remarkTemplate) {
      form.setFieldsValue({
        document_date: dayjs.utc(data.document_date),
        status: data.status.full_name,
        stage: data.stage?.name,
        employee_id: data.employee_id?.full_name,
        remark: data.remark,
      });
    } else {
      form.setFieldsValue({
        document_date: dayjs.utc(data.document_date),
        status: data.status.full_name,
        stage: data.stage?.name,
        employee_id: data.employee_id?.full_name,
        remark: data.remark2,
      });
    }
  }, [data, form]);

  useEffect(() => {
    if (typeof documentID === 'number') {
      fetchData(documentID);
    }
  }, [documentID, fetchData, status]);

  async function handleDocumentRemove() {
    try {
      await api.updateDocumentForm({
        documentId: data?.document_id,
        data: { active: false, stage_id: 6 },
      });

      notification.info({
        message: 'Уведомление',
        description: 'Документ успешно аннулирован',
      });

      window.location.reload();
    } catch (error) {
      let message;

      if (axios.isAxiosError(error)) {
        message = error.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      // dispatch({ type: 'deleteDocument', payload: false });
    }
  }

  /**
   * функция для чтение и определение расширений файлов
   */
  const read_files = (file_path) => {
    // ссылка для чтение документов
    const microsoft_files = `https://docs.google.com/gview?url=https://mycent.kz/storage/${file_path}`;
    // ссылка для чтение картинок
    const images = `https://mycent.kz/storage/${file_path}`;

    // получить расширение файла
    const part = file_path.split('.');
    const extension = part[part.length - 1];

    const extensions = {
      png: 'png',
      jpg: 'jpg',
      webp: 'webp',
    };

    // если это изображение возвращаем обычную ссылку
    if (extensions[extension]) {
      return images;
    }

    // в противном случае читаем с помощью https://docs.google.com/gview?url=
    return microsoft_files;
  };

  console.log(data)
  return (
    <CustomLoader spinning={isLoading}>
      <Card className="mt-5">
        <Title level={5} type="secondary">
          Лист согласования
        </Title>
        <Form
          form={form}
          layout="vertical"
          name="agreement"
          disabled={isDisabledForm}
        >
          <Row gutter={24} className="mb-5">
            <Col span={8} xs={24} xl={8}>
              <Form.Item label="Дата" name="document_date">
                <DatePicker format={'DD.MM.YYYY'} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8} xs={24} xl={8}>
              <Form.Item label="Статус" name="status" hidden noStyle>
                <Input />
              </Form.Item>
              <Form.Item label="Статус" name="stage">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} xs={24} xl={8}>
              <Form.Item label="Куратор" name="employee_id">
                <Input />
              </Form.Item>
            </Col>
            {/* <Col span="24">
              <Form.Item name="remark" label="Примечание">
                <TextArea disabled rows={6} />
              </Form.Item>
            </Col> */}
          </Row>
          {data?.files.length !== 0 && (
            <Row className="mb-5">
              <Col span={8} xs={24} xl={8}>
                <Title level={5} type="secondary" className="mb-5">
                  Вложенные документы
                </Title>
                <Space wrap={true}>
                  {data?.files.map(({ file_path, id, file_name }: any) => (
                    <Link to={`/read-docs?path=${file_path}`} key={id}>
                      <Button
                        target="_blank"
                        key={id}
                        type="text"
                        icon={<FileTwoTone />}
                      >
                        {file_name}
                      </Button>
                    </Link>
                  ))}
                </Space>
              </Col>
            </Row>
          )}
          {data && data.coordination.length > 0 && (
            <>
              <Approvers userList={data.coordination} documentID={documentID} />
            </>
          )}
          {data?.voice && <Row>
            <VotingList dataTable={data?.voice}/>
          </Row>}
        </Form>
        <Form name="listForm">
          <Row gutter={[24, 12]}>
            {data?.stage !== null &&
              data?.stage?.slug &&
              data?.stage?.slug.toLowerCase() !== 'signed_ecp' &&
              (data?.stage?.slug.toLowerCase() === 'in_work' ||
                !isDisabledForm) &&  (data?.stage?.slug.toLowerCase() === 'in_work' || docCompanyId !== 4) && (
                <Col>
                  <GenerateLS disabled={disabledButton} />
                </Col>
              )}

            {data?.stage !== null &&
              data?.stage?.slug &&
              data?.stage?.slug.toLowerCase() === 'in_work' && (
                <Col>
                  <EditLS
                    document_id={data.document_id}
                    disabled={disabledButton}
                  />
                </Col>
              )}
            {data?.stage !== null &&
              data?.stage?.slug &&
              (data?.stage?.slug.toLowerCase() === 'in_work' || !isDisabledForm)
              && (
                <Col>
                  <CancelLS
                    handleDocumentRemove={handleDocumentRemove}
                    disabled={disabledButton}
                  />
                </Col>
              )}

            {data?.stage !== null &&
              data?.stage?.slug &&
              data?.stage?.slug.toLowerCase() === 'denied' && docCompanyId !== 4 &&(
                <Col>
                  <EditLS
                    document_id={data.document_id}
                    disabled={disabledButton}
                  />
                </Col>
              )}

            {data?.stage !== null &&
              data?.stage?.slug &&
              data?.stage?.slug.toLowerCase() === 'to_be_agreed' && docCompanyId !== 4 && (
                <Col>
                  <EditLS
                    document_id={data.document_id}
                    disabled={disabledButton}
                  />
                </Col>
              )}
          </Row>
        </Form>
      </Card>
    </CustomLoader>
  );
}

export default AgreementItem;
