/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Card, Form, Typography, Checkbox, Table } from 'antd';
import MainInvoiceModal from './Invoice/index';
import axios from '../services/invoice';
import './MainContractAccruals.scss';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const MainContractAccruals = ({ agrsums, quotationData }: any) => {
  const { Title } = Typography;
  const [selectedFilters, setSelectedFilters] = useState<string[]>([
    'Premia',
    'Komissia',
  ]);
  const [originalColumns, setOriginalColumns] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [invoiceIsn, setInvoiceIsn] = useState<string>('');
  const [modal, setModal] = useState<boolean>(false);
  const [agrisn, setAgrisn] = useState<any>([]);

  const data = useSelector((state: any) => state.contract);
  const dispatch = useDispatch();

  useEffect(() => {
    const columns = [
      {
        title: 'Тип',
        dataIndex: 'class_name',
        key: 'class_name',
      },
      {
        title: 'Дата',
        dataIndex: 'date_cross',
        key: 'date_cross',
      },
      {
        title: 'Начислено',
        dataIndex: 'amount_plan',
        key: 'amount_plan',
      },
      {
        title: 'Оплачено',
        dataIndex: 'amount_fact',
        key: 'amount_fact',
      },
      {
        title: 'Валюта',
        dataIndex: 'curr',
        key: 'curr',
      },
      {
        title: 'Дата оплаты',
        dataIndex: 'datepay',
        key: 'datepay',
      },
      {
        title: 'Операционный документ',
        dataIndex: 'invoice_id',
        key: 'invoice_id',
      },
      {
        title: 'Объект',
        dataIndex: 'obj_name',
        key: 'obj_name',
      },
      {
        title: 'Счёт',
        dataIndex: 'invoice_isn',
        key: 'invoice_isn',
      },
    ];
    setOriginalColumns(columns);
    setColumns(columns);
  }, []);
  useEffect(() => {
    if (quotationData.data.agreement[0].isn) {
      axios.getAccrual(quotationData.data.agreement[0].isn).then((response) => {
        if (response.status === 200) {
          setAgrisn(response.data.data);
          dispatch({ type: 'UPDATE_CONTRACT', payload: '' });
          // console.log('DATA --> ', response.data.data);
        }
      });
    }
  }, [data]);

  const savedData = (params) => {
    // params
  };

  useEffect(() => {
    if (quotationData.data.agreement[0].isn) {
      axios.getAccrual(quotationData.data.agreement[0].isn).then((response) => {
        if (response.status === 200) {
          setAgrisn(response.data.data);
        }
      });
    }
  }, [quotationData]);

  /**
   * показываем инфо в зависимости какой фильтр выбрал user
   */
  const onChange = (checkedValues) => {
    setSelectedFilters(checkedValues);
    const filteredColumns = originalColumns.filter((column) => {
      if (column.dataIndex === 'filter') {
        return checkedValues.includes(column.value);
      }
      return true;
    });
    setColumns(filteredColumns);
  };

  const setInvoiceData = (isn: any) => {
    if (isn.length) {
      setInvoiceIsn(isn);
      setModal(true);
    }
  };

  const filteredData = agrisn.filter((item) =>
    selectedFilters.includes(item.filter)
  );

  const options = [
    { label: 'Премия', value: 'Premia' },
    { label: 'Комиссия', value: 'Komissia' },
    { label: 'Убытки', value: 'Ubytki' },
    { label: 'Прочие', value: 'Prochie' },
  ];

  useEffect(() => {
    if (!invoiceIsn) {
      setModal(false);
    }
  }, [invoiceIsn]);

  return (
    <Form className="c-form" layout="vertical">
      <Card className="mt-5">
        <Title className="main-contract__section-title" level={3}>
          Начисления/расчеты
        </Title>
        {/* Фильтрация по начислением и расчетом  */}
        <Checkbox.Group
          options={options}
          defaultValue={selectedFilters}
          onChange={onChange}
        />
        <CustomLoader spinning={agrisn.length ? false : true}>
          <Table
            className="mt-5"
            columns={columns}
            dataSource={filteredData}
            pagination={{ position: ['bottomLeft'] }}
            scroll={{ x: 1200, y: 300 }}
            onRow={(record) => {
              return {
                onClick: () => setInvoiceData(record.invoice_isn),
              };
            }}
          />
        </CustomLoader>
      </Card>
      <MainInvoiceModal
        isOpen={modal}
        invoiseIsn={invoiceIsn}
        setInvoiceIsn={setInvoiceIsn}
        onClick={savedData}
      />
    </Form>
  );
};

export default MainContractAccruals;
