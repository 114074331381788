import { useEffect, useState } from 'react';
import { FileTwoTone, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Typography,
  UploadProps,
  Upload,
  message,
} from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import { AgreementFull } from '../type';
import EDS from '../EDS';
import useAuth from '../../../hooks/useAuth';
import AgreementModalFiles from '../AgreementModal/AgreementModalFiles';
import dayjs from 'dayjs';
import AgreementDocAttr from '../components/DocAttr';
import Approvers from '@modules/common/Approvers';
import api from '../services/api';
import Clients from '../EDS/Clients';
import { IDynamicTable } from '@modules/common/DynamicTable/data.type';
import DynamicTable from '@modules/common/DynamicTable/DynamicTable';
import KiasFiles from './KiasFiles';
import DynamicTableMycent from '../components/DynamicTableMycent';
import './AgreementModal.scss';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import VotingList from '@modules/Agreement/VotingList/VotingList';
import CustomEDS from '@modules/Agreement/CustomEDS';

const { TextArea } = Input;
const { Title } = Typography;
const { Dragger } = Upload;

interface AgreementModalProps {
  visible: boolean;
  agreementID: number;
  agreementType: string;
  agreement: AgreementFull | null;
  onAgreementListGet?: (values) => void;
  onAgreementGet: (newAgreement) => void;
  onCancel: () => void;
  agreementDocument: IDynamicTable | null;
  resetDocInfo: () => void;
  docList?: any[];
  disabled?: boolean;
}

const arraySecuritiesDoc: string[] = [
  "AffReport",
  "RecReport",
  "ConcReport",
  "ProtReport"
];

const arrayForInterafceDoc: string[] = [
  "RecReport",
  "ConcReport",
];
const checkSecuritiesDoc = (array, value) => {
  return array.includes(value);
}
function AgreementModal(props: AgreementModalProps) {
  const {
    visible,
    onAgreementListGet = () => {},
    onCancel = () => {},
    onAgreementGet = () => {},
    agreementID,
    agreementType,
    agreement,
    agreementDocument,
    resetDocInfo = () => {},
    docList,
    disabled,
  } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isRemarkRequired, setIsRemarkRequired] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [files, setFiles] = useState([]);

  const token = Cookies.get('token'); // || localStorage?.getItem('token'); // Запасной вариант на случай если в iframe Bitrix не будет работать авторизация
  const fileProps: UploadProps = {
    name: 'file',
    multiple: true,
    headers: {
      Authorization: 'Bearer ' + token,
    },
    action: 'https://mycent.kz/api/coordination/sign/file',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`Файл "${info.file.name}" успешно загружен.`);
      } else if (status === 'error') {
        message.error(
          `При загрузке файла "${info.file.name}" произошла ошибка.`
        );
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const auth = useAuth();

  const handleSolutionSend = (solution: number) => {
    form
      .validateFields(['remark'])
      .then(async (values) => {
        try {
          setIsLoading(true);

          // eslint-disable-next-line @typescript-eslint/no-shadow

          if (agreementType === 'kias') {
            const sendData = {
              userId: auth.user.data.id,
              docIsn: agreementID,
              resolution: '',
              remark: values.remark,
              solution: solution,
            };
            const { data } = await api.sendSolutionKias(sendData);

            if (data.success) {
              onAgreementListGet(data.data);
              onCancel();
            }
          } else {
            const sendData = {
              document_id: agreementID,
              solution: solution,
              remark: values.remark,
              coordination_type: docList?.filter(
                (item) => item.document_id === agreementID
              )[0]?.coordination_type,
            };
            const { data } = await api.sendSolution(sendData);
            if (typeof data == 'string') {
              notification.info({
                message: 'Ошибка',
                description:
                  'С Вашим документов вышла ошибочка! Уже разбираемся!',
              });
            } else {
              if (
                agreement?.files &&
                agreement.files.length > 0 &&
                agreement?.coordination[agreement?.coordination.length - 1]
              ) {
                const userInfo =
                  agreement.coordination[agreement?.coordination.length - 1];

                if (
                  userInfo &&
                  userInfo.employee.client_id == auth.user.data.id
                ) {
                  api.getDocument(agreementID).then(async (res) => {
                    const fileData = await api.fileSave({
                      doc_isn: res.data.data.document_isn,
                      doc_id: agreementID,
                    });
                  });
                }
              }
              notification.info({
                message: 'Уведомление',
                description: 'Данные успешно сохранены',
              });
              onAgreementListGet(data);
              onCancel();
            }
          }
        } catch (error) {
          let message;
          if (axios.isAxiosError(error)) {
            message = error.message;
          } else {
            message = String(error);
          }
          notification.info({
            message: 'Ошибка',
            description: message,
          });
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageSure, setMessageSure] = useState('');

  const handleOk = () => {
    setIsModalOpen(false);
    if (messageSure === 'Вы уверены что хотите согласовать ?') {
      console.log('принято согласование');
      handleSolutionSend(1);
    }
    if (messageSure === 'Вы уверены что хотите отказать ?') {
      console.log('принято на отказ');
      handleSolutionSend(0);
    }
    if (messageSure === 'Вы уверены что хотите воздержаться ?') {
      handleSolutionSend(2);
    }
  };

  const handleCanc = () => {
    setIsModalOpen(false);
  };

  const handleCancel = (): void => {
    form.resetFields();
    onCancel();
  };

  const handleAgreementAccept = (): void => {
    setIsModalOpen(true);
    setMessageSure('Вы уверены что хотите согласовать ?');
    setIsRemarkRequired(false);
  };

  const handleAgreementReject = (): void => {
    setIsModalOpen(true);
    setMessageSure('Вы уверены что хотите отказать ?');
    setIsRemarkRequired(true);
  };

  const handleAgreementAbstain = () => {
    setIsModalOpen(true);
    setMessageSure('Вы уверены что хотите воздержаться ?');
    setIsRemarkRequired(true);
  };

  useEffect(() => {
    if (agreement === null) return;
    if (agreement.coordination.length > 0) {
      agreement.coordination.map((item) => {
        if (item.users && item.users.length > 0) {
          item.users.map((user) => {
            if (user.files.length > 0) {
              setFiles(user.files);
            }
          });
        }
      });
    }
    form.setFieldsValue({
      document_date: dayjs(agreement.document_date),
      date_beg: dayjs(agreement.date_beg),
      date_end: dayjs(agreement.date_end),
      status: agreement.status?.full_name,
      stage: agreement.stage?.name,
      employee_fullname: agreement.employee_id?.full_name,
      employee_department: agreement.employee_id?.department,
      kias_department_main: agreement?.docsform?.[0].MAIN_DEPT,
      mycent_department_main: agreement.employee_id?.department_main,
      remark_agreement: agreement.remark,
      sub_fullname: agreement.docsform?.[0]?.SUBJNAME,
      sub_department: agreement.docsform?.[0]?.SUBJDEPT,
      remark_kias: agreement.docsform?.[0]?.REMARK,
      remark_mycent: agreement.remark ? agreement.remark : '',
      remark2_mycent: agreement.remark2 ? agreement.remark2 : '',
    });

    if (agreement.docsform?.[0]?.STATUSNAME) {
      form.setFieldsValue({
        stage: agreement.docsform?.[0]?.STATUSNAME,
      });
    }

    if(agreementType === 'kias') {
      setIsButtonDisabled(agreement.docsform?.[0].SIGNESP !== 'N');
    }
  }, [agreement, form]);

  useEffect(() => {
    (async function fetchAgreement(documentID: number) {
      try {
        setIsLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { data } = await api.getAgreement(documentID, agreementType);
        onAgreementGet(data.data);
      } catch (error) {
        let message;

        if (axios.isAxiosError(error)) {
          message = error.message;
        } else {
          message = String(error);
        }

        notification.info({
          message: 'Ошибка',
          description: message,
        });
      } finally {
        setIsLoading(false);
      }
    })(agreementID);

    return () => {
      resetDocInfo();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementID]);

  // refusal - отказ
  // coordinate - согласовать
  const ModalButtons = () => {
    return (
      <Row gutter={24} className="modal-btn">
        <Col className="modal-btn__column">
          <Space align="center" size={5}>
            {!agreement?.ecp && <Button
              type="primary"
              onClick={handleAgreementAccept}
              disabled={isButtonDisabled}
            >
              Согласовать
            </Button>}
            {agreement?.docsform?.[0]?.CLASSISN === '883011' && (
              <Button
                type="default"
                onClick={handleAgreementAbstain}
                disabled={isLoading}
              >
                Воздержаться
              </Button>
            )}
            {!docList?.some((item) => item.document_id === agreementID) && (
              <Button
                type="primary"
                danger
                onClick={handleAgreementReject}
                disabled={isLoading}
              >
                Отказать
              </Button>
            )}
          </Space>
        </Col>
      </Row>
    );
  };

  const numberModal =
    agreementType === 'kias' ? agreement?.docsform?.[0].ID : agreement?.id;
  const titleModal =
    agreementType === 'kias'
      ? agreement?.docOrder?.docs[0]
        ? agreement?.docOrder?.docs[0].classisn_name
        : ''
      : agreement?.title;
  return (
    <>
      <Modal
        open={visible}
        title={`${titleModal ? titleModal + '.' : ''} Лист согласования № ${
          numberModal ?? ''
        }`}
        onCancel={handleCancel}
        width="1000px"
        footer={typeof disabled === 'boolean' ? <></> : <ModalButtons />}
      >
        <CustomLoader spinning={isLoading}>
          <Form
            form={form}
            layout="vertical"
            name="agreement"
            validateMessages={{ required: 'Обязательное поле' }}
          >
            <Row gutter={24} className="mb-5">
              <Col span={24} sm={12} md={8}>
                <Form.Item label="Дата" name="document_date">
                  <DatePicker
                    format="DD.MM.YYYY"
                    style={{ width: '100%' }}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={12} md={8}>
                <Form.Item label="Статус" name="status" hidden noStyle>
                  <Input disabled />
                </Form.Item>
                <Form.Item label="Статус" name="stage">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={24} sm={12} md={8}>
                <Form.Item name="employee_fullname" label="Куратор">
                  <Input disabled />
                </Form.Item>
              </Col>
              {agreementType !== 'kias' && (
                <Col span={24} sm={12} md={8}>
                  <Form.Item label="Дата начала" name="date_beg">
                    <DatePicker
                      format="DD.MM.YYYY"
                      disabled
                      className="datepicker"
                    />
                  </Form.Item>
                </Col>
              )}
              {agreementType !== 'kias' && (
                <Col span={24} sm={12} md={8}>
                  <Form.Item label="Дата окончания" name="date_end">
                    <DatePicker
                      format="DD.MM.YYYY"
                      disabled
                      className="datepicker"
                    />
                  </Form.Item>
                </Col>
              )}
              {agreementType !== 'kias' && (
                <Col span={24} sm={12} md={8}>
                  <Form.Item name="employee_department" label="Подразделение">
                    <Input disabled />
                  </Form.Item>
                </Col>
              )}
              {agreementType !== 'kias' && (
                <Col span={24} sm={12} md={8}>
                  <Form.Item name="mycent_department_main" label="Департамент">
                    <Input disabled />
                  </Form.Item>
                </Col>
              )}
              {agreementType === 'kias' && agreement?.docsform?.[0].MAIN_DEPT && (
                <Col span={24} sm={12} md={8}>
                  <Form.Item name="kias_department_main" label="Департамент">
                    <Input disabled />
                  </Form.Item>
                </Col>
              )}
              {agreementType === 'kias' && !!agreement?.docsform?.[0].SUBJDEPT && (
                <Col span={24} sm={12} md={8}>
                  <Form.Item name="sub_fullname" label="Контрагент">
                    <Input disabled />
                  </Form.Item>
                </Col>
              )}
              {agreementType === 'kias' && !!agreement?.docsform?.[0].SUBJNAME && (
                <Col span={24} sm={12} md={8}>
                  <Form.Item name="sub_department" label="Подразделение">
                    <Input disabled />
                  </Form.Item>
                </Col>
              )}
              {agreementType === 'kias' && !!agreement?.docsform?.[0]?.SUMMA && (
                <Col span={24} sm={12} md={8}>
                  <Form.Item label="Сумма">
                    <Input
                      value={`${agreement?.docsform?.[0]?.SUMMA} ${agreement?.docsform?.[0]?.CURRCODE}`}
                      disabled
                    />
                  </Form.Item>
                </Col>
              )}
              {!!agreement?.docAttr?.length && (
                <Col span="24">
                  <AgreementDocAttr docAttrList={agreement.docAttr} />
                </Col>
              )}

              {agreementType === 'kias' && agreementDocument !== null ? (
                <Col span={24} className="mt-2 mb-7">
                  <DynamicTable agreementDocument={agreementDocument} />
                </Col>
              ) : null}

              {agreement &&
                agreement?.doc_row &&
                agreement?.doc_row[0] &&
                agreement?.doc_row[0].length && (
                  <DynamicTableMycent data={agreement.doc_row} />
                )}
            </Row>
            {agreementType === 'kias' &&
              agreement?.docsform?.[0]?.REMARK !== null && (
                <Form.Item label="Примечание" name="remark_kias">
                  <TextArea rows={4} readOnly />
                </Form.Item>
              )}
            {agreement && agreementType !== 'kias' && (
              <Form.Item label="Примечание" name="remark_mycent">
                <TextArea rows={4} readOnly />
              </Form.Item>
            )}
            {agreement && agreementType !== 'kias' && agreement.remark2 && (
              <Form.Item label="Доп примечание" name="remark2_mycent">
                <TextArea rows={4} readOnly />
              </Form.Item>
            )}
            {agreementType === 'kias' &&
              agreementDocument &&
              agreementDocument?.Docs?.docspict?.length > 0 && (
                <KiasFiles agreementDocument={agreementDocument} />
              )}

            {agreement && agreement.files.length > 0 && (
              <AgreementModalFiles
                fileList={agreement.files}
                agreementType={agreementType}
              />
            )}
            <Title level={5} type="secondary">
              Вложенные документы
            </Title>
            <Dragger
              {...fileProps}
              data={
                agreementType === 'kias'
                  ? { document_isn: agreementID, remark: '', file_type: 'D' }
                  : { document_id: agreementID, remark: '', file_type: 'D' }
              }
              disabled={disabled ? disabled : false}
            >
              <p className="ant-upload-drag-icon">
                <UploadOutlined />
              </p>
              <p className="ant-upload-text">
                Нажмите или перетащите файл в эту область, чтобы загрузить.
              </p>
              <p className="ant-upload-hint">
                Поддержка одиночной или массовой загрузки. Категорически
                запрещается загрузка данных компании или других групповых
                файлов.
              </p>
            </Dragger>
            {files && files.length !== 0 && (
              <Row className="mt-5">
                <Col span="24">
                  <Space>
                    {files.map(({ file_path, id, file_name }) => (
                      <Button
                        target="_blank"
                        href={`https://mycent.kz/storage/${file_path}`}
                        key={id}
                        type="text"
                        icon={<FileTwoTone />}
                      >
                        {file_name}
                      </Button>
                    ))}
                  </Space>
                </Col>
              </Row>
            )}

            {agreement && agreement.coordination.length > 0 && (
              <Approvers
                userList={agreement.coordination}
                documentID={agreementID}
              />
            )}
            {agreement?.voice && <Row>
              <VotingList dataTable={agreement?.voice}/>
            </Row>}
            <Form.Item
              label="Дополнительные сведения"
              name="remark"
              rules={[{ required: isRemarkRequired }]}
            >
              <TextArea rows={4} disabled={disabled ? disabled : false} />
            </Form.Item>
            {agreementType === 'kias' &&
              agreement?.docsform?.[0]?.SIGNESP !== 'N' && (
                <>
                  <EDS
                    handleCancel={handleCancel}
                    agreement={agreement}
                    setIsButtonDisabled={setIsButtonDisabled}
                    ecpRedirect={
                      agreementDocument && agreementDocument?.ecpRedirect
                    }
                    ecpRedirectUrl={
                      agreementDocument && agreementDocument?.ecpRedirectUrl
                    }
                  />

                  {agreementType === 'kias' && agreementID ? (
                    <Clients
                      agreementType={agreementType}
                      agreementID={agreementID}
                    />
                  ) : null}
                </>
              )}

            {/*После переноса все логики на signature нужно будет открыть интерфейс подписания*/}
            {agreementType === 'mycent' && agreement?.show_eds === true && !checkSecuritiesDoc(arraySecuritiesDoc, agreement?.constant) && (
              <EDS
                handleCancel={handleCancel}
                agreement={agreement}
                setIsButtonDisabled={setIsButtonDisabled}
                ecpRedirect={
                  agreementDocument && agreementDocument?.ecpRedirect
                }
                ecpRedirectUrl={
                  agreementDocument && agreementDocument?.ecpRedirectUrl
                }
              />
            )}
            {agreementType === 'mycent'
              && agreement?.show_eds === true
              && agreement?.ecp_sign === true
              && (checkSecuritiesDoc(arraySecuritiesDoc, agreement?.constant) && agreement.coordination.length !== 1)
              && (<EDS
                handleCancel={handleCancel}
                agreement={agreement}
                setIsButtonDisabled={setIsButtonDisabled}
                ecpRedirect={agreementDocument && agreementDocument?.ecpRedirect}
                ecpRedirectUrl={agreementDocument && agreementDocument?.ecpRedirectUrl}
              />
            )}
            {agreementType === 'mycent' &&
              agreement?.show_eds === true &&
              agreement?.coordination?.length === 1 &&
              agreement?.signature_uuid &&
              checkSecuritiesDoc(arrayForInterafceDoc, agreement?.constant) &&
              (<CustomEDS signatureUUID={agreement?.signature_uuid}/>)
            }
          </Form>
        </CustomLoader>
      </Modal>
      <Modal
        title="Согласование"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCanc}
      >
        {/* Вы уверены что хотите согласовать ? */}
        <p>{messageSure}</p>
      </Modal>
    </>
  );
}

export default AgreementModal;
