import {
  Row,
  Col,
  Form,
  notification,
  Checkbox,
  Select,
  TreeSelect,
} from 'antd';
import TreeSelectKias from '@modules/TreeSelect/TreeSelectKias';
import CSelect from '@components/UI/select/CSelect';
import CDatePicker from '@components/UI/datepicker/CDatePicker';
import useQuotation from '../hooks/useQuotation';
import { useEffect, useMemo, useState } from 'react';
import CInput from '@components/UI/input/CInput';
import useAuth from '@hooks/useAuth';
import api from '@modules/Quotation/services/saveQuotation';
import dayjs from 'dayjs';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import QuotationTreeSelect from '../MainQuotation/QuotationTreeSelect';
import CTreeSelect from '@components/UI/treeselect/CTreeSelect';
import _ from 'lodash';
import CustomLoader from '@components/UI/CustomLoader/CustomLoader';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';
import SubjectCard from '../ObjectQuotation/ObjectCardDynamic/SubjectCard';
const NewAdditionalAttributesFields = ({
  agrIsn,
  userForeignId,
  productIsn,
  attributesList,
  contractorInfo,
  objectForm,
  isJuridical,
  dicti,
  quotationData,
}) => {
  const {
    productSelectedQuotation,
    setAgrAttributes,
    agrAttributes,
    colleaguesKiasList,
    agrContractor,
    agrCond,
    setAgrCond,
    setIsCustomTemplate,
    stateDateQuotation,
  } = useQuotation();
  const auth = useAuth();

  const { Option } = Select;
  const { TreeNode } = TreeSelect;
  const additionalAttributesForm = Form.useFormInstance();
  const [showProductElem, setShowProductElem] = useState(true);
  const [isDynamicProduct, setIsDynamicProduct] = useState(false);
  const [showVooAdditionalFields, setShowVooAdditionalFields] = useState(false);

  // Возможно и не нужно, отправлять массивом при сохранении

  const mrpField = Form.useWatch('2488591', additionalAttributesForm);
  const peopleCountField = Form.useWatch('828091', additionalAttributesForm);
  const isInside = Form.useWatch('828111', additionalAttributesForm);

  /**
   * Для ВОО если Макс. возможное число потерпевших в результате вредного воздействия опасн.объектов
   * > 1500, то показываем доп поля
   * @param selectedValue
   */
  const vooAdditionalFields = (selectedValue) => {
    if (
      selectedValue === 744201 ||
      selectedValue === 744161 ||
      selectedValue === 744231
    ) {
      setShowVooAdditionalFields(true);
    } else {
      setShowVooAdditionalFields(false);
    }
  };

  /**
   * Для ВОО если Макс. возможное число потерпевших в результате вредного воздействия опасн.объектов
   * > 1500, то показываем доп поля
   */
  useEffect(() => {
    if (peopleCountField) {
      vooAdditionalFields(peopleCountField);
    }
  }, [peopleCountField]);

  // useEffect(() => {
  //   if (mrpField && Number(productIsn) === 474741) {
  //     // onChange('2488591', mrpField, 'TEXT');
  //     setAttributes('2488591', mrpField, 'TEXT');
  //     vooCalcMrp(additionalAttributesForm.getFieldValue('828091'));
  //   }
  // }, [mrpField, productIsn]);
  useEffect(() => {
    if (mrpField) {
      onChange(2488591, mrpField, 'DICTI');
    }
  }, [mrpField]);

  /*
  useEffect(() => {
    if (agrAttributes && agrAttributes.length > 0) {
      agrAttributes.map((item) => {
        if (item.attr_isn) {
          if (item.attr_isn_code == 'DATE') {
            additionalAttributesForm.setFieldValue(
              item.attr_isn,
              dayjs(item.value, 'DD.MM.YYYY')
            );
          } else if (item.attr_isn == 2656251) {
            additionalAttributesForm.setFieldValue(item.attr_isn, item.val_c);
          } else if (item.attr_isn_code === 'DICTI_CODENAME') {
            additionalAttributesForm.setFieldValue(item.attr_isn, item.val_n);
          } else if (item.attr_isn_code === 'DICTI') {
            additionalAttributesForm.setFieldValue(item.attr_isn, item.val_n);
          } else {
            additionalAttributesForm.setFieldValue(item.attr_isn, item.value || item.val_c || item.val_n);
          }
        }
      });
    }
  }, [agrAttributes]);
  */

  useEffect(() => {
    if (!productIsn) return;
    setIsDynamicProduct(
      productIsn !== 471541 &&
        productIsn !== 474651 &&
        productIsn !== 474641 &&
        productIsn !== 474741 &&
        productIsn !== 471651 &&
        productIsn !== 437401
    );
    // Если ОСЭ

    // if (Number(productIsn) === 474641) {
    //   additionalAttributesForm.setFieldValue(
    //     '2488591',
    //     isJuridical ? 65000 : 5000
    //   );

    //   setAttributes('2488591', isJuridical ? 6500 : 5000);
    // }
  }, [isJuridical, productIsn, additionalAttributesForm]);

  const options = {
    // Да/Нет
    yesOrNo: [
      { label: 'Да', value: 482291 },
      { label: 'Нет', value: 482301 },
    ],
    customTemplateValue: [
      { label: 'Да', value: 499571 },
      { label: 'Нет', value: 499581 },
    ],
  };

  /**
   * Возвращает true/false для required значения поля При ответе "ДА" пожалуйста, поясните
   */
  const isExplainRequired = useMemo(() => {
    return (
      isInside ===
      options.yesOrNo.find(
        (option) => option.label === 'Да' && option.value === isInside
      )?.value
    );
  }, [isInside]);

  /**
   * Записываем атрибуты в agrAttributes
   * @param attrIsn
   * @param attrValue
   * @param type
   */
  const setAttributes = (attrIsn, attrValue, type?) => {
    const cloneAgrAttributes = cloneDeep(agrAttributes);
    let foundItem;
    Object.keys(cloneAgrAttributes).map((key) => {
      cloneAgrAttributes[key].find((item) => {
        if (String(item.attr_isn) === String(attrIsn)) {
          foundItem = item;
        }
      });
    });
    if (foundItem) {
      foundItem.val_n = Number(attrValue);
      foundItem.val_c = String(attrValue);
      foundItem.value = attrValue;
      foundItem.attr_isn_code = type;
    } else {
      cloneAgrAttributes.push({
        attr_isn: attrIsn,
        class_isn: 220565,
        obj_isn: agrIsn,
        attr_isn_code: type,
        user_isn: userForeignId,
        val_c: String(attrValue),
        val_n: Number(attrValue),
        value: attrValue,
      });
    }

    setAgrAttributes(cloneAgrAttributes);
  };

  let attributesTimeout;
  const onChange = async (attr_isn, val, type, callback: any = undefined) => {
    clearTimeout(attributesTimeout);
    attributesTimeout = setTimeout(async () => {
      let requestBody = {};
      switch (type) {
        case 'DATETIME':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                // val_d: dayjs(val).format('DD-MM-YYYY HH:mm:ss'),
                // val_d: dayjs(val).format('DD.MM.YYYY HH:mm:ss'),
                val_d: dayjs(val).format('YYYY.MM.DD HH:mm:ss'),
                // val_c: dayjs(val).format('YYYY-MM-DD HH:mm:ss'),
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'DATE':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_d: dayjs(val).format('YYYY.MM.DD'),
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'CHECKBOX':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_c: val.target.checked,
                val_n: Number(val.target.checked),
                value: val.target.checked,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'TEXT':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_c: val.target.value,
                val_n: Number(val.target.value),
                value: val.target.value,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'DICTI':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_c: val,
                val_n: val,
                value: val,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'DICTI_CODENAME':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_c: val,
                val_n: val,
                value: val,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'SUBJECT':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_n: val,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'NUMBER':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_n: val.target.value.length > 0 ? +val.target.value : null,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        case 'DEPTEMPL':
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                val_n: val,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
        default:
          requestBody = {
            agr_attributes: [
              {
                attr_isn: attr_isn,
                class_isn: 220565,
                value: val.target.value,
                obj_isn: String(agrIsn),
                user_isn: userForeignId,
              },
            ],
          };
          break;
      }
      try {
        await api.attributeSave(requestBody);
      } catch (error) {
        let message;
        if (axios.isAxiosError(error)) {
          message = error.message;
        } else {
          message = String(error);
        }
        notification.error({
          message: 'Ошибка',
          description: message,
        });
      }

      // Обновляем объект атрибутов
      const attrValue = val?.target?.value || val;
      setAttributes(attr_isn, attrValue);

      // Передаем выбранное значение во внешнюю функцию
      if (callback) {
        callback(val);
      }
    }, 400);
  };

  // 10чел 744221
  // 10-75 744191
  // 75-150 744211
  // 150-300 744151
  // 300-750 744171
  // 750-1500 744181
  // 1500-2000 744201
  // 2000-4000 744161
  // >4000 744231
  /**
   * Рассчитываем МРП и Страховую сумму в зависимости от кол-ва человек
   * @param selectedValue
   */
  const vooCalcMrp = (selectedValue) => {
    if (!selectedValue) return;
    const mrpList = [
      { 744221: 1000 },
      { 744191: 5000 },
      { 744211: 12000 },
      { 744151: 30000 },
      { 744171: 50000 },
      { 744181: 115000 },
      { 744201: 225000 },
      { 744161: 350000 },
      { 744231: 600000 },
    ];

    const index = mrpList.findIndex(
      (item) => Object.keys(item)[0] === String(selectedValue)
    );
    const mrp = mrpList.find(
      (item) => Object.keys(item)[0] === String(selectedValue)
    );

    if (index !== -1 && mrp) {
      if (productIsn != '471541') {
        // Ставим значение МРП – Страховая сумма
        additionalAttributesForm.setFieldValue('2488591', mrp[selectedValue]);

        // Ставим значение Страховая сумма
        objectForm.setFieldValue(
          'limit_sum',
          productSelectedQuotation[0]?.limit_sum[index].value
        );
        setAgrCond([
          {
            ...agrCond[0],
            limit_sum: productSelectedQuotation[0]?.limit_sum[index].value,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    setIsCustomTemplate(additionalAttributesForm.getFieldValue('499591'));
    if (auth && agrIsn) {
      (async () => {
        const manager = agrAttributes.filter(
          (item) => item.attr_isn == 2386051
        );
        if (manager.length == 0) {
          additionalAttributesForm.setFieldValue(
            '2386051',
            auth.user?.data?.user_info?.foreign_id
          );
          try {
            await api.attributeSave({
              agr_attributes: [
                {
                  attr_isn: 2386051,
                  class_isn: 220565,
                  val_n: auth.user?.data?.user_info?.foreign_id,
                  obj_isn: String(agrIsn),
                  user_isn: userForeignId,
                },
              ],
            });
          } catch (error) {
            let message;
            if (axios.isAxiosError(error)) {
              message = error.message;
            } else {
              message = String(error);
            }
            notification.error({
              message: 'Ошибка',
              description: message,
            });
          }
        }
      })();
    }
  }, [auth, agrIsn]);

  /**
   * Проверяет отображать ли поле или нет
   * @param  {number} attrIsn - ISN атрибута
   * @return {boolean} true/false
   */
  const isFieldExist = (attrIsn) => {
    if (!attrIsn) return;
    return (
      productSelectedQuotation[0]?.attributes[1].filter(
        (item) => item.attr_isn === attrIsn && item.active_mycent
      ).length > 0
    );
  };
  const isFieldRequired = (attrIsn) => {
    if (!attrIsn) return false;
    return (
      productSelectedQuotation[0]?.attributes[1].filter(
        (item) => item.attr_isn === attrIsn && item.required
      ).length > 0
    );
  };

  /**
   * Вытаскивает справочник из атрибутов по attr_isn
   * @param data
   * @param attrIsn
   */
  const getAttrDictisByIsn = (data, attrIsn) => {
    for (const key of Object.keys(data)) {
      const items = data[key];
      const foundItem = items.find((item) => item.attr_isn === attrIsn);

      if (foundItem) {
        return foundItem.dictis;
      }
    }
    return null;
  };

  /**
   * Форматирует категории ВОО из бека в формат для TreeSelect
   * @param categoryItem - элемент списка категорий
   * @param selectable { boolean } - возможность выбора элемента TreeSelect
   */
  const renderTreeNodesCategory = (categoryItem: any, selectable = false) => {
    return (
      <TreeNode
        value={categoryItem.id || categoryItem.isn}
        key={categoryItem.id || categoryItem.isn}
        title={categoryItem.full_name}
        selectable={selectable}
      >
        {_.uniqBy(categoryItem?.children, function (e: any) {
          return e.id || e.isn;
        }).map((subCategoryItem) =>
          renderTreeNodesCategory(subCategoryItem, true)
        )}
      </TreeNode>
    );
  };
  useEffect(() => {
    console.log(dicti);
  }, [dicti]);
  return (
    <CustomLoader spinning={!productSelectedQuotation.length}>
      <Row style={{ minHeight: 100 }} gutter={18}>
        {productSelectedQuotation[0]?.attributes[0]?.map((item) => {
          switch (item.attr_type) {
            case 'DICTI':
            case 'DICTI_SQL':
            case 'SQL':
            case 'DICTI_CODENAME':
              const findItem =
                dicti &&
                dicti[item.attr_isn]?.children?.filter(
                  (item) => item.n_children
                );
              return (
                <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                  <Form.Item
                    label={item.attr_name}
                    name={item.attr_isn}
                    className="bold-label"
                    validateTrigger="onChange"
                    rules={[
                      {
                        required:
                          typeof item.required === 'boolean'
                            ? item.required
                            : false,
                        message: 'Поле обязательно для заполнения',
                      },
                    ]}
                  >
                    {findItem?.length > 0 ? (
                      <QuotationTreeSelect
                        placeholder={item.attr_name}
                        disabled={
                          checkDisabledField(stateDateQuotation.status_isn) ||
                          item.readonly
                        }
                        size="large"
                        onChange={(e) => {
                          onChange(item.attr_isn, e, 'DICTI_CODENAME');
                          setIsCustomTemplate(e);
                        }}
                        list={dicti[item.attr_isn]?.children}
                      />
                    ) : (
                      <CSelect
                        showSearch
                        placeholder={item.attr_name}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        size="large"
                        onChange={(e) => {
                          onChange(item.attr_isn, e, 'DICTI_CODENAME');
                          setIsCustomTemplate(e);
                        }}
                        disabled={
                          checkDisabledField(stateDateQuotation.status_isn) ||
                          item.readonly
                        }
                      >
                        {dicti &&
                        dicti[item.attr_isn] &&
                        dicti[item.attr_isn].children
                          ? dicti[item.attr_isn].children.map((el) => {
                              return (
                                <Option
                                  value={el.foreign_id ? el.foreign_id : el.isn}
                                  key={el.foreign_id ? el.foreign_id : el.isn}
                                >
                                  {el.full_name}
                                </Option>
                              );
                            })
                          : dicti &&
                            dicti[item.attr_isn] &&
                            dicti[item.attr_isn].map((el) => {
                              return (
                                <Option
                                  value={el.foreign_id ? el.foreign_id : el.isn}
                                  key={el.foreign_id ? el.foreign_id : el.isn}
                                >
                                  {el.full_name}
                                </Option>
                              );
                            })}
                      </CSelect>
                    )}
                  </Form.Item>
                </Col>
              );
            case 'TEXT': {
              return (
                <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                  <Form.Item
                    label={item.attr_name}
                    name={item.attr_isn}
                    className="bold-label"
                    rules={[
                      {
                        required:
                          typeof item.required === 'boolean'
                            ? item.required
                            : false,
                        message: 'Поле обязательно для заполнения',
                      },
                    ]}
                  >
                    <CInput
                      placeholder={item.attr_name}
                      size="large"
                      onChange={(e) => onChange(item.attr_isn, e, 'TEXT')}
                      disabled={
                        checkDisabledField(stateDateQuotation.status_isn) ||
                        item.readonly
                      }
                    />
                  </Form.Item>
                </Col>
              );
            }
            case 'DEPTEMPL': {
              return (
                <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                  <Form.Item
                    label={item.attr_name}
                    name={item.attr_isn}
                    className="bold-label"
                    rules={[
                      {
                        required:
                          typeof item.required === 'boolean'
                            ? item.required
                            : false,
                        message: 'Поле обязательно для заполнения',
                      },
                    ]}
                  >
                    <TreeSelectKias
                      colleaguesKiasList={colleaguesKiasList}
                      placeholder={item.attr_name}
                      style={{ width: '100%' }}
                      size="large"
                      onChange={(e) => onChange(item.attr_isn, e, 'DEPTEMPL')}
                      disabled={
                        checkDisabledField(stateDateQuotation.status_isn) ||
                        item.readonly
                      }
                    />
                  </Form.Item>
                </Col>
              );
            }
            case 'DATETIME': {
              return (
                <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                  <Form.Item
                    label={item.attr_name}
                    name={item.attr_isn}
                    className="bold-label"
                    rules={[
                      {
                        required:
                          typeof item.required === 'boolean'
                            ? item.required
                            : false,
                        message: 'Поле обязательно для заполнения',
                      },
                    ]}
                  >
                    <CDatePicker
                      showTime
                      style={{ width: '100%' }}
                      onChange={(e) => onChange(item.attr_isn, e, 'DATETIME')}
                      placeholder={item.attr_name}
                      size="large"
                      disabled={
                        checkDisabledField(stateDateQuotation.status_isn) ||
                        item.readonly
                      }
                    />
                  </Form.Item>
                </Col>
              );
            }
            case 'DATE': {
              return (
                <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                  <Form.Item
                    label={item.attr_name}
                    name={item.attr_isn}
                    className="bold-label"
                    rules={[
                      {
                        required:
                          typeof item.required === 'boolean'
                            ? item.required
                            : false,
                        message: 'Поле обязательно для заполнения',
                      },
                    ]}
                  >
                    <CDatePicker
                      style={{ width: '100%' }}
                      format={'DD.MM.YYYY'}
                      placeholder={item.attr_name}
                      size="large"
                      onChange={(e) => onChange(item.attr_isn, e, 'DATE')}
                      disabled={
                        checkDisabledField(stateDateQuotation.status_isn) ||
                        item.readonly
                      }
                    />
                  </Form.Item>
                </Col>
              );
            }
            case 'DOCS': {
              return (
                <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                  <Form.Item
                    label={item.attr_name}
                    name={item.attr_isn}
                    className="bold-label"
                    rules={[
                      {
                        required:
                          typeof item.required === 'boolean'
                            ? item.required
                            : false,
                        message: 'Поле обязательно для заполнения',
                      },
                    ]}
                  >
                    <CInput
                      placeholder={item.attr_name}
                      size="large"
                      onChange={(e) => onChange(item.attr_isn, e, 'TEXT')}
                      disabled={
                        checkDisabledField(stateDateQuotation.status_isn) ||
                        item.readonly
                      }
                    />
                  </Form.Item>
                </Col>
              );
            }
            case 'CHECKBOX': {
              return (
                <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                  <Form.Item
                    name={item.attr_isn}
                    valuePropName="checked"
                    className="bold-label"
                    rules={[
                      {
                        required:
                          typeof item.required === 'boolean'
                            ? item.required
                            : false,
                        message: 'Поле обязательно для заполнения',
                      },
                    ]}
                  >
                    <Checkbox
                      onChange={(e) => onChange(item.attr_isn, e, 'CHECKBOX')}
                      disabled={
                        checkDisabledField(stateDateQuotation.status_isn) ||
                        item.readonly
                      }
                    >
                      {item.attr_name}
                    </Checkbox>
                  </Form.Item>
                </Col>
              );
            }
            case 'AGREEMENT':
            case 'AGRCALC': {
              return (
                <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                  <Form.Item
                    label={item.attr_name}
                    name={item.attr_isn}
                    className="bold-label"
                    rules={[
                      {
                        required:
                          typeof item.required === 'boolean'
                            ? item.required
                            : false,
                        message: 'Поле обязательно для заполнения',
                      },
                    ]}
                  >
                    <CInput
                      placeholder={item.attr_name}
                      size="large"
                      onChange={(e) => onChange(item.attr_isn, e, 'TEXT')}
                      disabled={
                        checkDisabledField(stateDateQuotation.status_isn) ||
                        item.readonly
                      }
                    />
                  </Form.Item>
                </Col>
              );
            }
            case 'NUMBER':
            case 'INTEGER': {
              return (
                <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                  <Form.Item
                    label={item.attr_name}
                    name={item.attr_isn}
                    className="bold-label"
                    rules={[
                      {
                        required:
                          typeof item.required === 'boolean'
                            ? item.required
                            : false,
                        message: 'Поле обязательно для заполнения',
                      },
                    ]}
                  >
                    <CInput
                      placeholder={item.attr_name}
                      size="large"
                      type={'number'}
                      onChange={(e) => onChange(item.attr_isn, e, 'NUMBER')}
                      disabled={
                        checkDisabledField(stateDateQuotation.status_isn) ||
                        item.readonly ||
                        Number(item.id) === 4336
                      }
                    />
                  </Form.Item>
                </Col>
              );
            }
            case 'SUBJECT': {
              return (
                <SubjectCard
                  key={`fullname-${item.attr_isn}`}
                  elem={{
                    column_name: item.attr_isn,
                    full_name: `fullname-${item.attr_isn}`,
                    label: item.attr_name,
                  }}
                  stateDateQuotation={stateDateQuotation}
                  col={6}
                  mainForm={additionalAttributesForm}
                  size="large"
                  onChange={onChange}
                />
              );
            }
            default: {
              return (
                <Col span={6} key={`${item.attr_isn}-${item.attr_name}`}>
                  <Form.Item
                    label={item.attr_name}
                    name={item.attr_isn}
                    className="bold-label"
                    rules={[
                      {
                        required:
                          typeof item.required === 'boolean'
                            ? item.required
                            : false,
                        message: 'Поле обязательно для заполнения',
                      },
                    ]}
                  >
                    <CInput
                      placeholder={item.attr_name}
                      size="large"
                      onChange={(e) => onChange(item.attr_isn, e, 'NUMBER')}
                      disabled={
                        checkDisabledField(stateDateQuotation.status_isn) ||
                        item.readonly
                      }
                    />
                  </Form.Item>
                </Col>
              );
            }
          }
        })}
        {/* {!isDynamicProduct && isFieldExist(2488601) && (
          <Col span={6}>
            <Form.Item
              label="Категория"
              name="2488601"
              validateTrigger="onChange"
              rules={[
                {
                  required: isFieldRequired(2488601),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CTreeSelect
                placeholder="Категория"
                treeLine={{ showLeafIcon: false }}
                treeNodeFilterProp="title"
                allowClear={true}
                showSearch={true}
                size="large"
                onChange={(e) => onChange('2488601', e, 'DICTI_CODENAME')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                {getAttrDictisByIsn(attributesList, 2488601)?.map((item) =>
                  renderTreeNodesCategory(item)
                )}
              </CTreeSelect>
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(2386051) && (
          <Col span={6}>
            <Form.Item
              label="Менеджер договора"
              name="2386051"
              rules={[
                {
                  required: isFieldRequired(2386051),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <TreeSelectKias
                colleaguesKiasList={colleaguesKiasList}
                placeholder="Менеджер договора"
                style={{ width: '100%' }}
                size="large"
                onChange={(e) => onChange('2386051', e, 'DEPTEMPL')}
                disabled
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(2578291) && (
          <Col span={6}>
            <Form.Item
              label="Дата договора гос.портала"
              name="2578291"
              rules={[
                {
                  required: isFieldRequired(2578291),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CDatePicker
                style={{ width: '100%' }}
                format={'DD.MM.YYYY'}
                placeholder="Дата договора гос.портала"
                size="large"
                onChange={(e) => onChange('2578291', e, 'DATE')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )} */}
        {/* {!isDynamicProduct && isFieldExist(499591) && (
          <Col span={6}>
            <Form.Item
              label="Стандартный шаблон договора страхования"
              name="499591"
              validateTrigger="onChange"
              rules={[
                {
                  required: isFieldRequired(499591),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CSelect
                showSearch
                placeholder="Стандартный шаблон договора страхования"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="large"
                onChange={(e) => {
                  onChange('499591', e, 'DICTI_CODENAME');
                  setIsCustomTemplate(e);
                }}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                {getAttrDictisByIsn(attributesList, 499591)?.map((el) => (
                  <Option value={el.isn} key={el.isn}>
                    {el.full_name}
                  </Option>
                ))}
              </CSelect>
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(514581) && (
          <Col span={6}>
            <Form.Item
              label={'%"АВ" к согласованию (гросс)'}
              name="514581"
              rules={[
                {
                  required: isFieldRequired(514581),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CInput
                placeholder={'%"АВ" к согласованию (гросс)'}
                size="large"
                type={'number'}
                onChange={(e) => onChange('514581', e, 'NUMBER')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(784851) && (
          <Col span={6}>
            <Form.Item
              label="Рейтинг"
              name="784851"
              rules={[
                {
                  required: isFieldRequired(784851),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CInput
                placeholder="Рейтинг"
                size="large"
                type={'number'}
                onChange={(e) => onChange('784851', e, 'NUMBER')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(2578281) && (
          <Col span={6}>
            <Form.Item
              label="№ договора гос. портала"
              name="2578281"
              rules={[
                {
                  required: isFieldRequired(2578281),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CInput
                placeholder="№ договора гос. портала"
                size="large"
                onChange={(e) => onChange('2578281', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(2580491) && (
          <Col span={6}>
            <Form.Item
              label="ИИК договора гос. портала"
              name="2580491"
              rules={[
                {
                  required: isFieldRequired(2580491),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CInput
                placeholder="ИИК договора гос. портала"
                size="large"
                onChange={(e) => onChange('2580491', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(513541) && (
          <Col span={6}>
            <Form.Item
              label="Порядок и форма страхового возмещения"
              name="513541"
              rules={[
                {
                  required: isFieldRequired(513541),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CSelect
                showSearch
                placeholder="Порядок и форма страхового возмещения"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="large"
                onChange={(e) => onChange('513541', e, 'DICTI')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                {getAttrDictisByIsn(attributesList, 513541)?.map((el) => (
                  <Option value={el.isn} key={el.isn}>
                    {el.full_name}
                  </Option>
                ))}
              </CSelect>
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(513491) && (
          <Col span={6}>
            <Form.Item
              label="Вызов ДП"
              name="513491"
              rules={[
                {
                  required: isFieldRequired(513491),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CSelect
                showSearch
                placeholder="Вызов ДП"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="large"
                onChange={(e) => onChange('513491', e, 'DICTI')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                {getAttrDictisByIsn(attributesList, 513491)?.map((el) => (
                  <Option value={el.isn} key={el.isn}>
                    {el.full_name}
                  </Option>
                ))}
              </CSelect>
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(513581) && (
          <Col span={6}>
            <Form.Item
              label="Территория страхования"
              name="513581"
              rules={[
                {
                  required: isFieldRequired(513581),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CSelect
                showSearch
                placeholder="Территория страхования"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="large"
                onChange={(e) => onChange('513581', e, 'DICTI')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                {getAttrDictisByIsn(attributesList, 513581)?.map((el) => (
                  <Option value={el.isn} key={el.isn}>
                    {el.full_name}
                  </Option>
                ))}
              </CSelect>
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(513621) && (
          <Col span={6}>
            <Form.Item
              label="Допущенные к управлению"
              name="513621"
              rules={[
                {
                  required: isFieldRequired(513621),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CSelect
                showSearch
                placeholder="Допущенные к управлению"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="large"
                onChange={(e) => onChange('513621', e, 'DICTI')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                {getAttrDictisByIsn(attributesList, 513621)?.map((el) => (
                  <Option value={el.isn} key={el.isn}>
                    {el.full_name}
                  </Option>
                ))}
              </CSelect>
            </Form.Item>
          </Col>
        )} */}
        {/* {!isDynamicProduct && isFieldExist(699071) && (
          <Col span={6}>
            <Form.Item
              label="Безопасный пробег"
              name="699071"
              rules={[
                {
                  required: isFieldRequired(699071),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CSelect
                showSearch
                placeholder="Безопасный пробег"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="large"
                onChange={(e) => onChange('699071', e, 'DICTI')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                {getAttrDictisByIsn(attributesList, 699071)?.map((el) => (
                  <Option value={el.isn} key={el.isn}>
                    {el.full_name}
                  </Option>
                ))}
              </CSelect>
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(2488561) && (
          <Col span={6}>
            <Form.Item
              label="Номер пролонгируемого договора"
              name="2488561"
              rules={[
                {
                  required: isFieldRequired(2488561),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CInput
                placeholder="Номер пролонгируемого договора"
                size="large"
                onChange={(e) => onChange('2488561', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
              
              <CSelect
                //options={handbookList}
                showSearch={true}
                style={{ width: '100%' }}
                size="large"
                fieldNames={{ label: 'FULLNAME', value: 'ISN' }}
                filterOption={(input, option) =>
                  (option?.FULLNAME ?? '').toLowerCase().includes(input.toLowerCase())
                }
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                onChange={(e) => onChange('2488561', e, 'SQL')}
                allowClear={true}
              />
              
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(2488591) && (
          <Col span={6}>
            <Form.Item
              label="МРП – Страховая сумма"
              name="2488591"
              validateTrigger="onChange"
              rules={[
                {
                  required: isFieldRequired(2488591),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CInput
                className="numericMask"
                placeholder="МРП – Страховая сумма"
                size="large"
                onChange={(e) => onChange('2488591', e, 'TEXT')}
                disabled
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(831381) && (
          <Col span={6}>
            <Form.Item
              label="Номер телефона для SMS"
              name="831381"
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    const numbersOnly = value?.replace(/\D/g, ''); // Удаляем все символы, кроме цифр
                    if (numbersOnly?.length === 11) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        'Введите корректный номер телефона'
                      );
                    }
                  },
                },
              ]}
              validateTrigger="onChange"
            >
              <CInput
                placeholder="Номер телефона для SMS"
                size="large"
                autoComplete="off"
                onChange={(e) => onChange('831381', e, 'TEXT')}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(720671) && (
          <Col span={6}>
            <Form.Item
              label="E-mail для договора"
              name="720671"
              rules={[
                {
                  required: isFieldRequired(720671),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CInput
                placeholder="E-mail для договора"
                size="large"
                onChange={(e) => onChange('720671', e, 'TEXT')}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(828091) && (
          <Col span={6}>
            <Form.Item
              label="Макс. возможное число потерпевших в результате вредного воздействия опасн.объектов"
              name="828091"
              rules={[{ required: true }]}
              validateTrigger="onChange"
            >
              <CSelect
                showSearch
                placeholder="Макс. возможное число потерпевших в результате вредного воздействия опасн.объектов"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="large"
                onChange={(e) => onChange('828091', e, 'DICTI', vooCalcMrp)}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                {getAttrDictisByIsn(attributesList, 828091)?.map((el) => (
                  <Option value={el.isn} key={el.isn}>
                    {el.full_name}
                  </Option>
                ))}
              </CSelect>
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(828111) && showVooAdditionalFields && (
          <Col span={12}>
            <Form.Item
              name="828111"
              rules={[
                {
                  required: isFieldRequired(828111),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CRadio
                title="Находится ли предприятие в черте населенного пункта?"
                options={options.yesOrNo}
                optionType="button"
                buttonStyle="solid"
                size="large"
                onChange={(e) => onChange('828111', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(828131) && showVooAdditionalFields && (
          <Col span={6}>
            <Form.Item
              label={`При ответе "ДА" пожалуйста, поясните`}
              name="828131"
              dependencies={['828111']}
              rules={[
                {
                  required: isExplainRequired,
                  message: 'Поле обязательно для заполнения',
                },
              ]}
              validateTrigger="onChange"
            >
              <CInput
                placeholder={`При ответе "ДА" пожалуйста, поясните`}
                size="large"
                onChange={(e) => onChange('828131', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}  */}
        {/* {!isDynamicProduct && isFieldExist(828071) && showVooAdditionalFields && (
          <Col span={12}>
            <Form.Item
              name="828071"
              rules={[
                {
                  required: isFieldRequired(828071),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
              validateTrigger="onChange"
            >
              <CRadio
                title="Заключали ли Вы ранее договоры ОГПО Владельцев опасных объектов"
                options={options.yesOrNo}
                optionType="button"
                buttonStyle="solid"
                size="large"
                onChange={(e) => onChange('828071', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(828101) && showVooAdditionalFields && (
          <Col span={6}>
            <Form.Item
              label="Наименование предыдущего страховщика"
              name="828101"
              rules={[
                {
                  required: isFieldRequired(828101),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
              validateTrigger="onChange"
            >
              <CInput
                placeholder="Наименование предыдущего страховщика"
                size="large"
                onChange={(e) => onChange('828101', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(828081) && showVooAdditionalFields && (
          <Col span={6}>
            <Form.Item
              label="Какой был предыдущий объем ответственности?"
              name="828081"
              rules={[
                {
                  required: isFieldRequired(828081),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
              validateTrigger="onChange"
            >
              <CInput
                placeholder="Какой был предыдущий объем ответственности?"
                size="large"
                type="number"
                onChange={(e) => onChange('828081', e, 'NUMBER')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(828051) && showVooAdditionalFields && (
          <Col span={12}>
            <Form.Item
              name="828051"
              rules={[
                {
                  required: isFieldRequired(828051),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
              validateTrigger="onChange"
            >
              <CRadio
                title="Были ли страховые выплаты по предыдущему страхованию?"
                options={options.yesOrNo}
                optionType="button"
                buttonStyle="solid"
                size="large"
                onChange={(e) => onChange('828051', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(828141) && showVooAdditionalFields && (
          <Col span={6}>
            <Form.Item
              label="Регистрационный № (рег.шифр декларации)"
              name="828141"
              rules={[
                {
                  required: isFieldRequired(828141),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
              validateTrigger="onChange"
            >
              <CInput
                placeholder="Регистрационный № (рег.шифр декларации)"
                size="large"
                type="number"
                onChange={(e) => onChange('828141', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(828061) && showVooAdditionalFields && (
          <Col span={6}>
            <Form.Item
              label="Вид деятельности"
              name="828061"
              rules={[
                {
                  required: isFieldRequired(828061),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
              validateTrigger="onChange"
            >
              <CInput
                placeholder="Вид деятельности"
                size="large"
                onChange={(e) => onChange('828061', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(828151) && (
          <Col span={6}>
            <Form.Item
              label="Уровень готовности к предупреждению ЧС"
              name="828151"
              rules={[
                {
                  required: isFieldRequired(828151),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
              validateTrigger="onChange"
            >
              <CSelect
                showSearch
                placeholder="Уровень готовности к предупреждению ЧС"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="large"
                onChange={(e) => onChange('828151', e, 'DICTI')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                {getAttrDictisByIsn(attributesList, 828151)?.map((el) => (
                  <Option value={el.isn} key={el.isn}>
                    {el.full_name}
                  </Option>
                ))}
              </CSelect>
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(514401) && (
          <Col span={6}>
            <Form.Item
              label="Вид аудиторской деятельности"
              name="514401"
              validateTrigger="onChange"
              rules={[
                {
                  required: isFieldRequired(514401),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CSelect
                showSearch
                placeholder="Вид аудиторской деятельности"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="large"
                onChange={(e) => onChange('514401', e, 'DICTI')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                {getAttrDictisByIsn(attributesList, 514401)?.map((el) => (
                  <Option value={el.isn} key={el.isn}>
                    {el.full_name}
                  </Option>
                ))}
              </CSelect>
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(2488561) && (
          <Col span={6}>
            <Form.Item
              // label="Продление условий"
              name="2488561"
              valuePropName="checked"
              rules={[
                {
                  required: isFieldRequired(2488561),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <Checkbox
                onChange={(e) => onChange('2488561', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                Продление условий
              </Checkbox>
            </Form.Item>
          </Col>
        )} */}
        {/* {!isDynamicProduct && isFieldExist(2729301) && (
          <Col span={6}>
            <Form.Item
              name="2729301"
              valuePropName="checked"
              rules={[
                {
                  required: isFieldRequired(2729301),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <Checkbox
                onChange={(e) => onChange('2729301', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                Бенефициарный собственник не менялся
              </Checkbox>
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(225291) && (
          <Col span={6}>
            <Form.Item
              name="225291"
              valuePropName="checked"
              rules={[
                {
                  required: isFieldRequired(225291),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <Checkbox
                onChange={(e) => onChange('225291', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                Полис выдан ИП/КХ
              </Checkbox>
            </Form.Item>
          </Col>
        )}
        {isDynamicProduct && isFieldExist(2488581) && (
          <Col span={6}>
            <Form.Item
              name="2488581"
              valuePropName="checked"
              rules={[
                {
                  required: isFieldRequired(2488581),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <Checkbox
                onChange={(e) => onChange('2488581', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              >
                НЕ автоматическое согласование
              </Checkbox>
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(856741) && (
          <Col span={6}>
            <Form.Item
              label={`№ заявки`}
              name="856741"
              rules={[
                {
                  required: isFieldRequired(856741),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CInput
                placeholder={`№ заявки`}
                size="large"
                onChange={(e) => onChange('856741', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(873911) && (
          <Col span={6}>
            <Form.Item
              label="Номер заявления-анкеты"
              name="873911"
              rules={[
                {
                  required: isFieldRequired(873911),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CInput
                placeholder="Номер заявления-анкеты"
                size="large"
                onChange={(e) => onChange('873911', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(1444661) && (
          <Col span={6}>
            <Form.Item
              label="Ответ KUPIPOLIS.KZ"
              name="1444661"
              rules={[
                {
                  required: isFieldRequired(1444661),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CInput
                placeholder="Ответ KUPIPOLIS.KZ"
                size="large"
                onChange={(e) => onChange('1444661', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(2468531) && (
          <Col span={6}>
            <Form.Item
              label="BitrixID"
              name="2468531"
              rules={[
                {
                  required: isFieldRequired(2468531),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CInput
                placeholder="BitrixID"
                size="large"
                onChange={(e) => onChange('2468531', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(2584231) && (
          <Col span={6}>
            <Form.Item
              label="Документ списания"
              name="2584231"
              rules={[
                {
                  required: isFieldRequired(2584231),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CInput
                placeholder="Документ списания"
                size="large"
                onChange={(e) => onChange('2584231', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(781361) && ( */}
        {/* <Col span={6}>
          <Form.Item
            label="Подписант от СТРАХОВАТЕЛЯ"
            name="781361"
            rules={[
              {
                required: isFieldRequired(781361),
                message: 'Поле обязательно для заполнения',
              },
            ]}
          >
            <CInput
              placeholder="Подписант от СТРАХОВАТЕЛЯ"
              size="large"
              onChange={(e) => onChange('781361', e, 'TEXT')}
              disabled={checkDisabledField(stateDateQuotation.status_isn)}
            />
          </Form.Item>
        </Col> */}
        {/* )} */}
        {/* {!isDynamicProduct &&
          isFieldExist(1026221) &&
          contractorInfo &&
          contractorInfo[0]?.JURIDICAL === 'Y' && (
            <Col span={6}>
              <Form.Item
                label="Документ-основание подписанта от Страхователя"
                name="1026221"
                rules={[
                  {
                    required: isFieldRequired(1026221),
                    message: 'Поле обязательно для заполнения',
                  },
                ]}
              >
                <CInput
                  placeholder="Документ-основание подписанта от Страхователя"
                  size="large"
                  onChange={(e) => onChange('1026221', e, 'TEXT')}
                  disabled={checkDisabledField(stateDateQuotation.status_isn)}
                />
              </Form.Item>
            </Col>
          )} */}
        {/* {!isDynamicProduct && isFieldExist(866081) && (
          <Col span={12}>
            <Form.Item
              label="Лицензия на право осуществления деятельности"
              name={'866081'}
              rules={[
                {
                  required: Number(productIsn) === 474651,
                  message: 'Поле обязательно для заполнения',
                },
              ]}
              validateTrigger="onChange"
            >
              <CInput
                placeholder="Лицензия на право осуществления деятельности"
                size="large"
                onChange={(e) => onChange('866081', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(1027821) && (
          <Col span={12}>
            <Form.Item
              label="С какого времения Вами осуществляется нотариальная деятельность?"
              name="1027821"
              rules={[
                {
                  required: isFieldRequired(1027821),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CInput
                placeholder="С какого времения Вами осуществляется нотариальная деятельность?"
                size="large"
                onChange={(e) => onChange('1027821', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(828181) && (
          <Col span={12}>
            <Form.Item
              name="828181"
              rules={[
                {
                  required: isFieldRequired(828181),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
              validateTrigger="onChange"
            >
              <CRadio
                title="Город республиканского значения"
                options={[
                  { label: 'Да', value: 1 },
                  { label: 'Нет', value: 0 },
                ]}
                optionType="button"
                buttonStyle="solid"
                size="large"
                onChange={(e) => onChange('828181', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(2656251) && (
          <Col span={12}>
            <Form.Item
              name="2656251"
              rules={[
                {
                  required: Number(productIsn) === 471651,
                  message: 'Поле обязательно для заполнения',
                },
              ]}
              validateTrigger="onChange"
            >
              <CRadio
                title="Оплата через казначейство"
                options={options.yesOrNo}
                optionType="button"
                buttonStyle="solid"
                size="large"
                onChange={(e) => onChange('2656251', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(1027831) && (
          <Col span={12}>
            <Form.Item
              name="1027831"
              rules={[
                {
                  required: isFieldRequired(1027831),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CRadio
                title="Были ли Вам ранее выставлены претензии, связанные с оказанием услуг частного нотариуса?"
                options={options.yesOrNo}
                optionType="button"
                buttonStyle="solid"
                size="large"
                onChange={(e) => onChange('1027831', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(1027861) && (
          <Col span={12}>
            <Form.Item
              name="1027861"
              rules={[
                {
                  required: isFieldRequired(1027861),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CRadio
                title="Заключали ли Вы ранее договоры ОГПО Частных нотариусов:"
                options={options.yesOrNo}
                optionType="button"
                buttonStyle="solid"
                size="large"
                onChange={(e) => onChange('1027861', e, 'TEXT')}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && isFieldExist(1281521) && (
          <Col span={12}>
            <Form.Item
              name="1281521"
              rules={[
                {
                  required: isFieldRequired(1281521),
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <CRadio
                title="Единовременное заключение с ОС ГПО ВОО"
                options={options.yesOrNo}
                optionType="button"
                buttonStyle="solid"
                size="large"
                onChange={(e) => onChange('1281521', e, 'TEXT')}
                disabled={checkDisabledField(stateDateQuotation.status_isn)}
              />
            </Form.Item>
          </Col>
        )}
        {!isDynamicProduct && !isFieldExist(499591) && (
          <Col span={14}>
            <Row style={{ minHeight: 50 }} gutter={18}>
              <Col span={24}>
                <Form.Item
                  name="499591"
                  rules={[
                    {
                      required: isFieldRequired(499591),
                      message: 'Поле обязательно для заполнения',
                    },
                  ]}
                  validateTrigger="onChange"
                >
                  <CRadio
                    title="Стандартный шаблон договора страхования"
                    options={options.customTemplateValue}
                    optionType="button"
                    buttonStyle="solid"
                    size="large"
                    onChange={(e: { target: { value: number } }) => {
                      onChange('499591', e.target.value, 'DICTI_CODENAME');
                      setIsCustomTemplate(e.target.value);
                    }}
                    disabled={checkDisabledField(stateDateQuotation.status_isn)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )} */}
      </Row>
    </CustomLoader>
  );
};
export default NewAdditionalAttributesFields;
