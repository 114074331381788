import { Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { isFieldDisabled } from '../DateQuotation';
import { IMembers } from '../hooks/useMembersQuotation';
import useQuotationJournal from '../hooks/useQuotationJournal';
import CSelect from '@components/UI/select/CSelect';
import useQuotation from '@modules/Quotation/hooks/useQuotation';
import { checkDisabledField } from '@modules/Quotation/Helpers/checkDisabledField';

const { Option } = Select;

interface IListMembersProps {
  members: IMembers[];
  label?: string;
  placeholder?: string;
  name?: string;
  member?: any;
  className?: string;
  index?: number;
  onChange?: (e: any) => void;
}

function ListMembers({
  className,
  members,
  member,
  placeholder,
  onChange,
}: IListMembersProps) {
  const [value, setValue] = useState();
  const { state } = useQuotationJournal();
  const { stateDateQuotation } = useQuotation();
  useEffect(() => {
    if (member) {
      setValue(member.foreign_id);
    }
  }, [member]);
  console.log(members);

  return (
    <CSelect
      size="large"
      className={className}
      placeholder={placeholder}
      showSearch
      optionFilterProp="children"
      value={value}
      filterOption={(input, option) =>
        (option?.full_name ?? '').toLowerCase().includes(input.toLowerCase())
      }
      onChange={onChange}
      disabled={isFieldDisabled(state.agrDate.status_id)}
    >
      {!checkDisabledField(stateDateQuotation.status_isn) ? (
        members
          .sort(function (a, b) {
            if (a.label > b.label) {
              return 1;
            }
            if (a.label < b.label) {
              return -1;
            }
            return 0;
          })
          .map((element) => {
            return (
              <Option value={element.id} key={element.label + '-' + element.id}>
                {element.label}
              </Option>
            );
          })
      ) : (
        <Option value="221507" key="Подписант от страховщика-221507">
          Подписант от страховщика
        </Option>
      )}
    </CSelect>
  );
}

export default ListMembers;
