import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import {
  Alert,
  Button,
  Form,
  Input,
  notification,
  Typography,
  Select, Row, Col,
} from 'antd';

import api from '../../services/api';
import useAuth from '../../hooks/useAuth';

import './Login.scss';
import { ArrowLeftOutlined } from '@ant-design/icons';
import catchErrorCustomAxios from '../../services/api/catchErrors';
import MycentLogoWhite from '../../assets/mycent-logo-auth.svg';
import Cookies from 'js-cookie';
import {useLocation, useNavigate} from 'react-router-dom';
const { Text } = Typography;
const { Option } = Select;

interface LoginResponseInterface {
  access_token: string;
}
interface LoginProps {
  title: string;
}

const Login: React.FC<LoginProps> = ({ title = 'Mycent.kz' }) => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isForget, setIsForget] = useState(false);
  const [reset] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [dataMessage, setDataMessage] = useState('');
  const [companyList, SetCompanyList] = useState(Object);
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const logout = searchParams.get('logout');
    if (logout) {
      searchParams.delete('logout');
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
      Cookies.remove('token');
    }
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const resetPassword = () => {
    setIsForget(true);
  };

  useEffect(() => {
    if (companyList.length) return;
    (async () => {
      try {
        const response = await api.auth.getCompanyList();
        SetCompanyList(response.data);
      } catch (error) {
        let message;
        catchErrorCustomAxios(error, message);
      } finally {
      }
    })();
  }, [companyList]);

  const onSubmitReset = async (email) => {
    setIsLoading(true);
    try {
      const { data } = await api.auth.resetPassword({
        email: email.email,
        company_id: email.company_id,
      });
      if (data.success === true) {
        setVisible(true);
        setDataMessage(data.message);
      }
      console.log(data);
    } catch (error) {
      let message;

      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setVisible(false);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<LoginResponseInterface> =
          await api.auth.login(data);
      auth.setToken(response.data.access_token);

      // TODO Попросить переделать бэк, чтобы данные пользователя приходили в первом запросе
      const { data: user } = await api.auth.getProfile();
      auth.setUser(user);
    } catch (error) {
      let message;

      if (axios.isAxiosError(error) && error.response) {
        message = error.response.data.message;
      } else {
        message = String(error);
      }

      notification.info({
        message: 'Ошибка',
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <div className="page-login">
        <div className="page-login__wrapper">
          <div className="page-login__rocket"></div>
          <div className="page-login__content">
            <div className="page-login__welcome">
              <img
                  src={MycentLogoWhite}
                  alt="mycent"
                  className="page-login__logo"
              />
            </div>
            {isForget && (
                <Form
                    name="reset"
                    layout="vertical"
                    onFinish={onSubmitReset}
                    style={{maxWidth: '100%'}}
                    form={reset}
                >
                  {visible ? (
                      <Alert
                          message={dataMessage}
                          type="success"
                          closable
                          afterClose={handleClose}
                      />
                  ) : null}
                  <Form.Item name="email" rules={[{required: true}]}>
                    <Input
                        size="large"
                        disabled={isLoading}
                        placeholder="Введите email"
                        className="page-login__input"
                    />
                  </Form.Item>
                  <Form.Item name="company_id" rules={[{required: true}]}>
                    <Select placeholder="Выберите компанию">
                      {companyList?.map((company) => (
                          <Option key={company.id} value={company.id}>
                            {company.full_name}
                          </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item className="page-login__footer">
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={isLoading}
                        className="page-login__btn"
                    >
                      Восстановить
                    </Button>
                    <Button
                        icon={<ArrowLeftOutlined/>}
                        onClick={() => {
                          setIsForget(false);
                        }}
                        className="page-login__btn-return"
                    >
                      Назад
                    </Button>
                  </Form.Item>
                </Form>
            )}
            {!isForget && (
                <>
                  <Form
                      name="login"
                      layout="vertical"
                      onFinish={onSubmit}
                      style={{maxWidth: '100%', textAlign: 'left'}}
                  >
                    <Form.Item name="username" rules={[{required: true}]}>
                      <Input
                          size="large"
                          disabled={isLoading}
                          width={'100%'}
                          placeholder="Логин"
                          className={`page-login__input ${
                              !login.length ? 'input-user-logo' : ''
                          }`}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              setLogin(event.target.value)
                          }
                      />
                    </Form.Item>
                    <Form.Item name="password" rules={[{required: true}]}>
                      <Input.Password
                          size="large"
                          disabled={isLoading}
                          width={'100%'}
                          placeholder="Пароль"
                          className={`page-login__input ${
                              !password.length ? 'input-user-password' : ''
                          }`}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                              setPassword(event.target.value)
                          }
                      />
                    </Form.Item>
                    <Form.Item className="page-login__footer">
                      <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          loading={isLoading}
                          className="page-login__btn"
                      >
                        Войти
                      </Button>
                      <Button
                          type="text"
                          htmlType="button"
                          size="small"
                          onClick={resetPassword}
                          loading={isLoading}
                      >
                        <Text italic>Забыли пароль?</Text>
                      </Button>
                    </Form.Item>
                  </Form>
                </>
            )}
          </div>
        </div>
        <div className="page-login__helpdesk-content">
          <p className="page-login__copyright">
            &copy; 1997&mdash;2024 АО "СТРАХОВАЯ КОМПАНИЯ "СЕНТРАС ИНШУРАНС"
            <br/>
            ВСЕ ПРАВА ЗАЩИЩЕНЫ
          </p>
          <div className="page-login__helpdesk">
            <span className="page-login__span">Контакты HelpDesk: </span>
            <a href="tel:+77272597755" className="page-login__number">
              {' '}
              +7 (727) 259 77 55{' '}
            </a>{' '}
            (вн. 2054, 2107, 2078)
          </div>
        </div>
      </div>
  );
};

export default Login;
