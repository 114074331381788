import React, { useEffect, useState } from 'react';

import { Card, Form, Tabs, Typography } from 'antd';
import { IAdditionalAttributesItem } from '../Interfaces/AdditionalAttributes/index.interface';
import AdditionalAttributesFields from './AdditionalAttributesFields';
import NewAdditionalAttributesFields from './NewAdditionalAttributesFields';
import UnderWriterFields from './UnderWriterFields';
import useQuotationJournal from '../hooks/useQuotationJournal';
import CTabs from '@components/UI/tabs/CTabs';
import api from '@modules/Quotation/services';
import { useImmerReducer } from 'use-immer';

const { Title } = Typography;
type Action = {
  type: 'setDicti';
  payload?: any;
};
interface State {
  dicti: any;
}
const initialState: State = {
  dicti: null,
};
const reducer = (draft = initialState, action: Action) => {
  switch (action.type) {
    case 'setDicti': {
      draft.dicti = action.payload;
      break;
    }
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const AdditionalAttributes: React.FC<IAdditionalAttributesItem> = ({
  agrIsn,
  userForeignId,
  productIsn,
  attributesList,
  contractorInfo,
  objectForm,
  isJuridical,
  quotationData,
}) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const { dicti } = state;
  useEffect(() => {
    if (attributesList) {
      let list;
      attributesList.map((item) => {
        item.filter(async (elem) => {
          if (
            elem.attr_type === 'DICTI' ||
            elem.attr_type === 'DICTI_SQL' ||
            elem.attr_type === 'SQL' ||
            elem.attr_type === 'DICTI_CODENAME'
          ) {
            const { data } = await api.auto.getDictiByIsn({
              isn: elem.attr_isn,
              obj_isn: agrIsn,
            });
            if (list) {
              list = { ...list, [elem.attr_isn]: data.data };
            } else {
              list = { [elem.attr_isn]: data.data };
            }
            dispatch({ type: 'setDicti', payload: list });
          }
        });
      });
    }
  }, [attributesList]);
  useEffect(() => {
    if (
      quotationData?.conds?.length > 0 &&
      productIsn === 512991 &&
      attributesList
    ) {
      let list;
      attributesList.map((item) => {
        item.filter(async (elem) => {
          if (
            elem.attr_type === 'DICTI' ||
            elem.attr_type === 'DICTI_SQL' ||
            elem.attr_type === 'SQL' ||
            elem.attr_type === 'DICTI_CODENAME'
          ) {
            const { data } = await api.auto.getDictiByIsn({
              isn: elem.attr_isn,
              obj_isn: agrIsn,
            });
            if (list) {
              list = { ...list, [elem.attr_isn]: data.data };
            } else {
              list = { [elem.attr_isn]: data.data };
            }
            dispatch({ type: 'setDicti', payload: list });
          }
        });
      });
    }
  }, [quotationData, attributesList]);
  return (
    <Card className="mt-5">
      <Title className="main-quotation__section-title" level={3}>
        Дополнительные атрибуты
      </Title>
      <CTabs
        className="mt-5 quotation-tabs"
        type="card"
        items={[
          {
            label: <span>Раздел страховщика</span>,
            key: '1',
            children: (
              <Card>
                <NewAdditionalAttributesFields
                  agrIsn={agrIsn}
                  userForeignId={userForeignId}
                  productIsn={productIsn}
                  attributesList={attributesList}
                  contractorInfo={contractorInfo}
                  objectForm={objectForm}
                  isJuridical={isJuridical}
                  dicti={dicti}
                  quotationData={quotationData}
                />
              </Card>
            ),
          },
          {
            label: <span>Раздел андеррайтера</span>,
            key: '2',
            children: (
              <Card>
                <UnderWriterFields
                  userForeignId={userForeignId}
                  agrIsn={agrIsn}
                  dicti={dicti}
                />
              </Card>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default AdditionalAttributes;
