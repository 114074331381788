import React, { useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Empty, InputRef } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

type DataIndex = keyof DataType;

export interface DataType {
  id: number;
  department_to: string;
  name_of_post_id: string;
  updated_at: string;
  chief_id: string;
}

type ApplicationTableProps = {
  fetchApplication: DataType[];
};

function ApplicationTable({ fetchApplication }: ApplicationTableProps) {
  const navigate = useNavigate();

  const searchInput = useRef<InputRef>(null);

  const navigateToApplication = (value: number) => {
    navigate(`/recruiting/application/${value}`);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Очистить
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columnsApplication: ColumnsType<DataType> = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      ...getColumnSearchProps('id'),
      render: (value) => {
        return (
          <a
            onClick={() => {
              navigateToApplication(value);
            }}
          >
            {value}
          </a>
        );
      },
    },
    {
      // TODO
      // dataindex change to name Director
      title: 'ФИО Руководителя',
      dataIndex: 'chief_id',
      key: 'chief_id',
      width: '20%',
      ...getColumnSearchProps('chief_id'),
      render: (value, record) => {
        return (
          <a
            onClick={() => {
              navigateToApplication(record.id);
            }}
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'Структурное подразделение',
      dataIndex: 'department_to',
      key: 'department_to',
      width: '20%',
      ...getColumnSearchProps('department_to'),
      render: (value, record) => {
        return (
          <a
            onClick={() => {
              navigateToApplication(record.id);
            }}
          >
            {value}
          </a>
        );
      },
    },
    {
      title: 'Дата/время заявки',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: '20%',
      ...getColumnSearchProps('updated_at'),
      render: (value, record) => {
        return (
          <a
            onClick={() => {
              navigateToApplication(record.id);
            }}
          >
            {dayjs(value).utc().format('YYYY-MM-DD / HH:mm')}
          </a>
        );
      },
    },
  ];

  return (
    <Table
      columns={columnsApplication}
      // dataSource={state.fetchedApplication}
      dataSource={fetchApplication}
      locale={{
        emptyText: <Empty description="У вас нет доступа к просмотру" />,
      }}
      rowKey="id"
      scroll={{ x: 1000 }}
      style={{ fontWeight: 'initial' }}
    />
  );
}

export default ApplicationTable;
