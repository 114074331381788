import React, { useMemo } from 'react';
import { Card, Checkbox, Col, Form, Row, notification } from 'antd';
import CInput from '@components/UI/input/CInput';
import CTabs from '@components/UI/tabs/CTabs';
import CSelect from '@components/UI/select/CSelect';
import api from '@modules/Quotation/services/saveQuotation';
import axios from 'axios';

const MainContractAdditionalAttributes = ({
  fields,
  contractData,
  userForeignId,
  agrIsn,
  quotationData,
  productIsn,
}) => {
  const checkDisabledChecbkox = () => {
    if (
      Number(productIsn) === 437141 ||
      Number(productIsn) === 437401 ||
      Number(productIsn) === 437411 ||
      Number(productIsn) === 449011 ||
      Number(productIsn) === 449051 ||
      Number(productIsn) === 471161 ||
      Number(productIsn) === 471231 ||
      Number(productIsn) === 471401 ||
      Number(productIsn) === 471441 ||
      Number(productIsn) === 471541 ||
      Number(productIsn) === 471571 ||
      Number(productIsn) === 471641 ||
      Number(productIsn) === 471661 ||
      Number(productIsn) === 471761 ||
      Number(productIsn) === 471771 ||
      Number(productIsn) === 473861 ||
      Number(productIsn) === 473891 ||
      Number(productIsn) === 474131 ||
      Number(productIsn) === 474141 ||
      Number(productIsn) === 474381 ||
      Number(productIsn) === 474391 ||
      Number(productIsn) === 474411 ||
      Number(productIsn) === 474421 ||
      Number(productIsn) === 474431 ||
      Number(productIsn) === 474471 ||
      Number(productIsn) === 475361 ||
      Number(productIsn) === 483101 ||
      Number(productIsn) === 501321 ||
      Number(productIsn) === 501351 ||
      Number(productIsn) === 501371 ||
      Number(productIsn) === 854501 ||
      Number(productIsn) === 856601 ||
      Number(productIsn) === 882651 ||
      Number(productIsn) === 2521131 ||
      Number(productIsn) === 2618631 ||
      Number(productIsn) === 2684231 ||
      Number(productIsn) === 2694861 ||
      Number(productIsn) === 2713201 ||
      Number(productIsn) === 2718231 ||
      Number(productIsn) === 2718311 ||
      Number(productIsn) === 2718681 ||
      Number(productIsn) === 2780971 ||
      Number(productIsn) === 5344171 ||
      Number(productIsn) === 5819621 ||
      Number(productIsn) === 5844681
    ) {
      return false;
    } else {
      return true;
    }
  };
  const underData = useMemo(() => {
    if (!contractData) return null;
    return contractData?.data?.addattr_underwriter;
  }, [contractData]);
  const onChange = (val, attr_isn) => {
    setTimeout(async () => {
      let requestBody = {
        agr_attributes: [
          {
            attr_isn: attr_isn,
            class_isn: 220565,
            val_c: val.target.value,
            val_n: Number(val.target.value),
            value: val.target.value,
            obj_isn: String(agrIsn),
            user_isn: userForeignId,
          },
        ],
      };
      try {
        await api.attributeSave(requestBody);
      } catch (error) {
        let message;
        if (axios.isAxiosError(error)) {
          message = error.message;
        } else {
          message = String(error);
        }
        notification.error({
          message: 'Ошибка',
          description: message,
        });
      }
    }, 500);
    console.log(val);
  };

  const RenderFields = ({ fieldsList }) => {
    return fieldsList?.map((field, index) => {
      switch (field?.attr_type) {
        case 'SQL':
        case 'TEXT':
          if (
            fields.filter(
              (item) => Number(item.attr_isn) === Number(field.attr_isn)
            )[0]?.val_c
          ) {
            return (
              <Col span="8" key={index}>
                <Form.Item
                  label={field?.attr_name}
                  name={[`id-${field?.attr_isn}`, 'char_value']}
                  initialValue={
                    fields.filter(
                      (item) => Number(item.attr_isn) === Number(field.attr_isn)
                    )[0]?.val_c
                  }
                >
                  <CInput
                    disabled={
                      field.attr_isn !== '781361' &&
                      field.attr_isn !== '1026221'
                    }
                    onChange={(e) => onChange(e, field.attr_isn)}
                    value={field?.val_c}
                    size="large"
                  />
                </Form.Item>
              </Col>
            );
          } else {
            break;
          }
        case 'DOCS':
        case 'DEPTEMPL':
        case 'DICTI_CODENAME':
        case 'DICTI':
        case 'NUMBER':
        case 'INTEGER':
          if (
            fields.filter(
              (item) => Number(item.attr_isn) === Number(field.attr_isn)
            )[0]?.value
          ) {
            return (
              <Col span="8" key={index}>
                <Form.Item
                  label={field?.attr_name}
                  name={[`id-${field?.attr_isn}`, 'value']}
                  initialValue={
                    fields.filter(
                      (item) => Number(item.attr_isn) === Number(field.attr_isn)
                    )[0]?.value
                  }
                >
                  <CInput disabled={true} value={field?.value} size="large" />
                </Form.Item>
              </Col>
            );
          } else {
            break;
          }
        case 'CHECKBOX':
          console.log(Number(field.attr_isn) !== 1796501);
          console.log(checkDisabledChecbkox());

          return (
            <Col span="8" key={index}>
              <Form.Item
                name={field?.attr_name}
                valuePropName="checked"
                initialValue={
                  fields.filter(
                    (item) => Number(item.attr_isn) === Number(field.attr_isn)
                  )[0]?.value
                }
              >
                <Checkbox
                  disabled={
                    Number(field.attr_isn) === 1796501
                      ? checkDisabledChecbkox()
                      : true
                  }
                  onChange={(e) => onChange(e, field.attr_isn)}
                >
                  {field?.attr_name}
                </Checkbox>
              </Form.Item>
            </Col>
          );
        default:
          return null;
      }
    });
  };

  return (
    <Form className="c-form" layout="vertical">
      <CTabs
        className="mt-5 quotation-tabs"
        type="card"
        items={[
          {
            label: <span>Раздел страховщика</span>,
            key: '1',
            children: (
              <Card>
                <Row gutter={18}>
                  <RenderFields
                    fieldsList={quotationData?.data[0].attributes[0]}
                  />
                </Row>
              </Card>
            ),
          },
          {
            label: <span>Раздел андеррайтера</span>,
            key: '2',
            children: (
              <Card>
                <RenderFields fieldsList={underData} />
              </Card>
            ),
          },
        ]}
      />
    </Form>
  );
};

export default MainContractAdditionalAttributes;
